import Spinner from "components/Spinner";
import { useRef, useState } from "react";
import * as xlsx from "xlsx";
import ViewSheet from "./ViewSheet/ViewSheet";
import SelectExcelData from "./SelectExcelData/SelectExcelData";
import LabelColumns from "./LabelColumns/LabelColumns";

export default function LabelPrinter() {
  /*   All States
   ********************************************* */

  // Refs
  const fileInputRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [spreadsheetData, setSpreadsheetData] = useState([]);
  const [columnLabels, setColumnLabels] = useState([]);

  const [rowsData, setRowsData] = useState({});

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const handleFileImport = (e) => {
    loadingOn();
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: "binary" });
      const sheetData = xlsx.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        { header: 1 }
      );
      const [firstRow, ...restRows] = sheetData;
      setColumnLabels(firstRow || []);
      const formattedData = restRows.map((row) =>
        row.map((cell) => ({ value: cell, readOnly: true }))
      );
      setSpreadsheetData(formattedData);
      loadingOff();
    };
  };

  const handleClearFile = () => {
    setSpreadsheetData([]);
    setColumnLabels([]);
    setRowsData({});
    fileInputRef.current.value = "";
  };

  /*   All UseEffects
   ********************************************* */
  return (
    <div
      className={`flex-grow-1 h-100 px-5 py-2`}
      style={{
        position: "fixed",
        overflow: "auto",
        top: 0,
        width: "calc(100vw - 200px)",
        marginLeft: 210,
        zIndex: "99",
      }}
    >
      {loading && <Spinner />}
      <h1>Label Printer</h1>

      {/* ---- Extract Data and View Label ---- */}
      <div className="row ">
        {/* ---- Extract Data View Label ---- */}
        <div className="col-md-6 ">
          <div className="card">
            <SelectExcelData
              columnLabels={columnLabels}
              spreadsheetData={spreadsheetData}
              rowsData={rowsData}
              setRowsData={setRowsData}
            />
          </div>
        </div>
        {/* ---- View Label ---- */}
        <div className="col-md-6 ">
          <div className="card">
            <LabelColumns
              columnLabels={columnLabels}
              spreadsheetData={spreadsheetData}
              rowsData={rowsData}
              setRowsData={setRowsData}
            />
          </div>
        </div>
      </div>
      {/* ---- View & Import Excel ---- */}
      <div className="row h-75">
        <div className="col-md-12">
          {/* ---- Action Bar ---- */}
          <div className="bg-success bg-opacity-10 px-3 py-2 rounded-3 shadow-sm d-flex align-items-center justify-content-between w-100 ">
            <h3 className="h3">Select Data</h3>
            <div className="d-flex align-items-center text-nowrap gap-2">
              <label htmlFor="excelFile" className="h3">
                Select File
              </label>
              <input
                onChange={handleFileImport}
                type="file"
                ref={fileInputRef}
                className="form-control d-inline-block"
              />
              <button
                onClick={(e) => {
                  handleClearFile();
                }}
                className="btn btn-danger btn-sm"
              >
                Clear
              </button>
            </div>
          </div>
          {/* ---- View Excel ---- */}
          <div className="overflow-auto mt-3 bg-white shadow-sm rounded-3">
            <ViewSheet
              columnLabels={columnLabels}
              spreadsheetData={spreadsheetData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
