import Table from "components/DataTable/Table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import QuickViewWorkOrder from "components/QuickViewWorkOrder/QuickViewWorkOrder";
import useWorkOrder from "features/Contexts/WorkOrderContext";

function CustomerWorkOrder({ id, customerWO, getCustomerWOs }) {
  const { setWorkOrderData } = useWorkOrder();

  let navigate = useNavigate();
  /*   All States
   ********************************************* */
  const [filterBy, setFilterBy] = useState(null);

  const [groupBy, setGroupBy] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  /*   All Functions
   ********************************************* */

  // Getting 12 Hours Time from SQL timestamp
  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return time;
  };

  const handleGroupBy = (value) => {
    if (!value) {
      setGroupBy((prev) => value);
      setFilteredData((prev) => null);
      return;
    }
    setGroupBy(value);
  };

  const setWorkOrderContext = async (selectedWorkOrder) => {
    await setWorkOrderData({
      data: selectedWorkOrder,
    });
    let status =
      selectedWorkOrder?.WOStatus === "pending" ||
      selectedWorkOrder?.WOStatus === "processing"
        ? "pending-processing"
        : selectedWorkOrder.WOStatus;
    return navigate(
      `/work-order/details/${status}/${selectedWorkOrder?.woId}?page=1`
    );
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (groupBy) {
        let groupedData = customerWO.filter(
          (item) => item.WOStatus === groupBy
        );
        setFilteredData(groupedData);
      }
    }, 50);
    return () => clearTimeout(timeout);
  }, [groupBy, customerWO]);

  /*   Table Functions
   ********************************************* */

  const buttons = () => (
    <div className=" d-flex justify-content-end ">
      {/* FilterBy */}

      <select
        onChange={(e) => {
          if (customerWO && e.target.value === "all") {
            handleGroupBy(null);
          } else if (customerWO && e.target.value === "pending") {
            handleGroupBy("pending");
          } else if (customerWO && e.target.value === "processing") {
            handleGroupBy("processing");
          } else if (customerWO && e.target.value === "completed") {
            handleGroupBy("completed");
          }
        }}
        defaultValue={"all"}
        className="form-select w-50"
      >
        <option value={"all"}>All</option>
        <option value={"pending"}>Pending</option>
        <option value={"processing"}>Processing</option>
        <option value={"completed"}>Completed</option>
      </select>
      {/* FilterBy */}
      <button
        type="button"
        className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {filterBy
          ? filterBy.toUpperCase().replaceAll("_", " ")
          : "Filter by All"}
      </button>
      <div className="dropdown-menu">
        <button onClick={() => setFilterBy(null)} className="dropdown-item">
          Filter by All
        </button>
        <button
          onClick={() => setFilterBy("customerName")}
          className="dropdown-item"
        >
          Customer Name
        </button>
        <button
          onClick={() => setFilterBy("jobName")}
          className="dropdown-item"
        >
          Job Name
        </button>
        <button
          onClick={() => setFilterBy("WOStatus")}
          className="dropdown-item"
        >
          WO Status
        </button>
        <button
          onClick={() => setFilterBy("createdBy")}
          className="dropdown-item"
        >
          Created By
        </button>
      </div>
    </div>
  );
  const table_head = [
    "WO ID",
    "Customer Name",
    "Job Name",
    "Created Date",
    "Created By",
    "Status",
    "Actions",
  ];

  const table_body = (item, index) => (
    <tr className="" key={item.id}>
      <td className="py-1">{item.woId}</td>
      <td className="py-1">{item.customerName}</td>
      <td className="py-1">{item.jobName}</td>
      <td className="py-1">{convertSqlTimestampToDate(item.createdAt)} </td>
      <td className="py-1">{item.createdBy} </td>
      <td className="py-1">{item.WOStatus}</td>
      <td className="table-action py-1 d-flex justify-content-center align-items-center">
        {customerWO && (
          <QuickViewWorkOrder
            refreshFunc={getCustomerWOs}
            hideCustomerViewBtn={true}
            selectedId={item?.woId}
            workOrdersList={filteredData ? filteredData : customerWO}
            btnClasses={"btn btn-sm bg-success text-white"}
          />
        )}
        <button
          onClick={() => {
            setWorkOrderContext(item);
          }}
          className={`btn btn-sm bg-success text-white mx-2 `}
        >
          Details
        </button>
      </td>
    </tr>
  );

  return (
    <div
      className="py-4 px-3 horizontal-scrollable d-flex flex-grow-1"
      style={{ borderRadius: 10 }}
    >
      <Table
        filterBy={filterBy}
        buttons={buttons}
        table_head={table_head}
        table_body={table_body}
        table_data={filteredData ? filteredData : customerWO}
      />
    </div>
  );
}

export default CustomerWorkOrder;
