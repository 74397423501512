import Table from "components/DataTable/Table";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";

import EmailFormatAdd from "./emailFormatAdd";
import EmailFormatUpdate from "./emailFormatUpdate";
import { setNotification } from "features/Notification/notificationSlice";
import Toast from "components/Toast/ToastComponent";
import Spinner from "components/Spinner";

function EmailFormat() {
  const user = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();

  /*   All States Below
   ********************************************* */

  const [emailFormats, setEmailFormats] = useState(null);
  const [lastSortId, setLastSortId] = useState(null);
  const [showLoading, setShowLoading] = useState(false);

  // Modals
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // below update data is used to set a variable before moving to update component
  const [updateData, setUpdateData] = useState(null);

  const [selectedDelete, setSelectedDelete] = useState(null);

  const emailVariables = {
    ID: " << ID  >> ",
    "CUSTOMER ID": " << Customer ID  >> ",
    "CUSTOMER NAME": " << Customer Name  >> ",
    "CONTACT NAME": " << Contact Name  >> ",
    // "INVOICE ID": " << Invoice ID  >> ",
    // "INVOICE NUMBER": " << Invoice Number  >> ",
    "INVOICE TOTAL": " << Invoice Total  >> ",
    "SIZES LIST": " << Sizes List  >> ",
    "COLORS LIST": " << Colors List  >> ",
    "FABRICS LIST": " << Fabrics List  >> ",
    "BILLING ADDRESS": " << Billing Address  >> ",
    "SHIPPING ADDRESS": " << Shipping Address  >> ",
  };

  /*   All Functions Below
   ********************************************* */

  // get All Email Format
  const getAllEmailFormat = async () => {
    try {
      const { data } = await API.get("/emailFormat", { headers: authHeader() });
      let dataReceived = data?.data;
      setEmailFormats(dataReceived);

      setLastSortId(
        dataReceived[dataReceived.length - 1]?.sortId
          ? dataReceived[dataReceived.length - 1]?.sortId
          : 0
      );
    } catch (err) {
      alert(err.message);
    }
  };

  // Delete password Data
  const deleteEmailFormat = async (id) => {
    try {
      setShowLoading(true);
      const { data } = await API.delete("/emailFormat/delete/" + id, {
        headers: authHeader(),
      });
      if (data.message.type === "success") {
        await getAllEmailFormat();
        setShowLoading(false);
        setShowDeleteModal(false);
        return;
      }
      if (data.message.type === "error") {
        dispatch(
          setNotification({
            message: data.message.message,
            type: "error",
          })
        );
      }
      setShowLoading(false);
      setShowDeleteModal(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setShowLoading(false);
      setShowDeleteModal(false);
    }
  };

  // Sorting Up
  const sortUp = async (index) => {
    const currentSortId = emailFormats[index].sortId;
    const currentId = emailFormats[index].id;
    const upSortId = emailFormats[index - 1].sortId;
    const upId = emailFormats[index - 1].id;
    try {
      const { data } = await API.post(
        "/emailFormat/sortup",
        { currentSortId, currentId, upSortId, upId },
        { headers: authHeader() }
      );
      if (data.success) {
        getAllEmailFormat();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Sorting Up
  const sortDown = async (index) => {
    const currentSortId = emailFormats[index].sortId;
    const currentId = emailFormats[index].id;
    const downSortId = emailFormats[index + 1].sortId;
    const downId = emailFormats[index + 1].id;
    try {
      const { data } = await API.post(
        "/emailFormat/sortDown",
        { currentSortId, currentId, downSortId, downId },
        { headers: authHeader() }
      );
      if (data.success) {
        getAllEmailFormat();
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    getAllEmailFormat();
  }, []);

  /*   Table Functions below
   ********************************************* */
  const buttons = () => (
    <div className="d-flex justify-content-end ms-2">
      <button onClick={() => setShowAddModal(true)} className="btn btn-primary">
        ADD NEW
      </button>
    </div>
  );
  const table_head = ["Title", "Subject", "Action"];
  const table_body = (item, index) => (
    <tr key={item.id}>
      <td>{item?.title}</td>
      <td>{item?.subject}</td>
      <td className="table-action">
        {user?.roles === "admin" && (
          <div
            onClick={() => {
              item.sortId != 1 && sortUp(index);
            }}
            className="action-icon"
          >
            <i className="mdi mdi-chevron-double-up"></i>
          </div>
        )}
        {user?.roles === "admin" && (
          <div
            onClick={() => {
              item.sortId != emailFormats.length && sortDown(index);
            }}
            className="action-icon"
          >
            <i className="mdi mdi-chevron-double-down"></i>
          </div>
        )}
        <div className="action-icon">
          <i
            onClick={() => {
              setShowUpdateModal(true);
              setUpdateData(item);
            }}
            className="mdi mdi-pencil"
          ></i>
        </div>
        <div className="action-icon">
          <i
            className="mdi mdi-delete"
            onClick={() => {
              setSelectedDelete(item);
              setShowDeleteModal(true);
            }}
          ></i>
        </div>
      </td>
    </tr>
  );

  return (
    <div className="row">
      {showLoading && <Spinner />}
      <h3>Email Template</h3>
      <div className="col-12">
        <Table
          btn1={buttons}
          table_body={table_body}
          table_data={emailFormats}
          table_head={table_head}
        />

        {/*   ADD Modal
         ********************************************* */}
        <Modal
          size="xl"
          contentClassName="bg-transparent"
          show={showAddModal}
          onHide={() => setShowAddModal(false)}
        >
          <Modal.Body
            className="shadow-lg bg-white py-3"
            style={{ borderRadius: 10 }}
          >
            <div>
              <EmailFormatAdd
                emailVariables={emailVariables}
                showAddPage={setShowAddModal}
                refreshFunc={getAllEmailFormat}
                lastSortId={lastSortId}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Update Modal
         ********************************************* */}
        <Modal
          size="xl"
          contentClassName="bg-transparent"
          show={showUpdateModal}
          onHide={() => setShowUpdateModal(false)}
        >
          <Modal.Body
            className="shadow-lg bg-white py-3"
            style={{ borderRadius: 10 }}
          >
            <div>
              <EmailFormatUpdate
                emailVariables={emailVariables}
                data={updateData}
                showUpdatePage={setShowUpdateModal}
                refreshFunc={getAllEmailFormat}
              />
            </div>
          </Modal.Body>
        </Modal>

        {/*   Confirmation Modal
         ********************************************* */}

        <Modal
          contentClassName="bg-transparent"
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
        >
          <Modal.Body>
            <div>
              <div className="bg-danger text-white p-3 custom-border-radius custom-shadow">
                <div className="text-center">
                  <i className="ri-close-circle-line h1"></i>
                  <h4 className="mt-2">Confirm Delete!</h4>
                  <p className="mt-3">Do You want to delete ?</p>
                  <button
                    type="button"
                    onClick={() => {
                      setShowDeleteModal(false);
                    }}
                    className="btn btn-light my-2 mx-2"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-light my-2 "
                    onClick={() => {
                      deleteEmailFormat(selectedDelete.id);
                    }}
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default EmailFormat;
