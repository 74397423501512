import { createContext, useContext, useState } from "react";

const CustomerContext = createContext({});

export const CustomerProvider = ({ children }) => {
  /* *************  States  **************** */
  const [advanceSearchParams, setAdvanceSearchParams] = useState(null);

  /* ******************** Functions ************************* */

  const customerValues = {
    advanceSearchParams,
    setAdvanceSearchParams,
  };
  return (
    <CustomerContext.Provider value={customerValues}>
      {children}
    </CustomerContext.Provider>
  );
};

export default function useCustomer() {
  return useContext(CustomerContext);
}
