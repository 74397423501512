import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function DesignRequestStatus({
  btnClasses,
  selectedDesign,
  refreshFunc,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [loading, setLoading] = useState(false);

  /*   All Functions
   ********************************************* */

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const updateStatus = async (status) => {
    try {
      loadingOn();
      const { data } = await API.patch(
        "/design-request/update-status",
        {
          id: selectedDesign?.id,
          status,
        },
        {
          headers: authHeader(),
        }
      );

      if (data?.type === "success") {
        dispatch(
          setNotification({
            message: data.message,
            type: "success",
          })
        );
        await refreshFunc();
      }

      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  return (
    <div>
      {loading && <Spinner />}
      <Dropdown>
        <Dropdown.Toggle className={`${btnClasses}`}>
          {selectedDesign?.status?.toUpperCase()}
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-1 py-2">
          <Dropdown.Item
            onClick={(e) => {
              updateStatus("pending");
            }}
          >
            Pending
          </Dropdown.Item>
          <Dropdown.Item
            onClick={(e) => {
              updateStatus("completed");
            }}
          >
            Completed
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
