import ImgWithSignedUrl from "components/SignedUrl/ImgWithSignedUrl";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import QuickViewProducts from "./QuickViewProducts";
import QuickViewProductSizes from "./QuickViewProductSize";

export default function QuickViewItemAnalysis({
  btnClasses,
  selectedId,
  workOrdersList,
  selectedCustomer,
}) {
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  /*   All States
   ********************************************* */

  const [showModal, setShowModal] = useState(false);

  const [selected, setSelected] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  /*   All Functions
   ********************************************* */

  // Go to previous WorkOrder
  const previousWorkOrder = () => {
    const currentIndex = workOrdersList.findIndex(
      (wo) => wo.id === selected?.id
    );

    if (currentIndex > 0) {
      setSelected(workOrdersList[currentIndex - 1]);
    }
  };

  // Go to next WorkOrder
  const nextWorkOrder = () => {
    const currentIndex = workOrdersList.findIndex(
      (wo) => wo.id === selected?.id
    );

    if (currentIndex < workOrdersList.length - 1) {
      setSelected(workOrdersList[currentIndex + 1]);
    }
  };

  /**
   * @param(selectedType) - Selected Product Type
   * Counts the number products of selected type.
   * @returns(Number) - Number of products of selected type.
   */

  /*   All UseEffects
   ********************************************* */

  // Set Selected Work Order
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (selectedId && workOrdersList) {
        setSelected(workOrdersList.find((wo) => wo.id === selectedId));
      }
    }, 50);
    return () => clearTimeout(timeout);
  }, [workOrdersList, selectedId]);

  useEffect(() => {
    setSelectedProduct(selected);
  }, [selected]);

  if (!selectedId || !workOrdersList) return null;
  return (
    <div>
      <button className={`${btnClasses}`} onClick={() => setShowModal(true)}>
        Quick View
      </button>

      <Modal
        show={showModal}
        size="xl"
        onHide={() => {
          setShowModal(false);
        }}
        contentClassName="bg-transparent"
      >
        <Modal.Body className="bg-light rounded-3 px-3 py-2">
          {/* Header Section */}
          <div className="mx- 1 me-2 d-flex justify-content-between">
            <h4 className="d-flex mb-0 flex-column">
              <div className="d-flex">
                {(user?.permissions?.includes("work_orders_add") ||
                  user?.permissions?.includes("work_orders_update") ||
                  user?.permissions?.includes("work_orders_delete") ||
                  user?.permissions?.includes("work_orders_dataEntry") ||
                  user?.roles === "admin") && (
                  <button
                    className="btn btn-sm btn-success me-2 py-0 px-1 mb-1"
                    onClick={() => {
                      // let status =
                      //   selected?.WOStatus === "pending" ||
                      //   selected?.WOStatus === "processing"
                      //     ? "pending-processing"
                      //     : selected.WOStatus;
                      navigate(
                        `/work-order/details/${selected?.WOStatus}/${selected?.WOId}?page=1`
                      );
                    }}
                  >
                    View Work Order
                  </button>
                )}
                <div>
                  WO ID {selected?.WOId} -
                  {selected?.jobName?.length <= 20
                    ? selected?.jobName
                    : `${selected?.jobName?.slice(0, 20)}..`}
                </div>
              </div>
              <div className="d-flex">
                <div className="">
                  Customer : {selectedCustomer?.customerId} -{" "}
                  {selectedCustomer?.customerName?.length <= 20
                    ? selectedCustomer?.customerName
                    : `${selectedCustomer?.customerName.slice(0, 20)}..`}
                </div>
              </div>
            </h4>
            <div className="d-flex justify-content-end align-items-center flex-grow-1 ">
              <h5 className="me-1">WO Status</h5>
              <div className="me-1 ">
                {user?.roles === "customer" ? (
                  <input
                    className="form-control"
                    readOnly
                    value={selected?.WOStatus}
                  />
                ) : (
                  <select
                    disabled
                    value={selected?.WOStatus}
                    className="form-select"
                  >
                    <option value="pending">Pending</option>
                    <option value="processing">Processing</option>
                    <option value="completed">Completed</option>
                  </select>
                )}
              </div>
              <div className="d-flex gap-1">
                <button
                  onClick={() => {
                    previousWorkOrder();
                  }}
                  disabled={workOrdersList?.length === 0}
                  className={"btn btn-success btn-sm p-0 px-1 "}
                >
                  <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
                </button>
                <button
                  onClick={() => {
                    nextWorkOrder();
                  }}
                  disabled={workOrdersList?.length === 0}
                  className={"btn btn-success btn-sm p-0  px-1"}
                >
                  <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
          <hr className="mt-1 mb-2" />

          {/*   Top Row ( Products & Sizes )
           ********************************************* */}
          <div className="row ">
            {/* Products Info */}
            <div style={{ padding: "5px 5px" }} className="col-md-6 mx-0 ">
              <div
                style={{ paddingBottom: "1.1rem" }}
                className="bg-white rounded-3 shadow px-3 pt-2"
              >
                {selected && selectedProduct ? (
                  <QuickViewProducts
                    user={user}
                    selected={selected}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    productsList={[selected]}
                    productTypesList={[]}
                  />
                ) : (
                  <div className="flex-grow-1">No Products</div>
                )}
              </div>
            </div>
            {/* Sizes Info */}
            <div style={{ padding: "5px 5px" }} className="col-md-6 mx-0">
              <div className="">
                <QuickViewProductSizes selectedProduct={selectedProduct} />
              </div>
            </div>
          </div>
          {/*   Top Row ( Img Preview & Notes )
           ********************************************* */}
          <div className="row ">
            {/* Product Image */}
            <div style={{ padding: "5px 5px" }} className="col-md-6 mx-0">
              <div
                style={{ paddingBottom: "1.1rem" }}
                className="bg-white rounded-3 shadow px-3 pt-2"
              >
                <div>
                  {selectedProduct?.productImage ? (
                    <ImgWithSignedUrl
                      height={300}
                      width={500}
                      path={selectedProduct?.productImage}
                    />
                  ) : (
                    <div
                      style={{
                        minHeight: 300,
                        minWidth: 300,
                      }}
                      className="h3 flex-center text-muted  text-center my-auto"
                    >
                      NO Image{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Notes */}
            <div style={{ padding: "5px 5px" }} className="col-md-6 mx-0">
              <div className="bg-white rounded-3 shadow-sm p-1">
                <div className="">
                  <ReactQuill
                    modules={modules}
                    theme="snow"
                    value={selectedProduct?.notes}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
