import ImgWithSignedUrl from "components/SignedUrl/ImgWithSignedUrl";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

function CustomerDetailsColumn({ customerDetails, refreshFunc }) {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));

  /*   ALL STATES
   ********************************************* */
  const [formData, setFormData] = useState({ ...customerDetails });
  const [customerDetailsModal, setCustomerDetailsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [paymentTerms, setPaymentTerms] = useState(null);
  const [discountLevel, setDiscountLevel] = useState(false);

  const [filesList, setFilesList] = useState([]);

  /*   ALL FUNCTIONS
   ********************************************* */
  const updateCustomerDetails = async (e) => {
    e.preventDefault();

    const newFormData = new FormData();

    for (let i = 0; i < filesList.length; i++) {
      newFormData.append("files", filesList[i]?.file);
    }

    console.log(formData);

    let updatedData = {
      ...formData,
      paymentTermsId:
        formData.paymentTermsId === "null" ? null : formData.paymentTermsId,
      discountId: formData.discountId === "null" ? null : formData.discountId,
      id: customerDetails.id,
    };
    newFormData.append("updatedData", JSON.stringify(updatedData));

    newFormData.append(
      "folder",
      `kms/companyLogos/${user?.companyId}/companyLogo/customerLogos/${customerDetails?.id}`
    );

    let updatedFilesList = filesList.map((item) => {
      let folder;

      if (item?.title === "customerLogo") {
        folder = `kms/companyLogos/${user?.companyId}/companyLogo/customerLogos/${customerDetails?.id}`;
      } else {
        folder = `kms/customer/${customerDetails?.id}/brandNeckLabels`;
      }

      return {
        folder,
        originalname: item?.fileName,
        title: item?.title,
      };
    });
    newFormData.append("filesList", JSON.stringify(updatedFilesList));
    setIsLoading(true);

    try {
      const { data } = await API.patch("/customers", newFormData, {
        headers: {
          "x-access-token": user?.accessToken,
          "Content-Type": "multipart/form-data",
        },
      });
      if (data.success) {
        refreshFunc(customerDetails.id);
        setIsLoading(false);
        setCustomerDetailsModal(false);
        return;
      }
      dispatch(
        setNotification({
          message: data,
          type: "error",
        })
      );
    } catch (err) {
      setIsLoading(false);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleFileSelect = (e) => {
    let { id, name } = e.target;
    id = parseInt(id);
    let file = e.target.files[0];
    // Only Allow png, jpg, jpeg files
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg"
    ) {
      e.target.value = null;
      return dispatch(
        setNotification({
          message: "Only png, jpg, jpeg files are allowed",
          type: "error",
        })
      );
    }

    let fileName = file.name;

    // Check if file with same id is present
    let isPresent = filesList.find((item) => item.id === id);
    if (isPresent) {
      let updatedFilesList = filesList.map((item) => {
        if (item.id === id) {
          return { ...item, file, fileName };
        }
        return item;
      });
      setFilesList(updatedFilesList);
    } else {
      setFilesList((prev) => {
        return [
          ...prev,
          {
            id,
            title: name,
            file,
            fileName,
          },
        ];
      });
    }
  };

  const deleteNeckLabel = async (key) => {
    try {
      setIsLoading(true);
      const { data } = await API.post(
        "/customers/delete-neck-label",
        {
          id: customerDetails?.id,
          file: key,
        },
        { headers: authHeader() }
      );
      console.log(data);
      await refreshFunc();
      dispatch(
        setNotification({
          message: data,
          type: "error",
        })
      );
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getPaymentTermName = (id) => {
    const item = paymentTerms?.find((item) => item.id === id);
    if (item) {
      return item.termName;
    } else {
      return null;
    }
  };

  const getPaymentTerms = async () => {
    try {
      const { data } = await API.get("/paymentterms", {
        headers: authHeader(),
      });
      setPaymentTerms(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getAllDiscounts = async () => {
    try {
      const { data } = await API.get("/discountlevels", {
        headers: authHeader(),
      });
      setDiscountLevel(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    setFormData(customerDetails);
  }, [customerDetails]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      getAllDiscounts();
      getPaymentTerms();
    }, 50);
    return () => clearTimeout(timeout);
  }, []);

  if (!discountLevel || !paymentTerms) return <Spinner />;
  return (
    <>
      <div className="d-flex justify-content-between align-items-center w-100 mb-1">
        <h4 className="border-bottom border-secondary">CUSTOMER DETAILS</h4>
        <div className="ms-2">
          <button
            className="btn d-flex justify-content-center align-items-center btn-sm btn-success"
            style={{ width: 28, height: 28 }}
            onClick={() => {
              setCustomerDetailsModal(true);
              setFormData(customerDetails);
            }}
          >
            <i className="mdi fs-5 mdi-pencil"></i>
          </button>
        </div>
      </div>
      <div className="d-flex align-items-start">
        <div className="d-flex  flex-column me-2">
          <div className="text-right">External Customer ID</div>
          <div className="">PO Required</div>
          <div className="">Payment Terms</div>
          <div className="">Credit Limit</div>
          <div className="">Credit Hold</div>
        </div>
        <div className="flex-grow-1">
          <div className="">
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.externalCustomerId
              ? customerDetails.externalCustomerId
              : "-"}
          </div>
          <div className="">
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.poRequired ? "Yes" : "No"}
          </div>
          <div className="">
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.paymentTermsId
              ? getPaymentTermName(customerDetails.paymentTermsId)
              : "-"}
          </div>
          <div className="">
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.creditLimit ? customerDetails.creditLimit : "-"}
          </div>
          <div className="">
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.creditHold ? "Yes" : "No"}
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex align-items-start">
        <div className="d-flex flex-column me-2 " style={{ minWidth: 110 }}>
          <div className="">Website</div>

          <div className="text-right">Discount</div>
          <div className="">Customer Reference</div>
          <div className="">Customer Type</div>
          <div className="">Custom Field 1</div>
          <div className="">Custom Field 2</div>
          <div className="">Custom Field 3</div>
        </div>

        <div className="">
          <div className="">
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.website ? customerDetails.website : "-"}
          </div>

          <div className="">
            <div className="">
              <div className="">
                :&nbsp;&nbsp;&nbsp;{" "}
                {customerDetails?.discountId ? customerDetails.discountId : "-"}
              </div>
            </div>
            <div className=" ">
              :&nbsp;&nbsp;&nbsp;
              {customerDetails?.customerReference
                ? customerDetails.customerReference
                : "-"}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              :&nbsp;&nbsp;&nbsp;
              {customerDetails?.customerType
                ? customerDetails.customerType
                : "-"}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              :&nbsp;&nbsp;&nbsp;
              {customerDetails?.customField1
                ? customerDetails.customField1
                : "-"}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              :&nbsp;&nbsp;&nbsp;
              {customerDetails?.customField2
                ? customerDetails.customField2
                : "-"}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              :&nbsp;&nbsp;&nbsp;
              {customerDetails?.customField3
                ? customerDetails.customField3
                : "-"}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="flex-grow-1 d-flex align-items-start">
        <div className="d-flex flex-column me-2 " style={{ minWidth: 110 }}>
          <div className="">Commission</div>
          <div className="">Commission Type</div>
          <div className="">Commission Value</div>
          <div className="">Brand Neck Label</div>
          <div className="">Record Name</div>
          <div className="">Record Email</div>
          <div className="">Record Phone</div>
          <div className="">Record Url</div>
          <div className="">Bid Url</div>
        </div>

        <div className="">
          <div className=" ">
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.isCommission ? "Yes" : "No"}
          </div>
          <div className=" ">
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.commissionType
              ? customerDetails.commissionType
              : "-"}
          </div>
          <div className=" ">
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.commissionValue
              ? customerDetails.commissionValue
              : "-"}
          </div>
          <div className=" ">
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.brandNeckLabel ? (
              <ImgWithSignedUrl
                path={customerDetails?.brandNeckLabel}
                width={30}
                height={30}
              />
            ) : (
              "-"
            )}
          </div>
          <div className=" ">
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.recordName ? customerDetails.recordName : "-"}
          </div>
          <div className=" ">
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.recordEmail ? customerDetails.recordEmail : "-"}
          </div>
          <div className=" ">
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.recordPhone ? customerDetails.recordPhone : "-"}
          </div>
          <div
            onDoubleClick={() => {
              navigator.clipboard.writeText(customerDetails.recordUrl);
            }}
            className=" "
          >
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.recordUrl
              ? customerDetails.recordUrl?.slice(0, 20)
              : "-"}
          </div>
          <div
            onDoubleClick={() => {
              navigator.clipboard.writeText(customerDetails?.bidUrl);
            }}
            className="text-wrap"
          >
            :&nbsp;&nbsp;&nbsp;
            {customerDetails?.bidUrl
              ? customerDetails?.bidUrl?.slice(0, 20)
              : "-"}
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={customerDetailsModal}
        onHide={() => setCustomerDetailsModal(false)}
      >
        <Modal.Body>
          <form onSubmit={updateCustomerDetails}>
            <div className="row p-3 pt-1">
              <div className="">
                <h4 className="">Customer Details</h4>
              </div>
              <hr className="mb-3 mt-0" />
              <div className="col-12 mb-2">
                <div className="row mb-2">
                  <label className="col-2 my-auto">Customer Name</label>
                  <div className="col-10">
                    <input
                      type="text"
                      value={formData?.customerName}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          customerName: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter Customer Name"
                    />
                  </div>
                </div>
                <div className="">
                  <label className="form-label">
                    Customer Logo ( W x H : 200px x 100px )
                  </label>
                  <input
                    onChange={handleFileSelect}
                    id="customerLogo"
                    name="customerLogo"
                    className="form-control"
                    type="file"
                  />
                </div>
                <hr />
              </div>
              {/* <hr className='mb-3 mt-0' /> */}
              <div className="col-6">
                <div className="row mb-1">
                  <label className="col-4 my-auto">External Customer ID</label>
                  <div className="col-8 ">
                    <input
                      type="text"
                      value={formData?.externalCustomerId}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          externalCustomerId: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter external customer ID"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-4">Po Required</label>
                  <div className="col-8 ">
                    <input
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          poRequired: e.target.checked,
                        })
                      }
                      checked={formData?.poRequired}
                      type="checkbox"
                      id="switch0"
                      data-switch="none"
                    />
                    <label
                      htmlFor="switch0"
                      data-on-label="Yes"
                      data-off-label="No"
                    ></label>
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-4">Payment Terms</label>
                  <div className="col-8 ">
                    <select
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          paymentTermsId: e.target.value,
                        })
                      }
                      className="form-select"
                    >
                      <option value="null">None</option>
                      {paymentTerms?.map((item) => (
                        <option
                          key={item?.id}
                          selected={
                            customerDetails?.paymentTermsId === item?.id
                          }
                          value={item?.id}
                        >
                          {item?.termName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-4 my-auto">Credit Limit</label>
                  <div className="col-8 ">
                    <input
                      type="number"
                      min={0}
                      value={Math.abs(formData?.creditLimit)}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          creditLimit: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter Credit Limit654"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-4">Credit Hold</label>
                  <div className="col-8 ">
                    <input
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          creditHold: e.target.checked,
                        })
                      }
                      checked={formData?.creditHold}
                      type="checkbox"
                      id="switchCreditHold"
                      data-switch="none"
                    />
                    <label
                      htmlFor="switchCreditHold"
                      data-on-label="Yes"
                      data-off-label="No"
                    ></label>
                  </div>
                </div>
                <hr />
                <div className="row mb-1">
                  <label className="col-4 my-auto">Website</label>
                  <div className="col-8 ">
                    <input
                      type="text"
                      value={formData?.website}
                      onChange={(e) =>
                        setFormData({ ...formData, website: e.target.value })
                      }
                      className="form-control"
                      placeholder="Enter Website"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-4 my-auto">Discount</label>
                  <div className="col-8 ">
                    <select
                      onChange={(e) =>
                        setFormData({ ...formData, discountId: e.target.value })
                      }
                      value={formData?.discountId}
                      className="form-control"
                    >
                      <option value={null}>None</option>
                      {discountLevel?.map((item) => (
                        <option key={item?.id} value={item?.id}>
                          {item?.discountName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <label className="col-4 my-auto">Customer Refference</label>
                  <div className="col-8 ">
                    <input
                      type="text"
                      value={formData?.customerReference}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          customerReference: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter Customer Reference"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-4 my-auto">Customer Type</label>
                  <div className="col-8 ">
                    <input
                      type="text"
                      value={formData?.customerType}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          customerType: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter Customer Type"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-4 my-auto">Custom Field 1</label>
                  <div className="col-8 ">
                    <input
                      type="text"
                      value={formData?.customField1}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          customField1: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter Custom Field 1"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-4 my-auto">Custom Field 2</label>
                  <div className="col-8 ">
                    <input
                      type="text"
                      value={formData?.customField2}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          customField2: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter Customer Type"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-4 my-auto">Custom Field 3</label>
                  <div className="col-8 ">
                    <input
                      type="text"
                      value={formData?.customField3}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          customField3: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter Customer Type"
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 d-flex flex-column">
                <div className="row mb-1">
                  <label className="col-4">Commission</label>
                  <div className="col-8 ">
                    <input
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          isCommission: e.target.checked,
                        });
                      }}
                      checked={formData?.isCommission}
                      type="checkbox"
                      id="switch1"
                      data-switch="none"
                    />
                    <label
                      htmlFor="switch1"
                      data-on-label="Yes"
                      data-off-label="No"
                    ></label>
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-4 my-auto">Commission Type</label>
                  <div className="col-8 ">
                    <input
                      type="text"
                      disabled={!formData?.isCommission}
                      value={formData?.commissionType}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          commissionType: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter Commission Type"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-4 my-auto">Commission Value</label>
                  <div className="col-8 ">
                    <input
                      type="number"
                      min={0}
                      step={"any"}
                      disabled={!formData?.isCommission}
                      value={formData?.commissionValue}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          commissionValue: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter Commission Value"
                    />
                  </div>
                </div>
                <hr />
                <div className="mb-3">
                  <label className="form-label">
                    Brand Neck Label ( W x H : 200px x 200px )
                  </label>
                  <input
                    onChange={handleFileSelect}
                    id="brandNeckLabel"
                    name="brandNeckLabel"
                    className="form-control"
                    type="file"
                  />
                </div>
                <div>
                  {formData?.brandNeckLabel && (
                    <div style={{ position: "relative" }}>
                      <ImgWithSignedUrl
                        path={formData?.brandNeckLabel}
                        height={80}
                        width={80}
                      />
                      <button
                        onClick={() => {
                          deleteNeckLabel(formData?.brandNeckLabel);
                        }}
                        className="btn btn-danger btn-sm p-0 px-1"
                        style={{ position: "absolute", top: 0, left: 0 }}
                      >
                        X
                      </button>
                    </div>
                  )}
                </div>
                <div className="row mb-1">
                  <label className="col-4 my-auto">Record Name</label>
                  <div className="col-8 ">
                    <input
                      type="text"
                      value={formData?.recordName}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          recordName: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter Record Name"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-4 my-auto">Record Email</label>
                  <div className="col-8 d-flex gap-1 align-items-center">
                    <button
                      type="button"
                      onClick={(e) => {
                        setFormData({
                          ...formData,
                          recordEmail: formData?.recordName,
                        });
                      }}
                      className="btn btn-success px-1 py-0 btn-sm"
                    >
                      C
                    </button>
                    <input
                      type="text"
                      value={formData?.recordEmail}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          recordEmail: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter Record Email"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-4 my-auto">Record Phone</label>
                  <div className="col-8 d-flex gap-1 align-items-center">
                    <button
                      type="button"
                      onClick={(e) => {
                        setFormData({
                          ...formData,
                          recordPhone: formData?.recordEmail,
                        });
                      }}
                      className="btn btn-success px-1 py-0 btn-sm"
                    >
                      C
                    </button>
                    <input
                      type="text"
                      value={formData?.recordPhone}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          recordPhone: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter Record Phone"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-4 my-auto">Record Url</label>
                  <div className="col-8 d-flex gap-1 align-items-center">
                    <button
                      type="button"
                      onClick={(e) => {
                        setFormData({
                          ...formData,
                          recordUrl: formData?.recordPhone,
                        });
                      }}
                      className="btn btn-success px-1 py-0 btn-sm"
                    >
                      C
                    </button>
                    <input
                      type="text"
                      value={formData?.recordUrl}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          recordUrl: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter Record Url"
                    />
                  </div>
                </div>

                <div className="row mb-1">
                  <label className="col-4 my-auto">Bid Url</label>
                  <div className="col-8 d-flex gap-1 align-items-center">
                    <button
                      type="button"
                      onClick={(e) => {
                        setFormData({
                          ...formData,
                          bidUrl: formData?.recordUrl,
                        });
                      }}
                      className="btn btn-success px-1 py-0 btn-sm"
                    >
                      C
                    </button>
                    <input
                      type="text"
                      value={formData?.bidUrl}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          bidUrl: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Enter Bid Url"
                    />
                  </div>
                </div>

                {isLoading && <Spinner />}
                {/*   Buttons
                 ********************************************* */}
                <div className="mt-3 flex-grow-1 d-flex justify-content-end align-items-end">
                  <div>
                    <button
                      onClick={() => {
                        setCustomerDetailsModal(false);
                      }}
                      type="button"
                      className="btn btn-secondary me-2 px-1"
                    >
                      CLOSE
                    </button>
                    <button type="submit" className="btn btn-primary">
                      UPDATE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CustomerDetailsColumn;
