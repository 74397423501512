import React, { useEffect, useState } from "react";
import API from "services/axios";
import authHeader from "services/auth-header";
import Modal from "react-bootstrap/Modal";
import ImportContacts from "./ImportContacts";
import axios from "axios";
import CustomerDetails from "./CustomerDetails";
import CustomerFiles from "./CustomerFile";
import useWorkOrder from "features/Contexts/WorkOrderContext";
import CustomerWorkOrder from "./CustomerWorkOrder/CustomerWorkOrder";
import C_Quotes from "./C_Quotes/C_Quotes";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setNotification } from "features/Notification/notificationSlice";
import { useDispatch } from "react-redux";
import useCustomer from "features/Contexts/CustomerContext";
// import Spinner from "components/Spinner";
import ItemAnalysis from "./ItemAnalysis/ItemAnalysis";
import { Spinner as RSpinner } from "react-bootstrap";
import Spinner from "components/Spinner";

function CustomerDetailsLayout({}) {
  const dispatch = useDispatch();
  /*   All States
   ********************************************* */

  const params = useParams();
  const navigate = useNavigate();

  const { clearCustomerData, customerContextData } = useWorkOrder();
  const { advanceSearchParams, setAdvanceSearchParams } = useCustomer();

  const [searchParams, setSearchParams] = useSearchParams();

  const [allCustomersData, setAllCustomersData] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [showImportModal, setShowImportModal] = useState(false);

  /* ******************** Tabs ************************* */
  const [customerFiles, setCustomerFiles] = useState(null);
  const [customerWO, setCustomerWO] = useState(null);
  const [customerQuotes, setCustomerQuotes] = useState(null);

  const [customerContactData, setCustomerContactData] = useState(null);
  const [customersAddressData, setCustomersAddressData] = useState(null);

  const [active, setActive] = useState(
    customerContextData ? "work Order" : "customer_details"
  );
  const checkActive = (val) => {
    setActive(val);
  };

  const [loading, setLoading] = useState(false);

  const [setTabsLoading, setSetTabsLoading] = useState(false);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };

  const loadingOff = () => {
    setLoading(false);
  };

  const getAllCustomersData = async () => {
    try {
      let currentID = params?.selectedID;

      let page = searchParams?.get("page");
      if (!page) {
        const { data } = await API.get("/customers/" + currentID, {
          headers: authHeader(),
        });

        if (data?.success) {
          let result = [data.data];
          setAllCustomersData(result);
          setSelectedCustomer(data.data);
        }
      } else {
        let query = searchParams?.get("query");
        let location = searchParams?.get("location");
        let filterBy = searchParams?.get("filterBy");

        let body = { page, location, filterBy };

        if (query) {
          body.query = query;
        }
        const { data } = await API.post("/customers", body, {
          headers: authHeader(),
        });

        setAllCustomersData(data?.result);
        let matchedOrderById = data?.result?.find(
          (item) => item?.customerId === parseInt(currentID)
        );

        if (matchedOrderById) {
          setSelectedCustomer(matchedOrderById);
        }

        return data.result;
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getCustomersByAdvanceForm = async () => {
    try {
      loadingOn();
      const { data } = await API.post(
        "/customer/advance-search",
        advanceSearchParams,
        {
          headers: authHeader(),
        }
      );
      let currentID = params?.selectedID;

      setAllCustomersData(data);
      let matchedOrderById = data?.find(
        (item) => item?.customerId === parseInt(currentID)
      );

      if (matchedOrderById) {
        setSelectedCustomer(matchedOrderById);
      }

      loadingOff();
      return data;
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const previousCustomer = () => {
    let curCustomerId = params.selectedID;

    let checkCrrIdExists = allCustomersData?.find(
      (item) => item?.customerId === parseInt(curCustomerId)
    );

    if (!checkCrrIdExists) {
      return;
    }
    let currentIndex = allCustomersData?.indexOf(selectedCustomer);
    if (currentIndex === 0) {
      return;
    }
    let newId = allCustomersData[currentIndex - 1]?.customerId;
    navigate(`/customer/details/${newId}/?${searchParams.toString()}`);
  };

  const nextCustomer = () => {
    let curQuoteId = params.selectedID;
    let checkCrrIdExists = allCustomersData?.find(
      (item) => item?.customerId === parseInt(curQuoteId)
    );
    if (!checkCrrIdExists) {
      return;
    }
    let currentIndex = allCustomersData?.indexOf(selectedCustomer);
    let totalLength = allCustomersData?.length;
    if (currentIndex === totalLength - 1) {
      return;
    }
    let newId = allCustomersData[currentIndex + 1]?.customerId;
    navigate(`/customer/details/${newId}/?${searchParams.toString()}`);
  };

  /*  Customer Contacts & Address
   ********************************************* */

  const getCustomerInfo = async () => {
    try {
      const { data } = await API.post(
        "/customerinfo",
        { id: selectedCustomer?.id },
        {
          headers: authHeader(),
        }
      );
      setCustomerContactData(parseJsonData(data.contacts));
      setCustomersAddressData(parseJsonData(data.addresses));
    } catch (err) {
      console.log(err.message);
    }
  };

  const parseJsonData = (data) => {
    let newArray = [];
    data.map((item) => {
      newArray.push({ ...item, ...JSON.parse(item.data) });
    });
    return newArray;
  };

  const downloadSampleFile = () => {
    axios
      .post(`${process.env.REACT_APP_API_URI}/aws/images`, {
        key: "kms/customer/Customer Info - Sample-.xlsx",
      })
      .then((response) => {
        const url = response.data[0];
        const link = document.createElement("a");
        link.href = url;
        link.download = "Customer Info - Sample-.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log("Error downloading file:", error);
      });
  };

  const copyContent = (val) => {
    navigator.clipboard.writeText(val);
  };

  /* ******************** Tabs ************************* */
  // Get Customer Files
  const getCustomerFiles = async () => {
    try {
      const { data } = await API.post(
        "customer-file/listfiles",
        {
          id: selectedCustomer?.id,
        },
        { headers: authHeader() }
      );

      setCustomerFiles(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  // Get Customer Work Order
  const getCustomerWOs = async () => {
    try {
      const { data } = await API.post(
        "customer/work-order",
        {
          id: selectedCustomer?.id,
        },
        { headers: authHeader() }
      );
      setCustomerWO(data);
      return data;
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getCustomerQuotes = async () => {
    try {
      const { data } = await API.get(
        "/customers/quotes/" + selectedCustomer?.id,
        {
          headers: authHeader(),
        }
      );
      setCustomerQuotes(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let tid = setTimeout(async () => {
      if (selectedCustomer) {
        setSetTabsLoading(true);
        setCustomerFiles([]);
        setCustomerWO([]);
        await getCustomerFiles();
        await getCustomerWOs();
        await getCustomerQuotes();
        setSetTabsLoading(false);
      }
    }, 50);
    return () => {
      clearTimeout(tid);
    };
  }, [selectedCustomer]);

  useEffect(() => {
    if (selectedCustomer) {
      let timeout = setTimeout(() => {
        getCustomerInfo();
      }, 50);
      return () => clearTimeout(timeout);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (params) {
      let { selectedID } = params;

      if (selectedID) {
        if (advanceSearchParams) {
          getCustomersByAdvanceForm();
        } else {
          getAllCustomersData();
        }
      }
    }
  }, [params, advanceSearchParams]);

  return (
    <div
      className="flex-grow-1 d-flex flex-column"
      style={{ position: "relative" }}
    >
      {loading && <Spinner />}
      {/*   Top Nav For Customer
       ********************************************* */}
      <div
        className="d-flex justify-content-end align-items-center bg-white shadow-lg ps-5 pe-4 py-2 z-3"
        style={{
          borderRadius: "10px",
          position: "fixed",
          top: 0,
          width: "calc(100vw - 200px)",
          marginLeft: 200,
          zIndex: 50,
        }}
      >
        <div className="flex-grow-1">
          <div className="d-flex align-items-center">
            ID : &nbsp; {selectedCustomer?.customerId}
            <div className="ms-3 d-flex align-items-center">
              <h4
                onDoubleClick={() => {
                  copyContent(selectedCustomer?.customerName?.toUpperCase());
                }}
                className="user-select-none"
              >
                {selectedCustomer?.customerName?.toUpperCase()}
              </h4>
            </div>
          </div>
        </div>
        <button
          onClick={previousCustomer}
          className="btn btn-success btn-sm my-1 px-2 py-0  "
        >
          <i className="mdi mdi-arrow-left-bold fs-4 py-0 my-0"></i>
        </button>
        <button
          onClick={nextCustomer}
          className="btn btn-success ms-1 me-2 btn-sm my-1 px-2 py-0  "
        >
          <i className="mdi mdi-arrow-right-bold fs-4 py-0 my-0"></i>
        </button>

        <button
          className="btn my-1 mx-1 btn-success btn-sm"
          onClick={() => {
            setShowImportModal(true);
          }}
        >
          IMPORT
        </button>
        <button
          onClick={() => downloadSampleFile()}
          type="button"
          className="btn btn-sm px-2 btn-info me-1"
        >
          <i className="mdi mdi-download"></i> SAMPLE FILE
        </button>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => {
            clearCustomerData();
            setAdvanceSearchParams(null);
            navigate(`/customer/?${searchParams.toString()}`);
          }}
        >
          Back
        </button>
      </div>
      {/* Main Content */}
      <div className="d-flex px-2 " style={{ marginLeft: 260, marginTop: 80 }}>
        <div className=" d-flex flex-column flex-grow-1">
          <div
            className="flex-grow-1 d-flex flex-column mt-2"
            style={{ borderRadius: 10 }}
          >
            {/* Customer Details Tabs */}
            <ul className="nav nav-tabs nav-bordered mb-2  d-flex flex-grow-1">
              <li
                onClick={() => {
                  checkActive("customer_details");
                }}
                className="nav-item"
              >
                <button
                  className={
                    active === "customer_details"
                      ? `nav-link rounded-0 active bg-primary text-white`
                      : "nav-link rounded-0"
                  }
                >
                  <span className="d-none d-md-block">Customer Details</span>
                </button>
              </li>
              <li
                onClick={() => {
                  checkActive("file_manager");
                }}
                className=""
              >
                <button
                  className={
                    active === "file_manager"
                      ? `nav-link rounded-0 active bg-primary text-white`
                      : "nav-link rounded-0"
                  }
                >
                  <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                  <span className="d-none d-md-block">
                    File Manager{" "}
                    {customerFiles ? `(${customerFiles.length})` : ""}
                  </span>
                </button>
              </li>
              <li
                onClick={() => {
                  checkActive("quotes");
                }}
                className=""
              >
                <button
                  className={
                    active === "quotes"
                      ? `nav-link rounded-0 active bg-primary text-white`
                      : "nav-link rounded-0"
                  }
                >
                  <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                  <span className="d-none d-md-block">
                    Quotes {customerQuotes ? `(${customerQuotes.length})` : ""}
                  </span>
                </button>
              </li>
              <li
                onClick={() => {
                  checkActive("work Order");
                }}
                className=""
              >
                <button
                  className={
                    active === "work Order"
                      ? `nav-link rounded-0 active bg-primary text-white`
                      : "nav-link rounded-0"
                  }
                >
                  <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                  <span className="d-none d-md-block">
                    Work Orders
                    {customerWO ? `(${customerWO.length})` : ""}
                  </span>
                </button>
              </li>
              <li
                onClick={() => {
                  checkActive("item analysis");
                }}
                className=""
              >
                <button
                  className={
                    active === "item analysis"
                      ? `nav-link rounded-0 active bg-primary text-white`
                      : "nav-link rounded-0"
                  }
                >
                  <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                  <span className="d-none d-md-block">Item Analysis</span>
                </button>
              </li>
              <li className="">
                <button className={"nav-link rounded-0"}>
                  <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                  <span className="d-none d-md-block">
                    {setTabsLoading && (
                      <RSpinner animation="border" size="sm" />
                    )}
                  </span>
                </button>
              </li>
            </ul>
            {/* Customer Tabs Content */}
            <div className="d-flex flex-grow-1">
              {active === "customer_details" &&
                allCustomersData &&
                selectedCustomer && (
                  <CustomerDetails
                    customerContactData={customerContactData}
                    customersAddressData={customersAddressData}
                    selectedCustomer={selectedCustomer}
                    allCustomersData={allCustomersData}
                    getCustomerInfo={getCustomerInfo}
                    getAllCustomersData={getAllCustomersData}
                  />
                )}
              {active === "file_manager" && (
                <div
                  className=" d-flex flex-grow-1 bg-white shadow-lg px-4"
                  style={{ borderRadius: 10 }}
                >
                  <CustomerFiles
                    customerFiles={customerFiles}
                    setCustomerFiles={setCustomerFiles}
                    getCustomerFiles={getCustomerFiles}
                  />
                </div>
              )}
              {active === "quotes" && selectedCustomer && (
                <div
                  className=" d-flex flex-grow-1  justify-content-start bg-white shadow-lg "
                  style={{ borderRadius: 10 }}
                >
                  <C_Quotes
                    id={selectedCustomer?.id}
                    customerQuotes={customerQuotes}
                    getCustomerQuotes={getCustomerQuotes}
                  />
                </div>
              )}
              {active === "work Order" && selectedCustomer && (
                <div
                  className=" d-flex flex-grow-1  justify-content-start bg-white shadow-lg "
                  style={{ borderRadius: 10 }}
                >
                  <CustomerWorkOrder
                    id={selectedCustomer?.id}
                    customerWO={customerWO}
                    getCustomerWOs={getCustomerWOs}
                  />
                </div>
              )}
              {active === "item analysis" && selectedCustomer && (
                <div
                  className=" d-flex flex-grow-1  justify-content-start bg-white shadow-lg "
                  style={{ borderRadius: 10 }}
                >
                  <ItemAnalysis selectedCustomer={selectedCustomer} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*   Import Modal
       ********************************************* */}
      <Modal
        show={showImportModal}
        size="lg"
        onHide={() => setShowImportModal(false)}
      >
        <Modal.Body>
          <ImportContacts
            id={selectedCustomer?.id}
            customerContactData={customerContactData}
            customersAddressData={customersAddressData}
            refreshFunc={getCustomerInfo}
            setShowImportModal={setShowImportModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CustomerDetailsLayout;
