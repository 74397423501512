import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

function MockupFabricsAdd({ showAddPage, refreshFunc, lastSortId }) {
  const dispatch = useDispatch();

  /*   All States Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions Below
   ********************************************* */
  const addMockupFabricsData = async (formData, e) => {
    e.preventDefault();

    try {
      loadingOn();
      const { data } = await API.post(
        "/mockup-fabric",
        { ...formData, sortId: lastSortId + 1 },
        { headers: authHeader() }
      );
      if (data?.type === "success") {
        refreshFunc();
        showAddPage(false);
        loadingOff();
        return;
      }
      console.log(data);

      loadingOff();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        {loading && <Spinner />}
        {/*    ADD FORM START
         ********************************************* */}
        <div className="mb-13 ms-3">
          <h3>ADD Fabric</h3>
        </div>
        <form
          className="ps-3 pe-3"
          onSubmit={handleSubmit(addMockupFabricsData)}
        >
          {/* ----------  Fields  ----------- */}
          <div className="mt-3 mb-3">
            <label htmlFor="name" className="form-label">
              Fabric Name
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              required
              placeholder="Enter Fabric name"
              {...register("name")}
            />
          </div>

          <div className={`mt-4 mb-2 d-flex justify-content-end`}>
            <div>
              <button
                className="btn px-1 btn-secondary"
                type="button"
                onClick={() => {
                  showAddPage(false);
                }}
              >
                Cancel
              </button>
              <button className="btn btn-primary ms-1" type="submit">
                ADD
              </button>
            </div>
          </div>
        </form>

        {/*    ADD FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default MockupFabricsAdd;
