import { setNotification } from "features/Notification/notificationSlice";
import CustomerSelectModal from "pages/BidPlanner/DetailPage/SelectCustomer/CustomerSelectModal";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";

function QuoteAdd({ showAddModal }) {
  const navigate = useNavigate();

  /*   All Satates Below
   ********************************************* */

  const [customerData, setCustomerData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState(null);

  const [showCustomerModal, setShowCustomerModal] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const dispatch = useDispatch();

  /*   All Functions Below
   ********************************************* */

  const handleAddQuote = async (e) => {
    e.preventDefault();
    try {
      let bodyData = {
        status: "pending",
        customerId: selectedCustomer?.id,
        locationId: selectedCustomer?.locationId,
        contactFirstName:
          selectedCustomer?.accountPayableContact?.split(" ")[0],
        contactLastName: selectedCustomer?.accountPayableContact?.split(" ")[1],
        contactPhone: selectedCustomer?.accountPayablePhone,
        contactEmail: selectedCustomer?.accountPayableEmail,
        taxRateId: selectedCustomer?.taxAccountId,
      };
      const { data } = await API.post("/quotes-add", bodyData, {
        headers: authHeader(),
      });
      if (data?.type === "success") {
        navigate(`/quotes/details/pending/${data?.data?.id}?page=1`);
        showAddModal(false);
      } else {
        console.log(data);
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleSelectedCustomer = (customer) => {
    setSelectedCustomer(customer);
  };

  const parseJsonData = (data) => {
    let newArray = [];
    data.map((item) => {
      newArray.push({ ...item, ...JSON.parse(item.data) });
    });
    return newArray;
  };

  const getCustomersData = async () => {
    try {
      const { data } = await API.get("/customers", { headers: authHeader() });
      setCustomerData(data);
      setSearchResults(data);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    let results = customerData?.filter((item) => {
      return (
        item.id.toString().includes(searchText.toString()) ||
        item.customerName
          .toLowerCase()
          .includes(searchText.toString().toLowerCase())
      );
    });
    setSearchResults(results);
  }, [searchText]);

  useEffect(() => {
    getCustomersData();
  }, []);

  return (
    <div className="row">
      <div className="col-12 px-3">
        {/*    ADD FORM START
         ********************************************* */}
        <div className="mb-3">
          <h3>ADD WorkOrder</h3>
        </div>
        <form className="" onSubmit={handleAddQuote}>
          <div>
            <div className="fw-bold fs-4 mb-1 px-1">Search Customer</div>

            <div className="d-flex align-items-center mb-2">
              <div className="flex-grow-1 ">
                <input
                  className="form-control bg-white shadow-sm"
                  placeholder="Search Customer"
                  type="search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </div>

            {selectedCustomer && (
              <div className="my-3">
                <div className="fw-bold fs-4 mb-1 px-1">Selected Cutomer</div>
                <div
                  className="bg-white border-primary border py-2 shadow-lg"
                  style={{ borderRadius: 8 }}
                >
                  <div className="row ps-3 pe-1">
                    <div className="col-2">{selectedCustomer?.id}</div>
                    <div className="col-8">
                      {selectedCustomer?.customerName}
                    </div>
                    <div className="col-2">
                      <div
                        onClick={() => setSelectedCustomer(null)}
                        className="btn btn-sm btn-danger p-0 px-1"
                      >
                        <i className="mdi mdi-delete"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {searchText && (
              <div
                className="bg-white px-2 shadow-lg scroll py-2 mt-1"
                style={{
                  maxHeight: "50%",
                  scrollBehavior: "auto",
                  borderRadius: 10,
                }}
              >
                {searchResults?.map((item, index) => (
                  <div key={index} className="px-2">
                    <div
                      className={` rounded py-1  ${
                        item.customerName.toLowerCase() ===
                        searchText.toLowerCase()
                          ? "bg-primary bg-opacity-25"
                          : ""
                      } row`}
                    >
                      {/* {item?.customerName} */}
                      <div className="col-2">{item?.id}</div>
                      <div className="col-8">{item?.customerName}</div>
                      <div className="col-2">
                        <div
                          onClick={() => handleSelectedCustomer(item)}
                          className="btn btn-sm btn-success p-0 px-1"
                        >
                          Select
                        </div>
                      </div>
                    </div>
                    {!(
                      item.id === searchResults[searchResults.length - 1].id
                    ) &&
                      !(searchResults.length === 1) && <hr className="my-0 " />}
                  </div>
                ))}
              </div>
            )}
          </div>
          {/*   Action Buttons
           ********************************************* */}
          <div className={`mt-4 mb-2 d-flex justify-content-end`}>
            <div>
              <button
                className="btn px-1 btn-secondary"
                type="button"
                onClick={() => {
                  showAddModal(false);
                }}
              >
                Cancel
              </button>
              <button
                disabled={!selectedCustomer}
                className="btn btn-primary ms-1"
                type="submit"
              >
                ADD
              </button>
            </div>
          </div>
        </form>

        {/*   Customer Select Modal
         ********************************************* */}
        <Modal
          size="xl"
          show={showCustomerModal}
          onHide={() => setShowCustomerModal(false)}
        >
          <Modal.Body>
            <div>
              <CustomerSelectModal
                showModal={setShowCustomerModal}
                handleSelectedCustomer={handleSelectedCustomer}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default QuoteAdd;
