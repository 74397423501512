import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";

import userPhoto from "../../../../assets/images/users/avatar-1.jpg";
import authHeader from "services/auth-header";
import API from "services/axios";
import Spinner from "components/Spinner";
import { useDispatch } from "react-redux";
import { setNotification } from "features/Notification/notificationSlice";
import Toast from "components/Toast/ToastComponent";

export default function UserInfo({ user, refreshFunc }) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [showModal, setShowModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});

  /*   All Functions
   ********************************************* */
  const updateUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await API.post("/users/update/" + user.id, userData, {
        headers: authHeader(),
      });
      resetState();
      await refreshFunc();
      setLoading(false);
      setShowModal(false);
    } catch (err) {
      setLoading(false);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setShowModal(false);
    }
  };

  const resetState = () => {
    setUserData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      phoneExt: "",
    });
  };

  const updateField = (e) => {
    setUserData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    setUserData({
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phone: user?.phone,
      phoneExt: user?.phoneExt,
    });
  }, [user]);
  return (
    <div
      className="d-flex flex-column shadow-sm bg-white py-4 px-2 me-3"
      style={{ borderRadius: 10, width: "30%" }}
    >
      {/* User Photo */}
      <div className="d-flex mb-2 justify-content-center align-items-center ">
        <span className="logo-lg ">
          <img
            src={userPhoto}
            style={{ borderRadius: 250 / 2 }}
            alt="logo"
            height="200"
          />
        </span>
      </div>
      {/* info */}
      <div className="pb-0 px-2 flex-grow-1 d-flex flex-column">
        {/* company Name */}
        <div className="row mb-3 text-center">
          <div className="col-12 text-center fw-bold fs-3 text-uppercase">{`${user?.firstName} ${user?.lastName}`}</div>
          <p className="fs-4">{user?.role}</p>
        </div>
        {/* Phone Number */}
        <div className="row mb-2">
          <div className="col-5">Email</div>
          <div className="col-7 ">{user?.email}</div>
        </div>
        {/* Phone Number */}
        <div className="row mb-2">
          <div className="col-5">Phone No#</div>
          <div className="col-7">{user?.phone}</div>
        </div>
        {/* Phone Extention */}
        <div className="row mb-2">
          <div className="col-5">Phone Ext</div>
          <div className="col-7">{user?.phoneExt}</div>
        </div>
        {/* End */}
        <div className="d-flex flex-grow-1">
          <div className="d-flex justify-content-end flex-grow-1 flex-column">
            <button
              onClick={() => {
                setShowModal(true);
              }}
              className="btn btn-primary "
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      <Modal
        contentClassName="bg-transparent"
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Body className="bg-white px-4 py-3 pb-1 custom-border-radius custom-shadow-sm">
          {loading && <Spinner />}
          <h2>Update User</h2>
          <form onSubmit={updateUser} className="my-3">
            <div>
              <div className="mb-3">
                <label className="form-label mb-1">First Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="firstName"
                  value={userData?.firstName}
                  onChange={updateField}
                  placeholder="First Name"
                />
              </div>
              <div className="mb-3">
                <label className="form-label mb-1">Last Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="lastName"
                  value={userData?.lastName}
                  onChange={updateField}
                  placeholder="Last Name"
                />
              </div>
              <div className="mb-3">
                <label className="form-label mb-1">Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  value={userData?.email}
                  onChange={updateField}
                  placeholder="Email"
                />
              </div>
              <div className="mb-3">
                <label className="form-label mb-1">Phone</label>
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  value={userData?.phone}
                  onChange={updateField}
                  placeholder="Phone"
                />
              </div>
              <div className="mb-3">
                <label className="form-label mb-1">Phone Ext</label>
                <input
                  className="form-control"
                  type="text"
                  name="phoneExt"
                  value={userData?.phoneExt}
                  onChange={updateField}
                  placeholder="Phone"
                />
              </div>
            </div>
            {/* ******************** Buttons ************************* */}
            <div className="text-end mb-2">
              <button
                className="btn px-2 me-1 btn-secondary"
                type="button"
                onClick={() => {
                  setShowModal(false);
                  resetState();
                }}
              >
                Close
              </button>
              <button className="btn px-2 btn-primary" type="submit">
                Update
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
