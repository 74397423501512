import React from "react";
import { Dropdown } from "react-bootstrap";

export default function MultipleRows({ rowData, updateFunc, columnLabels }) {
  return (
    <div className="row mb-2 ">
      <div className="col-md-3 px-1 mx-0 d-flex align-items-center">
        <input
          type="checkbox"
          name="hideHeader"
          className=" h-50 w-50 "
          checked={rowData.rowValues.hideHeader || false}
          onChange={(e) => {
            const isChecked = e.target.checked;
            updateFunc((prev) => ({
              ...prev,
              rowValues: { ...prev.rowValues, hideHeader: isChecked },
            }));
          }}
        />
        <label className="text-nowrap">Hide Header</label>
      </div>
      <div className="col-md-5 px-1 mx-0">
        <input
          type="text"
          name="customHeader"
          placeholder="Custom Header"
          className="form-control form-control-sm"
          value={rowData.rowValues.customHeader || ""}
          onChange={(e) => {
            const newValue = e.target.value;
            updateFunc((prev) => ({
              ...prev,
              rowValues: { ...prev.rowValues, customHeader: newValue },
            }));
          }}
        />
      </div>
      <div className="col-md-4 px-1 mx-0">
        <Dropdown>
          <Dropdown.Toggle className="btn-sm px-1 w-100">
            Select Columns
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <select
              name="columns"
              className="form-control h-100 form-control-sm"
              multiple
              value={rowData.rowValues.columns || []}
              onChange={(e) => {
                const selectedColumns = Array.from(
                  e.target.selectedOptions,
                  (option) => option.value
                );
                updateFunc((prev) => ({
                  ...prev,
                  rowValues: { ...prev.rowValues, columns: selectedColumns },
                }));
              }}
            >
              {columnLabels.map((label, index) => (
                <option key={index} value={label}>
                  {label}
                </option>
              ))}
            </select>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}
