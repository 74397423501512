import Table from "components/DataTable/Table";
import QuickViewItemAnalysis from "components/QuickViewWorkOrder/QuickViewItemAnalysis";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function ItemAnalysis({ selectedCustomer }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /*   All States
   ********************************************* */
  const [analysisData, setAnalysisData] = useState(null);
  const [loading, setLoading] = useState(false);

  /*   All Functions
   ********************************************* */

  let loadingOn = () => {
    setLoading(true);
  };

  let loadingOff = () => {
    setLoading(false);
  };

  const GetItemAnalysis = async (customerId) => {
    try {
      loadingOn();
      const { data } = await API.post(
        "/customer/work-order/products",
        { id: customerId },
        {
          headers: authHeader(),
        }
      );

      if (data?.success) {
        console.log(data);
        setAnalysisData(data?.woProductsList);
      } else {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }

      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };
  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (selectedCustomer) {
        GetItemAnalysis(selectedCustomer?.id);
      }
    }, 50);
    return () => clearTimeout(timeout);
  }, [selectedCustomer]);

  /*   Table Functions
   ********************************************* */

  const table_head = [
    "WoId",
    "Type",
    "Style",
    "Color",
    "Desc",
    "Total Qty",
    "Action",
  ];

  const table_body = (item, index) => (
    <tr className="border-b-2 hover:bg-white duration-300 " key={item.id}>
      <td className="py-1 align-middle">{item?.WOId}</td>
      <td className="py-1 align-middle">{item?.productType}</td>
      <td className="py-1 align-middle">{item?.productStyle}</td>
      <td className="py-1 align-middle">{item?.productColor}</td>
      <td className="py-1 align-middle">{item?.productDescription}</td>
      <td className="py-1 align-middle">{item?.totalQuantity}</td>
      <td className="py-1 align-middle ">
        <QuickViewItemAnalysis
          btnClasses={"btn btn-sm btn-success"}
          selectedId={item?.id}
          selectedProduct={item}
          workOrdersList={analysisData}
          selectedCustomer={selectedCustomer}
        />
        {/* <button
          className="btn btn-sm btn-primary"
          onClick={() => {
            let status =
              item?.WOStatus === "pending" || item?.WOStatus === "processing"
                ? "pending-processing"
                : item.WOStatus;
            navigate(`/work-order/details/${status}/${item?.WOId}?page=1`);
          }}
        >
          View
        </button> */}
      </td>
    </tr>
  );
  return (
    <div className="w-100 px-3 py-2">
      {loading && <Spinner />}
      {analysisData ? (
        <>
          <Table
            table_head={table_head}
            table_body={table_body}
            table_data={analysisData}
          />
        </>
      ) : (
        <>
          <div
            className="custom-card animate-pulse h-11 flex justify-between my-2"
            style={{ borderRadius: 10 }}
          >
            <div className="px-4 w-40 py-2"></div>
            <div className="px-4 w-40 py-2"></div>
            <div className="px-4 w-40 py-2"></div>
            <div className="px-4 w-40 py-2"></div>
          </div>
          <div
            className="custom-card animate-pulse h-11 flex justify-between my-2"
            style={{ borderRadius: 10 }}
          >
            <div className="px-4 w-40 py-2"></div>
            <div className="px-4 w-40 py-2"></div>
            <div className="px-4 w-40 py-2"></div>
            <div className="px-4 w-40 py-2"></div>
          </div>
          <div
            className="custom-card animate-pulse h-11 flex justify-between my-2"
            style={{ borderRadius: 10 }}
          >
            <div className="px-4 w-40 py-2"></div>
            <div className="px-4 w-40 py-2"></div>
            <div className="px-4 w-40 py-2"></div>
            <div className="px-4 w-40 py-2"></div>
          </div>
        </>
      )}
    </div>
  );
}
