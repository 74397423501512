import React, { useEffect, useState } from "react";

export default function WoSummary({ selected, selectedCustomer }) {
  /*   All States
   ********************************************* */

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQty, setTotalQty] = useState(0);
  const [priceTotal, setPriceTotal] = useState(0);
  const [salesTax, setSalesTax] = useState(0);
  const [summaryProductList, setSummaryProductList] = useState([]);

  /*   All Functions
   ********************************************* */

  const getProductDesc = (product) => {
    let sizeList = JSON.parse(product?.productSizes);
    let possibleSizes = [
      "xs",
      "axs",
      "yxs",
      "s",
      "as",
      "ys",
      "as",
      "ys",
      "m",
      "am",
      "ym",
      "l",
      "al",
      "yl",
      "xl",
      "axl",
      "yxl",
      "2xl",
      "a2xl",
      "y2xl",
    ];

    let groupedByPrice = {};
    sizeList?.map((size) => {
      let old = groupedByPrice[size.price]
        ? [...groupedByPrice[size.price]]
        : [];
      old.push(size);
      groupedByPrice[size.price] = old;
    });

    let allProd = [];
    Array.from(Object.keys(groupedByPrice))?.map((price) => {
      const sortedArr = groupedByPrice[price]?.sort((a, b) => {
        return possibleSizes.indexOf(b.size) - possibleSizes.indexOf(a.size);
      });

      let totalQty = 0;

      sortedArr.map((item) => {
        totalQty += parseInt(item?.quantity);
      });

      let updatedSize =
        sortedArr.length > 1
          ? `${sortedArr[0]?.size?.toUpperCase()}-${sortedArr[
              sortedArr.length - 1
            ]?.size?.toUpperCase()}`
          : sortedArr[0]?.size?.toUpperCase();

      let updatedDescription = `${product?.productDescription} ${updatedSize}`;

      let newItem = {
        size: updatedSize,
        price: parseFloat(sortedArr[0]?.price),
        productDescription: updatedDescription,
        productStyle: product?.productStyle,
        productColor: product?.productColor,
        productType: product?.productType,
        quantity: totalQty,
        id: product.id,
        sortId: sortedArr[0].sortId,
        totalQty: product?.totalQuantity,
        totalPrice: product?.totalPrice,
      };
      allProd.push(newItem);
    });

    const sortedProds = allProd?.sort((a, b) => {
      return a.sortId - b.sortId;
    });

    return sortedProds;
  };

  const combineProductsByStyle = (productList) => {
    const combinedProducts = productList.reduce((acc, curr) => {
      const existingProduct = acc.find(
        (item) => item.productStyle === curr.productStyle
      );

      if (existingProduct) {
        // Add quantities
        existingProduct.totalQty += curr.totalQty;
        existingProduct.totalPrice += curr.totalPrice;
      } else {
        acc.push({ ...curr });
      }

      return acc;
    }, []);

    return combinedProducts;
  };

  const getTotalQty = (arr) => {
    let total = 0;
    arr.map((item) => {
      total += parseFloat(item.totalQty);
    });
    return total;
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (!selected) return;
    let productsList = selected?.WOProducts;

    if (productsList) {
      let updatedProductList = [];
      let products = productsList;

      products?.map((item) => {
        updatedProductList = [...updatedProductList, ...getProductDesc(item)];
      });

      //   combine products with the same productStyle
      const combinedProducts = combineProductsByStyle(updatedProductList);

      let totalPrice = combinedProducts?.reduce((prev, curr) => {
        let price = curr?.totalPrice;
        let total = parseFloat(prev) + parseFloat(price);

        return total;
      }, 0);

      let taxRate = selected?.taxrate?.rate ? selected?.taxrate?.rate : 0;
      setSalesTax(((parseFloat(taxRate * totalPrice) * 100) / 100).toFixed(2));

      let total = totalPrice * taxRate + totalPrice;
      let parsedTotal = ((parseFloat(total) * 100) / 100).toFixed(2);

      setPriceTotal(parsedTotal);
      setTotalPrice(totalPrice);
      setSummaryProductList(combinedProducts);
    }
  }, [selected]);

  return (
    <div className="p-2">
      <h3>Work Order Summary</h3>
      <hr />

      <div className="row">
        <div className="col-md-12">
          {/* Table */}
          <table className="table table-responsive table-bordered">
            <thead>
              <tr>
                <th>Style</th>
                <th>Type</th>
                <th>Price</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {/* Example data rendering */}
              {summaryProductList?.map((product, index) => (
                <tr key={product?.id}>
                  <td>{product?.productStyle}</td>
                  <td>{product?.productType}</td>
                  <td>{product?.totalPrice?.toFixed(2)}</td>
                  <td>{product?.totalQty}</td>
                </tr>
              ))}
              <tr>
                <td className="fw-bold border-primary border-2" colSpan="2">
                  Total
                </td>
                <td className="fw-bold border-primary border-2">
                  {totalPrice.toFixed(2)}
                </td>
                <td className="fw-bold border-primary border-2">
                  {getTotalQty(summaryProductList)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr />

      <div className="d-flex justify-content-end">
        <div className=" w-25">
          <div className="row">
            <div className="col-md-6">
              <h5>Subtotal</h5>
            </div>
            <div className="col-md-6">
              <h5>${totalPrice.toFixed(2)}</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h5>Sales Tax</h5>
            </div>
            <div className="col-md-6">
              <h5>${salesTax}</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h5>Total</h5>
            </div>
            <div className="col-md-6">
              <h5>${priceTotal}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
