import React, { useEffect, useState } from "react";

function TableOnly({ table_data, table_head, table_body }) {
  /*  All States
   ********************************************* */
  const [currPageData, setCurrPageData] = useState(null);
  const [isSortUp, setIsSortUp] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);

  /*  All Functions
   ********************************************* */

  function handleColumnClick(col) {
    setSelectedColumn(col);
    if (!isSortUp) {
      setIsSortUp(true);
    } else {
      setIsSortUp(false);
    }
  }
  function getValue(obj, path) {
    const parts = path.split(".");
    return parts.reduce((acc, part) => acc && acc[part], obj);
  }

  function SortUpColumn(colName) {
    let sortedData = table_data?.sort((a, b) => {
      const valueA = getValue(a, colName);
      const valueB = getValue(b, colName);

      if (valueA > valueB) return 1;
      if (valueA < valueB) return -1;
      return 0;
    });
    setCurrPageData(sortedData);
  }
  function SortDownColumn(colName) {
    let sortedData = table_data?.sort((a, b) => {
      const valueA = getValue(a, colName);
      const valueB = getValue(b, colName);

      if (valueA < valueB) return 1;
      if (valueA > valueB) return -1;
      return 0;
    });
    setCurrPageData(sortedData);
    setCurrPageData(sortedData);
  }

  useEffect(() => {
    if (selectedColumn) {
      if (isSortUp && table_data) {
        SortDownColumn(selectedColumn);
      } else {
        SortUpColumn(selectedColumn);
      }
    }
  }, [selectedColumn, isSortUp, table_data]);

  useEffect(() => {
    if (table_data) {
      setCurrPageData(table_data);
    }
  }, [table_data]);
  return (
    <div className="d-flex flex-grow-1">
      <div className="flex-grow-1 d-flex flex-column ">
        {/* ************ Table Head  ************ */}
        <div className="table-responsive flex-grow-1">
          <table className="table">
            <thead>
              <tr className="">
                {table_head?.map((item) => {
                  return (
                    <th
                      onClick={() => {
                        if (item?.value === "Actions") {
                          return;
                        }
                        handleColumnClick(item?.value);
                      }}
                      key={item?.value}
                      role="button"
                      className=""
                    >
                      {item?.name}
                      {item?.value !== "Actions" &&
                        item?.value === selectedColumn &&
                        isSortUp && (
                          <i className="mdi mdi-sort-alphabetical-ascending h4 ms-2"></i>
                        )}
                      {item?.value !== "Actions" &&
                        item?.value === selectedColumn &&
                        !isSortUp && (
                          <i className="mdi mdi-sort-alphabetical-descending h4 ms-2"></i>
                        )}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {/*      Table Data
               ********************************************* */}
              {currPageData &&
                currPageData.map((item, index) =>
                  table_body(item, index, currPageData)
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TableOnly;
