import React, { useEffect, useState } from "react";

export default function QuickViewProductSizes({ selectedProduct }) {
  /*   All States
   ********************************************* */
  const [allSizes, setAllSizes] = useState(null);

  /*   All Functions
   ********************************************* */

  const sortArrBySortId = (arr) => {
    let sortedArr = arr?.sort((a, b) => a.sortId - b.sortId);
    return sortedArr;
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let timeout = setTimeout(() => {
      setAllSizes(
        selectedProduct?.productSizes
          ? typeof selectedProduct?.productSizes === "string"
            ? JSON.parse(selectedProduct?.productSizes)
            : selectedProduct?.productSizes
          : []
      );
    }, 50);
    return () => clearTimeout(timeout);
  }, [selectedProduct]);
  return (
    <div>
      <div
        className="bg-white shadow px-3 mb-2 me-2 d-flex flex-column py-2"
        style={{ borderRadius: 15, height: 250 }}
      >
        <div className="" style={{ overflowY: "scroll", height: "99%" }}>
          <div className="table-responsive  ">
            <table className="table ">
              <thead>
                <tr>
                  <th scope="col">Size</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                {sortArrBySortId(allSizes)?.map((item, i) => (
                  <tr key={i}>
                    <td className="py-0">{item?.size?.toUpperCase()}</td>
                    <td className="py-0">{item?.quantity}</td>
                    <td className="py-0">
                      $ {parseFloat(item?.price).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div
          className="d-flex justify-content-evenly flex-grow-1 py-1 align-items-center bg-white shadow me-2"
          style={{ borderRadius: 15 }}
        >
          <div>
            Total Qty
            <span className="border ms-1 px-2">
              {parseFloat(selectedProduct?.totalQuantity)}
            </span>
          </div>
          <div>
            Total Price
            <span className="border ms-1 px-2">
              $ {parseFloat(selectedProduct?.totalPrice).toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
