import React, { useEffect, useState } from "react";

function Table({
  table_data,
  table_head,
  table_body,
  buttons,
  disableSearch,
  searchMaxWidth,
  defaultLimit,
  btnLeft,
  btn1,
  filterBy,
  searchResults,
  searchedText,
  setSearchedText,
  hidePagination,
}) {
  /*  All States
   ********************************************* */
  const [currPage, setCurrPage] = useState(0);
  const [currPageData, setCurrPageData] = useState(null);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(null);
  const [searchText, setSearchText] = useState(
    searchedText ? searchedText : ""
  );
  const [lastPage, setLastPage] = useState(null);

  /*  All Functions
   ********************************************* */
  const DisplayTable = (data, page) => {
    // Getting total number of entries
    if (hidePagination) {
      setCurrPageData(data);
      setCurrPage(page);
      return;
    }
    if (data) {
      /* Data Slicing for the current Page Data */

      // Multiplying current page with limit will give us Starting value for
      // current Page
      const sliceFrom = page * limit;

      // Addition of limit to sliceFrom will give next limit number of entries
      //  through which we can get slicedData for the current Page
      const sliceTo = sliceFrom + limit;
      const sliced_data = data.slice(sliceFrom, sliceTo);

      setCurrPageData(sliced_data);
      setCurrPage(page);
    }
  };

  const getTotalPageNumber = (tableData, limit) => {
    const total_data_length = tableData?.length;

    // Here I am getting total pages according to Data received
    // Dividing total number of entries/length by PageLimit will give
    // total possible pages for data
    const possibleNumberOfPages = total_data_length / limit;

    // getting modulus of data with limit to check
    // any decimal values. If there are any decimal values
    // i am rounding up them and adding 1 to total number of pages
    let pageList = 0;

    if (total_data_length % limit === 0) {
      pageList = possibleNumberOfPages;
    } else {
      pageList = ~~Math.abs(possibleNumberOfPages) + 1;
    }
    return pageList;
  };

  const updateSearchText = (text) => {
    if (searchResults) {
      searchResults((prev) => null);
    }
    if (setSearchedText) {
      setSearchedText(text);
    }
    setSearchText((prev) => text);
    if (text === "" && searchResults) {
      searchResults(null);
    }
  };

  useEffect(() => {
    if (table_data && !hidePagination) {
      const pageList = getTotalPageNumber(table_data, limit);

      let totalPageList = Array.from(Array(pageList).keys());
      setLastPage(totalPageList.length - 1);

      if (!hidePagination) {
        if (currPage > totalPageList.length) {
          setTotalPage([
            ...totalPageList.slice(
              totalPageList.length - 10,
              totalPageList.length
            ),
          ]);
        } else if (currPage > 8) {
          setTotalPage([
            ...totalPageList.slice(currPage - 5, currPage),
            ...totalPageList.slice(currPage, currPage + 5),
          ]);
        } else {
          setTotalPage([...totalPageList.slice(0, 10)]);
        }
      }
    }
  }, [currPage, table_data, limit]);

  useEffect(() => {
    if (searchText && searchResults) {
      const data = filterBy
        ? table_data?.filter((item) =>
            item[filterBy] && item[filterBy] != null
              ? item[filterBy]
                  .toString()
                  .toLowerCase()
                  .includes(item[filterBy] ? searchText.toLowerCase() : null)
              : null
          )
        : table_data?.filter(
            (o) =>
              Object.keys(o) &&
              Object.keys(o).some((k) => {
                if (typeof o[k] == "string" && o[k]) {
                  return (
                    o[k] &&
                    o[k]
                      .toString()
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  );
                } else if (o[k] != null && o[k]) {
                  return (
                    o[k] &&
                    o[k]
                      .toString()
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  );
                }
              })
          );

      searchResults(data);
    }
  }, [searchText, searchResults]);

  useEffect(() => {
    // get total number of pages using limit and
    // check if currPage number is in total pages
    const pageList = getTotalPageNumber(table_data, limit);

    let newCurrPage = currPage;
    if (currPage + 1 <= pageList || currPage === 0) {
      newCurrPage = currPage;
    } else {
      newCurrPage = pageList - 1;
    }

    if (table_data) {
      DisplayTable(table_data, newCurrPage);
    }
  }, [currPage, limit, table_data]);

  useEffect(() => {
    if (defaultLimit) {
      setLimit(defaultLimit);
    }
  }, [defaultLimit]);
  return (
    <div className="d-flex flex-grow-1">
      <div className="flex-grow-1 d-flex  flex-column ">
        <div className=" d-flex justify-content-between align-items-baseline">
          <div className="d-flex justify-content-between align-items-center flex-grow-1">
            {btnLeft ? <div> {btnLeft()}</div> : null}
            {/* ************ Page Limit ************ */}
            {!searchText && table_data && (
              <div>
                Show &nbsp;
                <div className="btn-group">
                  <button
                    className="px-1 bg-primary bg-opacity-10 border border-primary rounded-end rounded-start "
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {limit}
                  </button>
                  <ul className="dropdown-menu w-25">
                    <li
                      onClick={() => {
                        setLimit(5);
                      }}
                    >
                      <button className="dropdown-item" href="#">
                        5
                      </button>
                    </li>
                    <li
                      onClick={() => {
                        setLimit(10);
                      }}
                    >
                      <button className="dropdown-item" href="#">
                        10
                      </button>
                    </li>
                    <li
                      onClick={() => {
                        setLimit(15);
                      }}
                    >
                      <button className="dropdown-item" href="#">
                        15
                      </button>
                    </li>
                    <li
                      onClick={() => {
                        setLimit(20);
                      }}
                    >
                      <button className="dropdown-item" href="#">
                        20
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          {/* ************ ADD Button ************ */}
          <div className="d-flex justify-content-end flex-grow-1">
            <div className="flex-grow-1">{buttons ? buttons() : null}</div>
            {/* ************ Search Box ************ */}
            {!disableSearch && (
              <div
                className="input-group mb-1"
                style={{
                  minWidth: "10%",
                  maxWidth: searchMaxWidth ? searchMaxWidth : "35%",
                }}
              >
                <input
                  onChange={(e) => {
                    updateSearchText(e.target.value.toLocaleLowerCase());
                  }}
                  className="form-control"
                  placeholder="Search here......"
                  value={searchText}
                  type="search"
                />
              </div>
            )}
            {/* ************ Btn1 ************ */}
            <div>{btn1 ? btn1() : null}</div>
          </div>
        </div>

        {/* ************ Table Head  ************ */}
        <div className="table-responsive flex-grow-1">
          <table className="table">
            <thead>
              <tr className="">
                {table_head?.map((item) => {
                  return (
                    <th key={item} scope="col">
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {/* /*    Table Data
               ********************************************* */}
              {currPageData && table_data && !searchText
                ? currPageData.map((item, index) =>
                    table_body(item, index, currPageData)
                  )
                : filterBy
                ? currPageData &&
                  table_data
                    .filter((item) =>
                      item[filterBy] && item[filterBy] != null
                        ? item[filterBy]
                            .toString()
                            .toLowerCase()
                            .includes(
                              item[filterBy] ? searchText.toLowerCase() : null
                            )
                        : null
                    )
                    .map((item, index) => table_body(item, index, table_data))
                : currPageData &&
                  table_data
                    .filter(
                      (o) =>
                        Object.keys(o) &&
                        Object.keys(o).some((k) => {
                          if (typeof o[k] == "string" && o[k]) {
                            return (
                              o[k] &&
                              o[k]
                                .toString()
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                            );
                          } else if (o[k] != null && o[k]) {
                            return (
                              o[k] &&
                              o[k]
                                .toString()
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                            );
                          }
                        })
                    )
                    .map((item, index) => table_body(item, index, table_data))}
            </tbody>
          </table>
        </div>

        {/*             Pagination
         ********************************************* */}
        <div className="d-flex justify-content-end align-items-center   ">
          {/*   NEw Pagination
           ********************************************* */}

          {!hidePagination && (
            <nav>
              <ul className="pagination flex-wrap justify-content-end">
                {!searchText && totalPage?.length != 0 && (
                  <li className="page-item">
                    <button
                      onClick={() => setCurrPage(0)}
                      className="page-link"
                      aria-label="Previous"
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>
                )}

                {!searchText &&
                  totalPage?.map((el) => (
                    <li key={el} className={`page-item `}>
                      <button
                        onClick={() => {
                          setCurrPage(el);
                        }}
                        className={`page-link  ${
                          currPage == el
                            ? "bg-primary text-white"
                            : "text-black bg-white"
                        } `}
                      >
                        {el + 1}
                      </button>
                    </li>
                  ))}

                {!searchText && totalPage?.length != 0 && (
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="Next"
                      onClick={() => setCurrPage(lastPage)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                )}
              </ul>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}

export default Table;
