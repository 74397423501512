import { BlobProvider } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import authHeader from "services/auth-header";
import API from "services/axios";
import { WOPdf } from "./WOPdf";
import Spinner from "components/Spinner";

export default function WOPdfButton({
  btnClasses,
  btnColor,
  selectedWO,
  filterBy,
}) {
  /*   All States
   ********************************************* */
  const [showPdf, setShowPdf] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [locationLogo, setLocationLogo] = useState(null);
  const [pdfOptions, setPdfOptions] = useState({ Invoice: false });

  const [productsListWithImages, setProductsListWithImages] = useState(null);
  const [pdfProducts, setPdfProducts] = useState(null);

  /*   All Functions
   ********************************************* */
  const getLocationLogo = async () => {
    const { data } = await API.post(
      "/design-request/get-image",
      { path: selectedWO?.location?.pdfLogo },
      {
        headers: authHeader(),
      }
    );
    setLocationLogo(data);
    return data;
  };

  const getWorkOrdersProducts = async () => {
    try {
      const { data } = await API.get(`/wo-products/${selectedWO?.id}`, {
        headers: authHeader(),
      });

      let updatedProductList = [];
      if (filterBy && filterBy !== "Pending") {
        if (filterBy === "READY TO BILL") {
          updatedProductList = data.filter((item) => item.isReady === true);
        } else {
          updatedProductList = data.filter(
            (item) => item.productType === filterBy
          );
        }
      } else {
        updatedProductList = data;
      }
      setPdfProducts(updatedProductList);
      setProductsListWithImages(updatedProductList);
    } catch (err) {
      alert(err.message);
    }
  };

  const loadContent = async () => {
    await getLocationLogo();
    setShowPdf(true);
  };

  const getAvailableProductTypes = (list) => {
    if (!list) {
      return { "No Products": false };
    }
    const productTypes = list.map((item) => item.productType);
    let options = productTypes?.reduce((acc, cur) => {
      acc[cur] = true;
      return acc;
    }, {});

    return options;
  };

  // Function to handle checkbox of productTypeList
  // And filter items according to checkbox

  const handleOptionSelect = (e, option) => {
    setShowPdf(false);
    let name = option;
    let value = e.target.checked;

    if (value) {
      let pdfProduct = productsListWithImages.filter(
        (item) => item.productType === e.target.id
      );
      let newProducts = [...pdfProducts, ...pdfProduct];
      setPdfProducts(newProducts);
    } else {
      let pdfProduct = pdfProducts?.filter(
        (item) => item.productType !== e.target.id
      );
      setPdfProducts(pdfProduct);
    }

    setPdfOptions((prev) => {
      return { ...prev, [name]: value };
    });
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getWorkOrdersProducts();
  }, [selectedWO]);

  useEffect(() => {
    if (productsListWithImages) {
      let optionsList = getAvailableProductTypes(productsListWithImages);

      setPdfOptions((prev) => {
        return { Invoice: false, ...optionsList };
      });
    }
  }, [productsListWithImages]);

  if (!pdfOptions) return <Spinner />;

  return (
    <>
      <button
        className={`${btnClasses} ${btnColor}`}
        onClick={() => {
          setShowPdfModal(true);
        }}
      >
        Get Pdf
      </button>

      {/*   Delete Modal
       ********************************************* */}
      <Modal
        show={showPdfModal}
        onHide={() => setShowPdfModal(false)}
        contentClassName="bg-transparent"
      >
        <Modal.Body className="custom-border-radius-sm bg-white custom-shadow-sm">
          <h3>Select Pdf Options</h3>
          <div className="my-3">
            {Object.keys(pdfOptions)?.map((option) => (
              <div key={option} className="d-flex gap-2 mb-1">
                <input
                  onChange={(e) => {
                    handleOptionSelect(e, option);
                  }}
                  type="checkbox"
                  checked={pdfOptions[option]}
                  id={option}
                  data-switch="success"
                />
                <label
                  htmlFor={option}
                  data-on-label="Yes"
                  data-off-label="No"
                ></label>
                <div className="ms-2 fw-bold">
                  {option?.replaceAll("_", " ")?.toUpperCase()}
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end gap-1 mt-3">
            <button
              onClick={() => {
                setShowPdfModal(false);
              }}
              className="btn btn-secondary"
            >
              Close
            </button>
            {showPdf && locationLogo && pdfProducts ? (
              <>
                <BlobProvider
                  document={
                    <WOPdf
                      selectedWO={selectedWO}
                      productsList={pdfProducts}
                      locationLogo={locationLogo}
                      showInvoicePage={pdfOptions.Invoice}
                    />
                  }
                >
                  {({ url, blob }) => {
                    return (
                      <>
                        <button
                          disabled={!selectedWO}
                          className={"btn btn-success"}
                        >
                          <a
                            className="text-decoration-none text-white"
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="mdi mdi-download"></i>Download Pdf
                            {!url && (
                              <div
                                className="spinner-border spinner-border-sm text-white ms-2 mb-1"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </a>
                        </button>
                      </>
                    );
                  }}
                </BlobProvider>
              </>
            ) : (
              <>
                <div>
                  {selectedWO?.quoteProducts?.length === 0 ? (
                    <button className={`${btnClasses} btn-danger`}>
                      No Products
                    </button>
                  ) : !selectedWO?.location?.pdfLogo ? (
                    <button className={`${btnClasses} btn-danger`}>
                      Please Select Work Order location First
                    </button>
                  ) : (
                    <button className={`btn-success btn`} onClick={loadContent}>
                      Generate PDF
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
