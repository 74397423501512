import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

import { useSearchParams } from "react-router-dom";
import QuoteProductAdd from "./QuoteProductAdd";
import QuoteProductUpdate from "./QuoteProductUpdate";
import { setNotification } from "features/Notification/notificationSlice";

function QuoteProducts({ refreshFunc, selectedQuote }) {
  const user = useSelector((state) => state.user?.userInfo);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  /*   ALL STATES
   ********************************************* */
  const [quoteProductsData, setQuoteProductsData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const [selected, setSelected] = useState(null);
  const [lastSortId, setLastSortId] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [loading, setLoading] = useState(false);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };
  const setUpdateId = (id) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("productId", id);
      return newParams.toString();
    });
  };

  const removeUpdateId = () => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.delete("productId");
      return newParams.toString();
    });
  };

  // Delete WorkOrders
  const deleteQuoteProduct = async (id) => {
    try {
      loadingOn();
      const { data } = await API.delete(`quote-product/${selected.id}`, {
        headers: authHeader(),
      });
      if (data?.type === "success") {
        await refreshFunc();
        setShowDeleteModal(false);
        setSelected(null);
        removeUpdateId();
        loadingOff();
        return;
      }
      console.log(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      alert(err.message);
    }
  };

  // Sorting Up
  const sortUp = async (index) => {
    const currentSortId = quoteProductsData[index]?.sortId;
    const currentId = quoteProductsData[index]?.id;
    const upSortId = quoteProductsData[index - 1].sortId;
    const upId = quoteProductsData[index - 1].id;
    try {
      const { data } = await API.post(
        "quote-product/sortup",
        { currentSortId, currentId, upSortId, upId },
        { headers: authHeader() }
      );
      if (data.success) {
        refreshFunc();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Sorting Up
  const sortDown = async (index) => {
    const currentSortId = quoteProductsData[index]?.sortId;
    const currentId = quoteProductsData[index]?.id;
    const downSortId = quoteProductsData[index + 1].sortId;
    const downId = quoteProductsData[index + 1].id;
    try {
      const { data } = await API.post(
        "quote-product/sortDown",
        { currentSortId, currentId, downSortId, downId },
        { headers: authHeader() }
      );
      if (data.success) {
        refreshFunc();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const HandleDuplicateProduct = async (item) => {
    try {
      loadingOn();
      delete item.id;
      delete item.sortId;
      delete item.createdAt;
      delete item.updatedAt;
      const { data } = await API.post(
        "/quote-product",
        {
          ...item,
          sortId: lastSortId + 1,
        },
        {
          headers: authHeader(),
        }
      );
      if (data?.type === "success") {
        await refreshFunc();
        loadingOff();
        return;
      }
      if (data?.type === "error") {
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
      }
      console.log(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   ALL UseEffects
   ********************************************* */

  useEffect(() => {
    if (!showUpdateModal) {
      removeUpdateId();
    }
  }, [showUpdateModal]);

  useEffect(() => {
    if (selectedQuote) {
      let productsList = selectedQuote?.quoteProducts;
      let sortedProductList = productsList?.sort((a, b) => a.sortId - b.sortId);
      setLastSortId(
        sortedProductList[sortedProductList?.length - 1]?.sortId
          ? sortedProductList[sortedProductList?.length - 1]?.sortId
          : 0
      );
      setQuoteProductsData(sortedProductList);
    }
    setSelected(null);
  }, [selectedQuote, showUpdateModal, showAddModal]);

  /*   Table functions
   ********************************************* */
  const btn1 = () => (
    <div className="d-flex">
      {(user?.permissions?.includes("quote_add") ||
        user?.permissions?.includes("quote_dataEntry") ||
        user?.roles === "admin") && (
        <>
          <button
            onClick={() => setShowAddModal(true)}
            type="button"
            className="btn btn-primary ms-1"
          >
            ADD Product
          </button>
        </>
      )}
    </div>
  );

  const table_head = [
    "Item No",
    "Style No",
    "Description",
    "Color",
    "fabric",
    "Quantity",
    "Price",
    "Total",
    "Actions",
  ];

  const table_body = (item, index) => (
    <tr
      onClick={() => setSelected(item)}
      className={selected?.id === item.id ? "bg-primary bg-opacity-25" : ""}
      key={item.id}
      onDoubleClick={() => {
        setShowUpdateModal(true);
        setSelected(item);
        setUpdateId(item?.id);
      }}
    >
      <td className="py-0 align-middle">{item.sortId}</td>
      <td className="py-0 align-middle">{item.styleNo} </td>
      <td className="py-0 align-middle">{item.description} </td>
      <td className="py-0 align-middle">{item.color} </td>
      <td className="py-0 align-middle">{item.fabric} </td>
      <td className="py-0 align-middle">{item.quantity} </td>
      <td className="py-0 align-middle">{item.price} </td>
      <td className="py-0 align-middle">
        ${" "}
        {parseFloat(
          (parseFloat(item?.quantity) || 0) * (item?.price || 0)
        )?.toFixed(2)}
      </td>
      <td className="table-action py-0">
        <div
          onClick={() => {
            item.sortId !== 1 && sortUp(index);
          }}
          className="action-icon"
          style={{ cursor: "pointer" }}
        >
          <i className="mdi mdi-chevron-double-up"></i>
        </div>
        <div
          onClick={() => {
            item.sortId !== quoteProductsData?.length && sortDown(index);
          }}
          className="action-icon"
          style={{ cursor: "pointer" }}
        >
          <i className="mdi mdi-chevron-double-down"></i>
        </div>
        {(user?.permissions?.includes("quote_update") ||
          user?.permissions?.includes("quote_dataEntry") ||
          user?.roles === "admin") && (
          <div className="action-icon">
            <i
              onClick={() => {
                setShowUpdateModal(true);
                setUpdateId(item?.id);
              }}
              className="mdi mdi-pencil"
            ></i>
          </div>
        )}
        {(user?.permissions?.includes("quote_update") ||
          user?.permissions?.includes("quote_dataEntry") ||
          user?.roles === "admin") && (
          <div className="action-icon">
            <i
              onClick={() => {
                HandleDuplicateProduct(item);
              }}
              className="mdi mdi-content-duplicate"
            ></i>
          </div>
        )}
        {(user?.permissions?.includes("quote_delete") ||
          user?.permissions?.includes("quote_dataEntry") ||
          user?.roles === "admin") && (
          <div
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteID(item.id);
            }}
            className="action-icon"
          >
            <i className="mdi mdi-delete"></i>
          </div>
        )}
      </td>
    </tr>
  );

  if (!quoteProductsData) return <Spinner />;
  return (
    <div
      className="d-flex flex-grow-1 flex-column px-3"
      style={{ height: "auto" }}
    >
      <h3>Quote Products</h3>
      {loading && <Spinner />}
      {/*   Table
       ********************************************* */}
      <div
        className="d-flex flex-grow-1 "
        style={{ minHeight: "50vh", borderRadius: 15 }}
      >
        <div className="horizontal-scrollable  d-flex flex-grow-1 ">
          {quoteProductsData && (
            <Table
              btn1={btn1}
              table_head={table_head}
              table_body={table_body}
              table_data={quoteProductsData}
            />
          )}
        </div>
      </div>

      {/*  Product Add
       ********************************************* */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Body>
          {quoteProductsData && (
            <QuoteProductAdd
              lastSortId={lastSortId}
              refreshFunc={refreshFunc}
              selectedQuote={selectedQuote}
              showModal={setShowAddModal}
            />
          )}
        </Modal.Body>
      </Modal>
      {/*  Product Update
       ********************************************* */}
      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Body className="bg-light">
          {quoteProductsData && (
            <QuoteProductUpdate
              selected={selected}
              refreshFunc={refreshFunc}
              showModal={setShowUpdateModal}
              allProductsData={quoteProductsData}
            />
          )}
        </Modal.Body>
      </Modal>

      {/*   Delete Modal
       ********************************************* */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Body className="bg-danger">
          <div className="">
            <div className="text-center text-white">
              <i className="ri-close-circle-line h1"></i>
              <h4 className="mt-2">Confirm Delete!</h4>
              <p className="mt-3">Do You want to delete ?</p>
              <button
                type="button"
                onClick={() => setShowDeleteModal(false)}
                className="btn btn-light my-2 mx-2"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-outline-light my-2 "
                data-bs-dismiss="modal"
                onClick={() => {
                  deleteQuoteProduct(deleteID);
                }}
              >
                DELETE
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default QuoteProducts;
