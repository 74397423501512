import Table from "components/DataTable/Table";
import { useNavigate } from "react-router-dom";
import { convertSqlTimestampToDate } from "utils/UtilityFuncs";

export default function C_Quotes({ id, customerQuotes, getCustomerQuotes }) {
  const navigate = useNavigate();
  /*   ALL STATES
   ********************************************* */

  /*   All Functions
   ********************************************* */

  const handleDetailsClick = (item) => {
    let status =
      item?.WOStatus === "pending" || item?.WOStatus === "processing"
        ? "pending-processing"
        : item.WOStatus;
    return navigate(
      `/quotes/details/${status}/${item?.id}?page=1&tab=quote-details`
    );
  };

  /*   Table functions
   ********************************************* */
  const table_head = [
    "Quote ID",
    "Job Name",
    "Location",
    "Created Date",
    "Status",
    "Actions",
  ];

  const table_body = (item, index) => (
    <tr className="" key={item.id}>
      <td className="py-1 align-middle me-0">{item?.id}</td>
      <td className="py-1 align-middle me-0">{item?.jobName?.slice(0, 30)}</td>
      <td className="py-1 align-middle me-0">{item?.location?.name}</td>
      <td className="py-1 align-middle me-0">
        {convertSqlTimestampToDate(item.createdAt)}{" "}
      </td>

      <td className="py-1 align-middle me-0">{item?.status}</td>
      <td className="table-action d-flex py-1 align-middle me-0">
        <button
          onClick={(e) => {
            handleDetailsClick(item);
          }}
          className={`btn btn-sm bg-success text-white mx-1 `}
        >
          Details
        </button>
      </td>
    </tr>
  );
  return (
    <div
      className="py-4 px-3 horizontal-scrollable d-flex flex-grow-1"
      style={{ borderRadius: 10 }}
    >
      {/*   Table
       ********************************************* */}
      <div
        className="px-2 flex-grow-1 d-flex"
        style={{ minHeight: "50vh", borderRadius: 15 }}
      >
        <div className="horizontal-scrollable d-flex flex-grow-1">
          <Table
            table_head={table_head}
            table_body={table_body}
            table_data={customerQuotes}
          />
        </div>
      </div>
    </div>
  );
}
