import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "services/axios";

export default function AddNewVersion({
  setShowAddModal,
  selectedProduct,
  selectedDesign,
  setDesignRequests,
  setSelectedDesign,
  setSelectedProduct,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.userInfo);
  /*   All States
   ********************************************* */
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(null);

  /*   All Functions
   ********************************************* */
  const addNewProductVersion = async (e) => {
    e.preventDefault();

    if (!formData.file) {
      return dispatch(
        setNotification({
          message: "Please select file",
          type: "error",
        })
      );
    }

    setIsLoading(true);

    try {
      const submitData = new FormData();
      submitData.append("file", formData?.file);
      submitData.append("id", selectedDesign.id);

      /* ******************** Updating currentData  ************************* */

      // initialize updatedDesignProducts to update all products.
      let updatedDesignProducts = [...selectedDesign?.products];

      let currentProduct = selectedDesign?.products?.find(
        (product) => product.id === selectedProduct.id
      );

      let folder = `kms/design-requests/${user?.companyId}/${
        selectedDesign?.customerId
      }/${selectedDesign?.title.replaceAll(
        " ",
        "_"
      )}/${currentProduct?.title.replaceAll(" ", "_")}`;
      let imgUrl = `${folder}/${formData?.file?.name}`;

      // Create Append imgPath of new image to currentProduct Images.
      let newImgData = [
        {
          img: imgUrl,
          version: currentProduct.images?.length + 1,
        },
        ...currentProduct.images,
      ];

      // Update Selected Product to included new image.
      let updatedCurrentProduct = {
        ...currentProduct,
        images: newImgData,
      };

      // Update Project to included updated product.
      updatedDesignProducts = updatedDesignProducts.map((product) => {
        if (product.id === updatedCurrentProduct?.id) {
          return updatedCurrentProduct;
        }
        return product;
      });

      let updatedSelectedDesign = {
        ...selectedDesign,
        products: updatedDesignProducts,
      };

      submitData.append("contactName", selectedDesign?.contactName);
      submitData.append("folder", folder);
      submitData.append("products", JSON.stringify(updatedDesignProducts));

      /* ******************** Api Call  ************************* */
      const { data } = await API.post(
        "/design-request/new-version",
        submitData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      let updatedSelectedProduct = updatedSelectedDesign?.products?.find(
        (product) => product.id === selectedProduct?.id
      );
      setSelectedProduct((prev) => updatedSelectedProduct);
      setSelectedDesign((prev) => updatedSelectedDesign);
      setDesignRequests((prev) => {
        return prev.map((item) => {
          if (item?.id === updatedSelectedDesign?.id) {
            return updatedSelectedDesign;
          } else {
            return item;
          }
        });
      });
      setIsLoading(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );

      setIsLoading(false);
    }

    setShowAddModal(false);
  };

  /*   All UseEffects
   ********************************************* */

  return (
    <div>
      {isLoading && <Spinner />}
      <div className="">
        <h3>Add New Version ( width 950px ) </h3>
      </div>
      <hr className="mt-0" />
      <div className="row">
        <div className="col-12">
          <form>
            <div className=" mb-3">
              <h4 className="form-label">{selectedProduct?.title}</h4>
              <div className="">
                <input
                  type="file"
                  name={selectedProduct?.title}
                  id={selectedProduct?.id}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return { ...prev, file: e.target.files[0] };
                    });
                  }}
                  className="form-control"
                  placeholder="Enter Title"
                />
              </div>
            </div>
            {/*   buttons
             ********************************************* */}
            <div className="my-2 mt-4">
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => {
                    setShowAddModal(false);
                  }}
                  className="btn btn-secondary btn-sm"
                  type="button"
                >
                  Close
                </button>
                <button
                  onClick={(e) => {
                    addNewProductVersion(e, false);
                  }}
                  className="btn btn-primary btn-sm ms-1"
                  type="submit"
                >
                  Add Version
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
