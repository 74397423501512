import React from "react";

export default function SingleRow({ rowData, updateFunc, columnLabels }) {
  return (
    <div className="row mb-2">
      <div className="col-md-3 px-1 mx-0 d-flex align-items-center">
        <input
          type="checkbox"
          name="hideHeader"
          className=" h-50 w-50 "
          checked={rowData.rowValues.hideHeader || false}
          onChange={(e) => {
            const isChecked = e.target.checked;
            updateFunc((prev) => ({
              ...prev,
              rowValues: { ...prev.rowValues, hideHeader: isChecked },
            }));
          }}
        />
        <label className="text-nowrap">Hide Header</label>
      </div>
      <div className="col-md-4 px-1 mx-0">
        <input
          type="text"
          name="customHeader"
          placeholder="Custom Header"
          className="form-control form-control-sm"
          value={rowData.rowValues.customHeader || ""}
          onChange={(e) => {
            const newValue = e.target.value;
            updateFunc((prev) => ({
              ...prev,
              rowValues: { ...prev.rowValues, customHeader: newValue },
            }));
          }}
        />
      </div>
      <div className="col-md-5 px-1 mx-0">
        <select
          name="columns"
          className="form-control form-control-sm"
          value={rowData.rowValues.column || ""}
          onChange={(e) => {
            const selectedColumn = e.target.value;
            updateFunc((prev) => ({
              ...prev,
              rowValues: { ...prev.rowValues, column: selectedColumn },
            }));
          }}
        >
          <option value="">Select Column</option>
          {columnLabels?.map((label, index) => (
            <option key={index} value={label}>
              {label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
