import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";

function WorkOrdersAdd({ showAddModal }) {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  /*   All States Below
   ********************************************* */

  const [customerData, setCustomerData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState(null);

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [billingAddress, setBillingAddress] = useState({});
  const [shippingAddress, setShippingAddress] = useState(null);
  const [WOData, setWOData] = useState({});

  /*   All Functions Below
   ********************************************* */

  const addWorkOrderData = async (formData, e) => {
    e.preventDefault();

    try {
      const { data } = await API.post(
        "/work-orders-add",
        {
          data: {
            ...formData,
            customerName: selectedCustomer?.customerName,
            customerId: selectedCustomer?.id,
            contactData: JSON.stringify({}),
            billingAddress,
            shippingAddress,
            ...WOData,
            WOStatus: "pending",
          },
        },
        { headers: authHeader() }
      );
      if (data?.message?.type === "success") {
        let selectedWorkOrder = data?.data;
        navigate(
          `/work-order/details/pending-processing/${selectedWorkOrder?.woId}?page=1`
        );
        showAddModal(false);
        return;
      } else {
        console.log(data);
        alert("something went wrong");
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const handleSelectedCustomer = async (customerData) => {
    setSelectedCustomer(customerData);
    setBillingAddress({
      ...billingAddress,
      description: "",
      addressCompany: customerData?.addressCompany,
      addressLine1: customerData?.addressLine1,
      addressLine2: customerData?.addressLine2,
      city: customerData?.addressCity,
      state: customerData?.addressState,
      zip: customerData?.addressZipCode,
      country: customerData?.addressCountry,
    });

    let shipAddress = customerData?.shippingAddress
      ? customerData?.shippingAddress
      : {};
    let _shippingAddress =
      typeof shipAddress === "string" ? JSON.parse(shipAddress) : shipAddress;

    setShippingAddress({
      description: _shippingAddress.description,
      addressCompany: _shippingAddress.addressCompany,
      addressLine1: _shippingAddress?.addressLine1,
      addressLine2: _shippingAddress?.addressLine2,
      city: _shippingAddress?.addressCity,
      state: _shippingAddress?.addressState,
      zip: _shippingAddress?.addressZipCode,
      country: _shippingAddress?.addressCountry,
      email: _shippingAddress?.email,
      phone: _shippingAddress?.phone,
      signatureRelease: _shippingAddress?.signatureRelease,
      authorizePerson: _shippingAddress?.authorizePerson,
      selfCollect: _shippingAddress?.selfCollect,
      authorizeLocation: _shippingAddress?.authorizeLocation,
      serviceId: _shippingAddress?.serviceId,
      dropShipping: _shippingAddress?.dropShipping,
    });

    var currentDate = new Date();
    var formattedDateTime = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    setWOData({
      locationId: customerData?.locationId,
      taxExamption: customerData?.isTaxExamption,
      taxRateId: customerData?.taxAccountId,
      paymentTermsId: customerData?.paymentTermsId,
      salesPersonId: customerData?.salesPersonId,
      WODate: formattedDateTime,
      WOShipDate: formattedDateTime,
    });
  };

  const getCustomersData = async () => {
    try {
      const { data } = await API.get("/customers", { headers: authHeader() });
      setCustomerData(data);
      setSearchResults(data);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    let results = customerData?.filter((item) => {
      return (
        item.id.toString().includes(searchText.toString()) ||
        item.customerName
          .toLowerCase()
          .includes(searchText.toString().toLowerCase())
      );
    });
    setSearchResults(results);
  }, [searchText]);

  useEffect(() => {
    getCustomersData();
  }, []);

  return (
    <div className="row">
      <div className="col-12 px-3">
        {/*    ADD FORM START
         ********************************************* */}
        <div className="mb-3">
          <h3>ADD WorkOrder</h3>
        </div>
        <form className="" onSubmit={handleSubmit(addWorkOrderData)}>
          <div>
            <div className="fw-bold fs-4 mb-1 px-1">Search Customer</div>

            <div className="d-flex align-items-center mb-2">
              <div className="flex-grow-1 ">
                <input
                  className="form-control bg-white shadow-sm"
                  placeholder="Search Customer"
                  type="search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </div>

            {selectedCustomer && (
              <div className="my-3">
                <div className="fw-bold fs-4 mb-1 px-1">Selected Cutomer</div>
                <div
                  className="bg-white border-primary border py-2 shadow-lg"
                  style={{ borderRadius: 8 }}
                >
                  <div className="row ps-3 pe-1">
                    <div className="col-2">{selectedCustomer?.id}</div>
                    <div className="col-8">
                      {selectedCustomer?.customerName}
                    </div>
                    <div className="col-2">
                      <div
                        onClick={() => setSelectedCustomer(null)}
                        className="btn btn-sm btn-danger p-0 px-1"
                      >
                        <i className="mdi mdi-delete"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {searchText && (
              <div
                className="bg-white px-2 shadow-lg scroll py-2 mt-1"
                style={{
                  maxHeight: "50%",
                  scrollBehavior: "auto",
                  borderRadius: 10,
                }}
              >
                {searchResults?.map((item, index) => (
                  <div key={index} className="px-2">
                    <div
                      className={` rounded py-1  ${
                        item.customerName.toLowerCase() ===
                        searchText.toLowerCase()
                          ? "bg-primary bg-opacity-25"
                          : ""
                      } row`}
                    >
                      {/* {item?.customerName} */}
                      <div className="col-2">{item?.id}</div>
                      <div className="col-8">{item?.customerName}</div>
                      <div className="col-2">
                        <div
                          onClick={() => handleSelectedCustomer(item)}
                          className="btn btn-sm btn-success p-0 px-1"
                        >
                          Select
                        </div>
                      </div>
                    </div>
                    {!(
                      item.id === searchResults[searchResults.length - 1].id
                    ) &&
                      !(searchResults.length === 1) && <hr className="my-0 " />}
                  </div>
                ))}
              </div>
            )}
          </div>
          {/*   Action Buttons
           ********************************************* */}
          <div className={`mt-4 mb-2 d-flex justify-content-end`}>
            <div>
              <button
                className="btn px-1 btn-secondary"
                type="button"
                onClick={() => {
                  showAddModal(false);
                }}
              >
                Cancel
              </button>
              <button
                disabled={!selectedCustomer}
                className="btn btn-primary ms-1"
                type="submit"
              >
                ADD
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default WorkOrdersAdd;
