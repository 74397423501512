import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function SelectedShipAdd({
  table_head,
  selectedCustomer,
  refreshFunc,
}) {
  const dispatch = useDispatch();
  /*   All States
   ********************************************* */
  const [loading, setLoading] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(null);

  const [showShipModal, setShowShipModal] = useState(false);
  const [checkMarks, setCheckMarks] = useState({
    signatureRelease: false,
    selfCollect: false,
  });

  const [billingAddress, setBillingAddress] = useState(null);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const updateSelectedShippingAddress = async (e) => {
    e.preventDefault();

    try {
      loadingOn();
      const { data } = await API.patch(
        "/customer/ship-address",
        {
          id: selectedCustomer?.id,
          shippingAddress: JSON.stringify({
            ...shippingAddress,
            ...checkMarks,
          }),
        },
        {
          headers: authHeader(),
        }
      );

      if (data?.success) {
        dispatch(
          setNotification({
            message: data.message,
            type: "success",
          })
        );
        await refreshFunc(selectedCustomer?.id);
        setShowShipModal(false);
      }

      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const copyBillingToShipping = () => {
    if (billingAddress) {
      setShippingAddress({
        ...shippingAddress,
        addressCompany: billingAddress.addressCompany,
        addressContact: billingAddress.addressContact,
        addressLine1: billingAddress.addressLine1,
        addressLine2: billingAddress.addressLine2,
        addressCity: billingAddress.addressCity,
        addressState: billingAddress.addressState,
        addressZipCode: billingAddress.addressZipCode,
        addressCountry: billingAddress.addressCountry,
        phone: billingAddress.accountPayablePhone,
        email: billingAddress.accountPayableEmail,
      });
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (!selectedCustomer) {
      return;
    }

    let shipAddress = selectedCustomer?.shippingAddress
      ? selectedCustomer?.shippingAddress
      : {};
    let _shippingAddress =
      typeof shipAddress === "string" ? JSON.parse(shipAddress) : shipAddress;

    setBillingAddress({ ...selectedCustomer });

    setShippingAddress({
      description: _shippingAddress?.description,
      addressCompany: _shippingAddress?.addressCompany,
      addressContact: _shippingAddress?.addressContact,
      addressLine1: _shippingAddress?.addressLine1,
      addressLine2: _shippingAddress?.addressLine2,
      addressCity: _shippingAddress?.addressCity,
      addressState: _shippingAddress?.addressState,
      addressZipCode: _shippingAddress?.addressZipCode,
      addressCountry: _shippingAddress?.addressCountry,
      phone: _shippingAddress?.phone,
      email: _shippingAddress?.email,

      authorizePerson: _shippingAddress?.authorizePerson,
      authorizeLocation: _shippingAddress?.authorizeLocation,
      serviceId: _shippingAddress?.serviceId,
      dropShipping: _shippingAddress?.dropShipping,
    });

    setCheckMarks({
      signatureRelease: _shippingAddress?.signatureRelease,
      selfCollect: _shippingAddress?.selfCollect,
    });
  }, [selectedCustomer]);

  return (
    <div>
      <div className="d-flex">
        <h4 className="border-secondary border-bottom">
          DEFAULT SHIPPING ADDRESS
        </h4>
      </div>

      <div>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <table className="table">
                <thead>
                  <tr>
                    {table_head.map((head, index) => (
                      <th key={index}>{head}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="py-1 align-middle">
                      {shippingAddress?.description}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressCompany}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressContact}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressLine1}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressLine2}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressCity}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressState}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressZipCode}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      {shippingAddress?.addressCountry}{" "}
                    </td>
                    <td className="py-1 align-middle">
                      <button
                        onClick={(e) => {
                          setShowShipModal(true);
                        }}
                        className="btn btn-sm btn-info me-1"
                      >
                        <i className="mdi mdi-pencil"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="xl"
        show={showShipModal}
        onHide={() => setShowShipModal(false)}
        contentClassName="bg-transparent"
      >
        <Modal.Body className="px-4 bg-white custom-border-radius">
          {loading && <Spinner />}
          {/*   Shipping Address
           ********************************************* */}
          <div className="d-flex justify-content-between align-items-center">
            <h3>Update Default Address</h3>
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={copyBillingToShipping}
            >
              Copy Billing Address
            </button>
          </div>
          <hr />
          <form onSubmit={updateSelectedShippingAddress}>
            <div className="row">
              <div className="col-6">
                {/* Description */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-4 pe-0">
                    <label className="form-label">Description</label>
                  </div>
                  <div className="col-8">
                    <input
                      value={shippingAddress?.description || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          description: e.target.value,
                        })
                      }
                      placeholder="Enter description"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* Address Company Name */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-4 pe-0">
                    <label className="form-label">Company Name</label>
                  </div>
                  <div className="col-8">
                    <input
                      value={shippingAddress?.addressCompany || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          addressCompany: e.target.value,
                        })
                      }
                      placeholder="Enter address company name"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* Attention To */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-4 pe-0">
                    <label className="form-label">Contact Name</label>
                  </div>
                  <div className="col-8">
                    <input
                      value={shippingAddress?.addressContact || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          addressContact: e.target.value,
                        })
                      }
                      placeholder="Enter attention to"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
                {/* Attention To */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-4 pe-0">
                    <label className="form-label">Phone</label>
                  </div>
                  <div className="col-8">
                    <input
                      value={shippingAddress?.phone || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          phone: e.target.value,
                        })
                      }
                      placeholder="Enter phone"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
                {/* Attention To */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-4 pe-0">
                    <label className="form-label">Email</label>
                  </div>
                  <div className="col-8">
                    <input
                      value={shippingAddress?.email || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          email: e.target.value,
                        })
                      }
                      placeholder="Enter attention to"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
                <hr />

                {/*   DHL Section
                 ********************************************* */}

                {/* Remote Shipping */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-5 pe-0">
                    <label className="form-label">Remote Address</label>
                  </div>
                  <div className="col-7 d-flex align-items-center justify-content-between gap-2x">
                    <div className="d-flex me-2 ">
                      <input
                        type="text"
                        className="form-control "
                        disabled
                        value={"Set from Api"}
                      />
                    </div>
                    <div className="">
                      <button
                        type="button"
                        className="btn text-nowrap btn-info"
                      >
                        Validate Address
                      </button>
                    </div>
                  </div>
                </div>
                {/* Signature Release  */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-5 pe-0">
                    <label className="form-label">Signature Release</label>
                  </div>
                  <div className="col-7 d-flex">
                    <input
                      type="checkbox"
                      checked={checkMarks?.signatureRelease}
                      onChange={(e) => {
                        setCheckMarks({
                          selfCollect: !e.target.checked,
                          signatureRelease: e.target.checked,
                        });
                      }}
                      value={shippingAddress?.email || ""}
                      id={"signatureRelease"}
                      data-switch="success"
                    />
                    <label
                      htmlFor={"signatureRelease"}
                      data-on-label="Yes"
                      data-off-label="No"
                    ></label>
                  </div>
                </div>
                {checkMarks.signatureRelease && (
                  <div>
                    {/* Authorize Person */}
                    <div className="row mb-1">
                      <div className="flex-center justify-content-start col-5 pe-0">
                        <label className="form-label">Authorize Person</label>
                      </div>
                      <div className="col-7 d-flex">
                        <input
                          value={shippingAddress?.authorizePerson || ""}
                          onChange={(e) =>
                            setShippingAddress({
                              ...shippingAddress,
                              authorizePerson: e.target.value,
                            })
                          }
                          placeholder="Enter Authorize Person"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>

                    {/* Authorize Location */}
                    <div className="row mb-1">
                      <div className="flex-center justify-content-start col-5 pe-0">
                        <label className="form-label">Authorize Location</label>
                      </div>
                      <div className="col-7 d-flex">
                        <input
                          value={shippingAddress?.authorizeLocation || ""}
                          onChange={(e) =>
                            setShippingAddress({
                              ...shippingAddress,
                              authorizeLocation: e.target.value,
                            })
                          }
                          placeholder="Enter Authorize location"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Self Collect  */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-5 pe-0">
                    <label className="form-label">Self Collect</label>
                  </div>
                  <div className="col-7 d-flex">
                    <input
                      type="checkbox"
                      checked={checkMarks?.selfCollect}
                      onChange={(e) => {
                        setCheckMarks({
                          signatureRelease: !e.target.checked,
                          selfCollect: e.target.checked,
                        });
                      }}
                      id={"selfCollect"}
                      data-switch="success"
                    />
                    <label
                      htmlFor={"selfCollect"}
                      data-on-label="Yes"
                      data-off-label="No"
                    ></label>
                  </div>
                </div>
                {/* Service ID */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-5 pe-0">
                    <label className="form-label">Service ID</label>
                  </div>
                  <div className="col-7 d-flex align-items-center justify-content-between gap-2">
                    <input
                      value={shippingAddress?.serviceId || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          serviceId: e.target.value,
                        })
                      }
                      placeholder="Enter Service ID"
                      type="text"
                      className="form-control"
                    />
                    <button type="button" className="btn btn-info text-nowrap">
                      Service Point
                    </button>
                  </div>
                </div>
              </div>

              {/*   Right Panel
               ********************************************* */}
              <div className="col-6 d-flex flex-column">
                {/* IS company Location */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-4 pe-0">
                    <label className="form-label">Drop Shipping</label>
                  </div>
                  <div className="col-8">
                    <div className="d-flex gap-2 mb-1">
                      <input
                        type="checkbox"
                        checked={shippingAddress?.dropShipping || ""}
                        onChange={(e) => {
                          setShippingAddress({
                            ...shippingAddress,
                            dropShipping: e.target.checked,
                          });
                        }}
                        id={"dropShipping"}
                        data-switch="success"
                      />
                      <label
                        htmlFor={"dropShipping"}
                        data-on-label="Yes"
                        data-off-label="No"
                      ></label>
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-4 pe-0">
                    <label className="form-label">Address 1</label>
                  </div>
                  <div className="col-8">
                    <input
                      value={shippingAddress?.addressLine1 || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          addressLine1: e.target.value,
                        })
                      }
                      placeholder="Enter address 1"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* Address 2 */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-4 pe-0">
                    <label className="form-label">Address 2</label>
                  </div>
                  <div className="col-8">
                    <input
                      value={shippingAddress?.addressLine2 || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          addressLine2: e.target.value,
                        })
                      }
                      placeholder="Enter address 2"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* Address 2 */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-4 pe-0">
                    <label className="form-label">City | State | Zip</label>
                  </div>
                  <div className="d-flex col-8">
                    <input
                      value={shippingAddress?.addressCity || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          addressCity: e.target.value,
                        })
                      }
                      placeholder="city"
                      type="text"
                      className="form-control"
                    />
                    <input
                      value={shippingAddress?.addressState || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          addressState: e.target.value,
                        })
                      }
                      placeholder="state"
                      type="text"
                      className="form-control"
                    />
                    <input
                      value={shippingAddress?.addressZipCode || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          addressZipCode: e.target.value,
                        })
                      }
                      placeholder="zipcode"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-4 pe-0">
                    <label className="form-label">Country</label>
                  </div>
                  <div className="col-8">
                    <input
                      value={shippingAddress?.addressCountry || ""}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          addressCountry: e.target.value,
                        })
                      }
                      placeholder="Enter country"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              {/* Buttons */}
              <div className="mb-1 flex-grow-1 d-flex justify-content-end align-items-end">
                <div>
                  <button
                    onClick={() => setShowShipModal(false)}
                    type="button"
                    className="btn btn-secondary me-1"
                  >
                    CLOSE
                  </button>
                  <button type="submit" className="btn btn-primary">
                    UPDATE
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
