import { useState } from "react";
import { useSelector } from "react-redux";
import AddUser from "../../Dashboard/Users/AddUser";
import UsersManagement from "../../Dashboard/Users/index";
import CompanySettings from "./CompanySettings/CompanySettings";
import DiscountLevel from "./CustomizerSettings/discountLevel/discountLevel";
import EmailFormat from "./emailFormat/emailFormat";
import Locations from "./locations/locations";
import PaymentTerms from "./paymentTerms/paymentTerms";
import ProductTypes from "./productTypes/productTypes";
import SalesPersons from "./salesPersons/salesPersons";
import ShipmentMethods from "./shipmentMethods/shipmentMethods";
import TaxRate from "./taxRate/taxRate";

import Modal from "react-bootstrap/Modal";
import PriceMatrix from "./CustomizerSettings/priceMatrix/priceMatrix";
import EmailSmtp from "./emailSmtp/EmailSmtp";
import PortalUsers from "./portalUsers/PortalUsers";
import Subscription from "./subscription/Subscription";

function Settings() {
  const user = useSelector((state) => state.user?.userInfo);
  /*   All States Below
   ********************************************* */
  const [active, setActive] = useState("company_info");
  const [showAddUser, setShowAddUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [showModal, setShowModal] = useState(false);

  return (
    <div className="d-flex" style={{ minHeight: "90vh", marginLeft: 285 }}>
      <div className="d-flex flex-grow-1 flex-column">
        <h1 className="mt-5 mb-4">Settings</h1>
        <div className="d-flex justify-content-start align-items-center align-content-center gap-2 flex-wrap">
          {/*   paste here
           **********************************************/}
          <div
            onClick={() => {
              setActive("subscription");
              setShowModal(true);
            }}
            className={`px-3 py-2 btn bg-white shadow-sm custom-border-radius-sm  `}
          >
            <div>Subscription</div>
          </div>
          <div
            onClick={() => {
              setActive("company_info");
              setShowModal(true);
            }}
            className={`px-3 py-2 btn bg-white shadow-sm custom-border-radius-sm  `}
          >
            <div>Company Info</div>
          </div>
          <div
            onClick={() => {
              setActive("locations");
              setShowModal(true);
            }}
            className={`btn px-3 py-2 bg-white shadow-sm custom-border-radius-sm  `}
          >
            <div>Locations</div>
          </div>

          <div
            onClick={() => {
              setActive("user_management");
              setShowModal(true);
            }}
            className={`btn px-3 py-2 bg-white shadow-sm custom-border-radius-sm  `}
          >
            <div>User Management</div>
          </div>

          <div
            onClick={() => {
              setActive("portal_users");
              setShowModal(true);
            }}
            className={`btn px-3 py-2 bg-white shadow-sm custom-border-radius-sm  `}
          >
            <div>Portal Users</div>
          </div>
          <div
            onClick={() => {
              setActive("sales_persons");
              setShowModal(true);
            }}
            className={`btn px-3 py-2 bg-white shadow-sm custom-border-radius-sm  `}
          >
            <div>Sales Persons</div>
          </div>
          <div
            onClick={() => {
              setActive("payment_terms");
              setShowModal(true);
            }}
            className={`btn px-3 py-2 bg-white shadow-sm custom-border-radius-sm `}
          >
            <div>Payment Terms</div>
          </div>
          <div
            onClick={() => {
              setActive("shipment_methods");
              setShowModal(true);
            }}
            className={`btn px-3 py-2 bg-white shadow-sm custom-border-radius-sm `}
          >
            <div>Shipment Methods</div>
          </div>
          <div
            onClick={() => {
              setActive("product_type");
              setShowModal(true);
            }}
            className={`btn px-3 py-2 bg-white shadow-sm custom-border-radius-sm `}
          >
            <div>Product Type</div>
          </div>
          <div
            onClick={() => {
              setActive("tax_rate");
              setShowModal(true);
            }}
            className={`btn px-3 py-2 bg-white shadow-sm custom-border-radius-sm`}
          >
            <div>Tax Rate</div>
          </div>
          <div
            onClick={() => {
              setActive("email_format");
              setShowModal(true);
            }}
            className={`btn px-3 py-2 bg-white shadow-sm custom-border-radius-sm  `}
          >
            <div>Email Template</div>
          </div>
          <div
            onClick={() => {
              setActive("email_smtp");
              setShowModal(true);
            }}
            className={`btn px-3 py-2 bg-white shadow-sm custom-border-radius-sm  `}
          >
            <div>Email Smtp</div>
          </div>
        </div>

        <h3 className="mt-5 mb-4">Customizer Setting</h3>
        <div className="d-flex justify-content-start align-items-center align-content-center gap-2 flex-wrap">
          <div
            onClick={() => {
              setActive("discount_level");
              setShowModal(true);
            }}
            className={`btn px-3 py-2 bg-white shadow-sm custom-border-radius-sm  `}
          >
            <div>Discount Level</div>
          </div>
          <div
            onClick={() => {
              setActive("price_matrix");
              setShowModal(true);
            }}
            className={`btn px-3 py-2 bg-white shadow-sm custom-border-radius-sm `}
          >
            <div>Price Matrix</div>
          </div>
        </div>
      </div>
      <Modal
        contentClassName="bg-transparent"
        size={
          active === "subscription"
            ? "md"
            : active === "company_info" || active === "user_management"
            ? "xl"
            : "lg"
        }
        centered
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setActive("");
        }}
      >
        <Modal.Body className="bg-light px-3 py-3 pb-1 custom-border-radius">
          <div>
            {active === "subscription" && <Subscription />}
            {active === "company_info" && (
              <div className="tab-pane">
                <CompanySettings />
              </div>
            )}

            {active === "user_management" &&
              (user?.permissions?.includes("user_add") ||
                user?.permissions?.includes("user_update") ||
                user?.permissions?.includes("user_delete") ||
                user?.roles === "admin") && (
                <div className="tab-pane">
                  {!showAddUser && (
                    <UsersManagement
                      setShowAddUser={setShowAddUser}
                      setSelectedUser={setSelectedUser}
                    />
                  )}
                  {showAddUser && (
                    <AddUser
                      id={selectedUser?.id}
                      setShowAddUser={setShowAddUser}
                      setSelectedUser={setSelectedUser}
                      selectedUser={selectedUser}
                    />
                  )}
                </div>
              )}
            {active === "portal_users" && (
              <div className="tab-pane">
                <PortalUsers />
              </div>
            )}
            {active === "locations" && (
              <div className="tab-pane">
                <Locations />
              </div>
            )}
            {active === "payment_terms" && (
              <div className="tab-pane">
                <PaymentTerms />
              </div>
            )}
            {active === "shipment_methods" && (
              <div className="tab-pane">
                <ShipmentMethods />
              </div>
            )}
            {active === "sales_persons" && (
              <div className="tab-pane">
                <SalesPersons />
              </div>
            )}
            {active === "product_type" && (
              <div className="tab-pane">
                <ProductTypes />
              </div>
            )}
            {active === "tax_rate" && (
              <div className="tab-pane">
                <TaxRate />
              </div>
            )}
            {active === "email_format" && (
              <div className="tab-pane">
                <EmailFormat />
              </div>
            )}
            {active === "email_smtp" && (
              <div className="tab-pane">
                <EmailSmtp />
              </div>
            )}

            {active === "discount_level" && (
              <div className="tab-pane">
                <DiscountLevel />
              </div>
            )}
            {active === "price_matrix" && (
              <div className="tab-pane">
                <PriceMatrix />
              </div>
            )}
          </div>
          <div className={`mt-3 mb-2 d-flex justify-content-end`}>
            <div>
              <button
                className="btn px-1 btn-secondary"
                type="button"
                onClick={() => {
                  setShowModal(false);
                  setActive("");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Settings;
