import React from "react";

export default function ManualRow({ rowData, updateFunc }) {
  /*   All States
   ********************************************* */

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */
  return (
    <div className="row mb-2">
      <div className="col-md-12">
        <input
          type="text"
          value={rowData?.rowValues?.customHeader || ""}
          onChange={(e) => {
            const newValue = e.target.value;
            updateFunc((prev) => ({
              ...prev,
              rowValues: { customHeader: newValue },
            }));
          }}
          name="customHeader"
          placeholder="Enter Value"
          className="form-control form-control-sm"
        />
      </div>
    </div>
  );
}
