import React from "react";
import Spreadsheet from "react-spreadsheet";

export default function ViewSheet({ spreadsheetData, columnLabels }) {
  return (
    <div className="h-100 ">
      {spreadsheetData.length > 0 ? (
        <Spreadsheet
          key={spreadsheetData.length}
          className="w-auto h-100 "
          data={spreadsheetData}
          columnLabels={columnLabels}
          rowLabels={Array.from({ length: spreadsheetData.length }, (_, i) =>
            String(i + 1)
          )}
        />
      ) : (
        <h4 className="text-muted px-3 py-2">
          Please import an Excel file to display data.
        </h4>
      )}
    </div>
  );
}
