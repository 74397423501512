import { Image, Page, Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import WOPdfHeader from "./WOPdfHeader";
import WOPdfInfo from "./WOPdfInfo";

export default function WOPdfProducts({
  styles,
  locationLogo,
  logo,
  selectedWO,
  productsList,
}) {
  /*   All States
   ********************************************* */

  /*   All Functions
   ********************************************* */
  const limitFirstPage = 2; // Limit for the first page
  const limitOtherPages = 3; // Limit for subsequent pages

  const getTotalPages = (list) => {
    if (!list || list.length === 0) return 0;

    const firstPageItems = Math.min(list.length, limitFirstPage);
    const remainingItems = list.length - firstPageItems;

    return 1 + Math.ceil(remainingItems / limitOtherPages);
  };

  /*   All UseEffects
   ********************************************* */
  let productStyle = 0;

  return (
    <>
      {productsList &&
        Array.from({ length: getTotalPages(productsList) }, (_, pagesIndex) => {
          let sliceStart, sliceEnd;

          if (pagesIndex === 0) {
            sliceStart = 0;
            sliceEnd = limitFirstPage;
          } else {
            sliceStart = limitFirstPage + (pagesIndex - 1) * limitOtherPages;
            sliceEnd = sliceStart + limitOtherPages;
          }

          const itemsInPage = productsList.slice(sliceStart, sliceEnd);

          return (
            <Page key={pagesIndex} size="A4" style={styles.page}>
              {pagesIndex === 0 && (
                <WOPdfHeader
                  locationLogo={locationLogo}
                  logo={logo}
                  styles={styles}
                  selectedWO={selectedWO}
                />
              )}

              {/*   Contact and Shipping Info
               ********************************************* */}
              {pagesIndex === 0 && (
                <WOPdfInfo
                  styles={styles}
                  selectedWO={selectedWO}
                  productsList={productsList}
                />
              )}
              {/*   ALL Product Details
               ********************************************* */}
              <View style={[styles.productDetails, { marginTop: 10 }]}>
                {/* ----------  Looping Products   ---------- */}
                {itemsInPage.map((item, index) => {
                  productStyle += 1;
                  return (
                    <View style={styles.mainContainer} key={item?.id}>
                      <View style={styles.prodMain}>
                        <Text
                          style={{
                            ...styles.prodTitle,
                            width: "30%",
                            fontSize: "12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          {item?.productType}
                        </Text>
                        <Text style={styles.prodTitle}>
                          {productStyle}: {item?.productDescription}
                        </Text>
                      </View>
                      <View style={styles.prodMain}>
                        <View style={styles.prodContent}>
                          <View style={styles.prodInfoRow}>
                            <View style={[styles.prodCol, styles.borderRight]}>
                              <Text style={styles.prodKey}>Style Number:</Text>
                              <Text style={styles.prodVal}>
                                {item?.productStyle}
                              </Text>
                            </View>
                            <View style={styles.prodCol}>
                              <Text style={styles.prodKey}>Fabrics:</Text>
                              <Text style={styles.prodVal}>
                                {item?.productFabric}
                              </Text>
                            </View>
                          </View>
                          <View style={styles.prodInfoRow}>
                            <View style={[styles.prodCol, styles.borderRight]}>
                              <Text style={styles.prodKey}>Color:</Text>
                              <Text style={styles.prodVal}>
                                {item?.productColor}
                              </Text>
                            </View>
                            <View style={styles.prodCol}>
                              <Text style={styles.prodKey}>Team:</Text>
                              <Text style={styles.prodVal}>
                                {item?.teamName}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {item?.productImage && (
                              <Image
                                style={{ width: 300, height: 160 }}
                                src={item?.productImage}
                              />
                            )}
                          </View>
                        </View>

                        <View style={styles.prodSizes}>
                          <View
                            style={{
                              borderBottom: "1px solid gray;",
                              marginBottom: 5,
                              paddingBottom: 5,
                            }}
                          >
                            <Text style={styles.prodSubHeading}>
                              Qty : {item?.totalQuantity}
                            </Text>
                          </View>

                          <Text style={styles.prodSubHeading}>
                            Size Details
                          </Text>
                          {item?.productSizes &&
                            JSON.parse(item?.productSizes).map((d) => (
                              <Text key={d.id} style={styles.prodTxt}>
                                {d?.size} = {d?.quantity}
                              </Text>
                            ))}
                        </View>
                      </View>
                      <Text style={styles.prodComments}>
                        COMMENTS: {item?.notes?.replace(/<[^>]+>/g, "")}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </Page>
          );
        })}
    </>
  );
}
