import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ContactSelectModal from "../contactSelectModal";
import UpdateWOCustomer from "./UpdateWOCustomer";

export default function WOinfo({
  formData,
  setFormData,
  refreshFunc,
  selectedWorkOrder,
  disableCustomerSelect,
}) {
  /*   All States
   ********************************************* */
  const [showCustomerModal, setShowCustomerModal] = useState(false);

  const [showContactModal, setShowContactModal] = useState(false);
  const [contactsList, setContactsList] = useState(null);

  /*   All Functions
   ********************************************* */

  const parseJsonData = (data) => {
    let newArray = [];
    data?.map((item) => {
      newArray.push({ ...item, ...JSON.parse(item.data) });
    });
    return newArray;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const getDate = (inputDate) => {
    const date = new Date(inputDate);
    return date.toISOString().slice(0, 10).replace("T", " ");
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let data =
      typeof selectedWorkOrder?.contactData === "string"
        ? JSON.parse(selectedWorkOrder?.contactData)
        : selectedWorkOrder?.contactData;
    setFormData({
      firstName: data?.firstName,
      lastName: data?.lastName,
      department: data?.department,
      phone: data?.phone,
      email: data?.email,
      jobName: selectedWorkOrder?.jobName,
      WOShipDate: getDate(selectedWorkOrder?.WOShipDate),
    });
  }, [selectedWorkOrder]);

  useEffect(() => {
    if (selectedWorkOrder?.customer?.customerinfos) {
      let typeByContact = [];
      selectedWorkOrder?.customer?.customerinfos?.forEach((item) => {
        if (item?.type === "contact") {
          typeByContact.push(item);
        }
      });
      setContactsList(parseJsonData(typeByContact));
    }
  }, [selectedWorkOrder]);

  return (
    <div className="bg-white custom-shadow flex-grow-1 px-3 pb-1 pt-3 custom-border-radius-sm">
      {/*   WO Info
       **********************************************/}
      <div className="">
        <div className="d-flex justify-content-between align-items-center gap-0">
          <input
            type="text"
            className="form-control w-50 "
            value={formData?.jobName || ""}
            placeholder="Enter Job Name"
            name="jobName"
            onChange={handleChange}
          />
          <div className="w-50 row text-nowrap d-flex align-items-center me-2">
            <label className="col-2 text-end">Due date</label>
            <div className="col-10">
              <input
                className="form-control flex-grow-1 mx-0"
                placeholder="Enter address company"
                type="date"
                value={formData?.WOShipDate}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    WOShipDate: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="d-flex align-items-center gap-1">
            <button
              disabled={!disableCustomerSelect}
              className="btn btn-info text-nowrap"
              onClick={() => setShowCustomerModal(true)}
            >
              Change Customer
            </button>
            <button
              disabled={!disableCustomerSelect}
              className="btn btn-info text-nowrap"
              onClick={() => setShowContactModal(true)}
            >
              Edit Contact
            </button>
          </div>
        </div>
        {/*   Customer Contact
         ********************************************* */}
        <div className="my-2 d-flex align-items-center justify-content-between gap-2">
          <input
            type="text"
            className="form-control"
            value={formData?.firstName || ""}
            placeholder="Enter first name"
            name="firstName"
            onChange={handleChange}
          />

          <input
            type="text"
            className="form-control"
            value={formData?.lastName || ""}
            placeholder="Enter last name"
            name="lastName"
            onChange={handleChange}
          />

          <input
            type="text"
            className="form-control"
            value={formData?.department || ""}
            placeholder="Enter department"
            name="department"
            onChange={handleChange}
          />

          <input
            type="text"
            className="form-control"
            value={formData?.phone || ""}
            placeholder="Enter phone"
            name="phone"
            onChange={handleChange}
          />

          <input
            type="text"
            className="form-control"
            value={formData?.email || ""}
            placeholder="Enter email"
            name="email"
            onChange={handleChange}
          />
        </div>
      </div>

      {/*   Customer Select Modal
       ********************************************* */}
      <Modal
        size="xl"
        contentClassName="bg-transparent"
        show={showCustomerModal}
        onHide={() => setShowCustomerModal(false)}
      >
        <Modal.Body>
          <UpdateWOCustomer
            selectedWorkOrder={selectedWorkOrder}
            showModal={setShowCustomerModal}
            refreshFunc={refreshFunc}
          />
        </Modal.Body>
      </Modal>
      {/*   Customer Contact Modal
       ********************************************* */}
      <Modal
        size="xl"
        contentClassName="bg-transparent"
        show={showContactModal}
        onHide={() => setShowContactModal(false)}
      >
        <Modal.Body>
          <ContactSelectModal
            setFormData={setFormData}
            contactsList={contactsList}
            showModal={setShowContactModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
