import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { set } from "react-hook-form";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function QuickViewProducts({
  user,
  selected,
  selectedProduct,
  setSelectedProduct,
  productsList,
  setProductsList,
  productTypesList,
  allWOData,
  setAllWOData,
}) {
  /*   All States
   ********************************************* */
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  /*   All Functions
   ********************************************* */
  const handleProductTypeSelection = async (e) => {
    let selectedType = e.target.value;

    if (!selectedProduct) {
      return;
    }

    setLoading(true);
    let updateData = {
      id: selectedProduct.id,
      productType: selectedType,
    };

    try {
      const { data } = await API.patch(
        "/wo-products",
        { data: updateData },
        {
          headers: authHeader(),
        }
      );

      if (data?.message?.type === "success") {
        let updatedProduct = {
          ...selectedProduct,
          productType: selectedType,
        };

        // Get current index of workOrder in allWOData
        let currentWOIndex = allWOData?.findIndex(
          (item) => item.id === selected?.id
        );

        // Get current index of product in allWOData
        let currentProductIndex = allWOData[
          currentWOIndex
        ]?.WOProducts?.findIndex((item) => item.id === selectedProduct.id);

        // Update the product in allWOData
        let updatedWOData = JSON.parse(JSON.stringify(allWOData));
        updatedWOData[currentWOIndex].WOProducts[currentProductIndex] =
          updatedProduct;

        setAllWOData((prev) => updatedWOData);
        setLoading(false);
      }

      if (data?.message?.type === "error") {
        dispatch(
          setNotification({
            message: data?.message?.message,
            type: "error",
          })
        );
        setLoading(false);
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  // Get Index of selected Product
  const getSelectedProductIndex = () => {
    if (productsList?.length === 0) {
      return;
    }
    let currentIndex = productsList.findIndex(
      (item) => item.id === selectedProduct.id
    );
    return currentIndex;
  };

  //  Next Product
  const nextProduct = () => {
    if (productsList?.length === 0) {
      return;
    }
    let currentIndex = getSelectedProductIndex();

    // If the current index is the last index
    if (currentIndex === productsList.length - 1) {
      setSelectedProduct(productsList[0]);
      return;
    }

    setSelectedProduct(productsList[currentIndex + 1]);
  };

  //  Previous Product
  const prevProduct = () => {
    if (productsList?.length === 0) {
      return;
    }
    let currentIndex = getSelectedProductIndex();

    // If the current index is the first index
    if (currentIndex === 0) {
      setSelectedProduct(productsList[productsList.length - 1]);
      return;
    }

    setSelectedProduct(productsList[currentIndex - 1]);
  };

  /*   All UseEffects
   ********************************************* */

  return (
    <div>
      {loading && <Spinner />}

      <div className="d-flex justify-content-between align-items-center">
        <h3>
          Product
          {` {${
            productsList?.length === 0
              ? " 0 "
              : ` ${getSelectedProductIndex() + 1} `
          } of ${productsList?.length} }`}
        </h3>
        <div className="d-flex gap-1">
          {/*   WO Product Buttons
           ********************************************* */}
          <button
            onClick={() => {
              prevProduct();
            }}
            disabled={productsList?.length === 0}
            className={"btn btn-success btn-sm p-0 px-1 "}
          >
            <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
          </button>
          <button
            onClick={() => {
              nextProduct();
            }}
            disabled={productsList?.length === 0}
            className={"btn btn-success btn-sm p-0 px-1"}
          >
            <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
          </button>
        </div>
      </div>
      <div className=" p-1">
        <div className="row mb-1">
          <label className="col-5">Product Type</label>
          {productTypesList?.length === 0 ? (
            <input
              className="form-control col-7 w-50 form-control w-fit"
              readOnly
              value={selected?.productType}
            />
          ) : (
            <select
              disabled={!selectedProduct}
              value={
                selectedProduct?.productType ? selectedProduct.productType : ""
              }
              className="col-7 w-50 form-control w-fit"
              onChange={handleProductTypeSelection}
            >
              {productTypesList &&
                productTypesList?.map((item) => (
                  <option key={item?.id} value={item?.productTypes}>
                    {item?.productTypes}
                  </option>
                ))}
            </select>
          )}
        </div>
        <div className="row mb-1">
          <label className="col-5">Product Style</label>
          <div className="col-7 d-flex align-items-center">
            {selectedProduct?.productStyle}
          </div>
        </div>
        <div className="row mb-1">
          <label className="col-5">Product Description</label>
          <div className="col-7 ">{selectedProduct?.productDescription}</div>
        </div>
        <div className="row mb-1">
          <label className="col-5">Product Fabric</label>
          <div className="col-7 ">{selectedProduct?.productFabric}</div>
        </div>
        <div className="row mb-1">
          <label className="col-5">Product Color</label>
          <div className="col-7 ">{selectedProduct?.productColor}</div>
        </div>
        <div className="row mb-1">
          <label className="col-5">Team Name</label>
          <div className="col-7 ">{selectedProduct?.teamName}</div>
        </div>

        <div className="row">
          <div className=" col-5 d-flex mb-1">
            <label className="text-nowrap ">Has Names</label>
            <div className="d-flex form-switch " style={{ paddingLeft: 48 }}>
              <input
                type="checkbox"
                checked={selectedProduct?.hasName ? true : false}
                readOnly
                className="form-check-input "
                style={{ width: 40 }}
              />
            </div>
          </div>
          <div className="col-7 d-flex mb-1">
            <label className="text-nowrap">Has Numbers</label>
            <div className="d-flex form-switch " style={{ paddingLeft: 48 }}>
              <input
                type="checkbox"
                checked={selectedProduct?.hasNumbers ? true : false}
                readOnly
                className="form-check-input "
                style={{ width: 40 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
