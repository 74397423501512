import React, { useEffect } from "react";

export default function LabelColumns({
  columnLabels,
  spreadsheetData,
  rowsData,
  setRowsData,
}) {
  /*   All States
   ********************************************* */

  /*   All Functions
   ********************************************* */
  const getColumnValueOfFirstRow = (column) => {
    if (!column || !spreadsheetData) return null;
    let colIndex = columnLabels.findIndex((col) => col === column);
    let rowIndex = 0;
    // Get value of rowIndex from spreadsheetData
    let rowValue = spreadsheetData[rowIndex][colIndex];
    return rowValue?.value;
  };

  const getMultipleColumnValueOfFirstRow = (columns) => {
    if (!columns || !spreadsheetData) return null;

    let value = "";
    columns?.map((item) => {
      value = `${
        value === "" ? value : value + ","
      } ${item}-${getColumnValueOfFirstRow(item)} `;
    });

    return value;
  };

  /*   All UseEffects
   ********************************************* */

  return (
    <div className="px-3 pb-3 py-1">
      <div className="d-flex justify-content-between align-items-center mb-1">
        <h4 className="h4">Label Columns</h4>
        <div>
          <button className="btn btn-info btn-sm">Print Label</button>
        </div>
      </div>
      <hr className="mt-0" />
      <div>
        {/* Show row 1 Data */}
        <div className="row">
          <h4 className="col-md-1 mb-0 text-center align-items-center d-flex">
            R1
          </h4>
          <div className="col-md-11">
            <div
              className="border text-center p-2 py-1"
              style={{ minHeight: 31 }}
            >
              {rowsData?.row1 && rowsData?.row1?.rowType === "manual" && (
                <h5 className="m-0 p-0">
                  {rowsData.row1.rowValues?.customHeader}
                </h5>
              )}

              {rowsData?.row1 && rowsData?.row1?.rowType === "single" && (
                <h5 className="m-0 p-0">
                  {rowsData?.row1?.rowValues?.hideHeader
                    ? ""
                    : `${
                        rowsData?.row1?.rowValues?.customHeader
                          ? `${rowsData?.row1?.rowValues?.customHeader} - `
                          : ""
                      }`}
                  {`${rowsData?.row1?.rowValues?.hideHeader ? "" : ""}${
                    rowsData?.row1?.rowValues?.column
                      ? `${
                          rowsData?.row1?.rowValues?.column
                        } - ${getColumnValueOfFirstRow(
                          rowsData?.row1?.rowValues?.column
                        )}
                        `
                      : ""
                  }`}
                </h5>
              )}

              {rowsData?.row1 && rowsData?.row1?.rowType === "multiple" && (
                <h5 className="m-0 p-0">
                  {rowsData?.row1?.rowValues?.hideHeader
                    ? ""
                    : `${
                        rowsData?.row1?.rowValues?.customHeader
                          ? `${rowsData?.row1?.rowValues?.customHeader} - `
                          : ""
                      }`}

                  {`${rowsData?.row1?.rowValues?.hideHeader ? "" : ""}${
                    rowsData?.row1?.rowValues?.columns
                      ? getMultipleColumnValueOfFirstRow(
                          rowsData?.row1?.rowValues?.columns
                        )
                      : ""
                  }`}
                </h5>
              )}
            </div>
          </div>
        </div>

        {/* Show row 2 Data */}
        <div className="row mt-2">
          <h4 className="col-md-1 mb-0 text-center align-items-center d-flex">
            R2
          </h4>
          <div className="col-md-11">
            <div
              className="border text-center p-2 py-1"
              style={{ minHeight: 31 }}
            >
              {rowsData?.row2 && rowsData?.row2?.rowType === "manual" && (
                <h5 className="m-0 p-0">
                  {rowsData.row2.rowValues?.customHeader}
                </h5>
              )}

              {rowsData?.row2 && rowsData?.row2?.rowType === "single" && (
                <h5 className="m-0 p-0">
                  {rowsData?.row2?.rowValues?.hideHeader
                    ? ""
                    : `${
                        rowsData?.row2?.rowValues?.customHeader
                          ? `${rowsData?.row2?.rowValues?.customHeader} - `
                          : ""
                      }`}
                  {`${rowsData?.row2?.rowValues?.hideHeader ? "" : ""}${
                    rowsData?.row2?.rowValues?.column
                      ? `${
                          rowsData?.row2?.rowValues?.column
                        } - ${getColumnValueOfFirstRow(
                          rowsData?.row2?.rowValues?.column
                        )}
                        `
                      : ""
                  }`}
                </h5>
              )}

              {rowsData?.row2 && rowsData?.row2?.rowType === "multiple" && (
                <h5 className="m-0 p-0">
                  {rowsData?.row2?.rowValues?.hideHeader
                    ? ""
                    : `${
                        rowsData?.row2?.rowValues?.customHeader
                          ? `${rowsData?.row2?.rowValues?.customHeader} - `
                          : ""
                      }`}

                  {`${rowsData?.row2?.rowValues?.hideHeader ? "" : ""}${
                    rowsData?.row2?.rowValues?.columns
                      ? getMultipleColumnValueOfFirstRow(
                          rowsData?.row2?.rowValues?.columns
                        )
                      : ""
                  }`}
                </h5>
              )}
            </div>
          </div>
        </div>

        {/* Show row 3 Data */}
        <div className="row mt-2">
          <h4 className="col-md-1 mb-0 text-center align-items-center d-flex">
            R3
          </h4>
          <div className="col-md-11">
            <div
              className="border text-center p-2 py-1"
              style={{ minHeight: 31 }}
            >
              {rowsData?.row3 && rowsData?.row3?.rowType === "manual" && (
                <h5 className="m-0 p-0">
                  {rowsData.row3.rowValues?.customHeader}
                </h5>
              )}

              {rowsData?.row3 && rowsData?.row3?.rowType === "single" && (
                <h5 className="m-0 p-0">
                  {rowsData?.row3?.rowValues?.hideHeader
                    ? ""
                    : `${
                        rowsData?.row3?.rowValues?.customHeader
                          ? `${rowsData?.row3?.rowValues?.customHeader} - `
                          : ""
                      }`}
                  {`${rowsData?.row3?.rowValues?.hideHeader ? "" : ""}${
                    rowsData?.row3?.rowValues?.column
                      ? `${
                          rowsData?.row3?.rowValues?.column
                        } - ${getColumnValueOfFirstRow(
                          rowsData?.row3?.rowValues?.column
                        )}
                        `
                      : ""
                  }`}
                </h5>
              )}

              {rowsData?.row3 && rowsData?.row3?.rowType === "multiple" && (
                <h5 className="m-0 p-0">
                  {rowsData?.row3?.rowValues?.hideHeader
                    ? ""
                    : `${
                        rowsData?.row3?.rowValues?.customHeader
                          ? `${rowsData?.row3?.rowValues?.customHeader} - `
                          : ""
                      }`}

                  {`${rowsData?.row3?.rowValues?.hideHeader ? "" : ""}${
                    rowsData?.row3?.rowValues?.columns
                      ? getMultipleColumnValueOfFirstRow(
                          rowsData?.row3?.rowValues?.columns
                        )
                      : ""
                  }`}
                </h5>
              )}
            </div>
          </div>
        </div>

        {/* Show row 4 Data */}
        <div className="row mt-2">
          <h4 className="col-md-1 mb-0 text-center align-items-center d-flex">
            R4
          </h4>
          <div className="col-md-11">
            <div
              className="border text-center p-2 py-1"
              style={{ minHeight: 31 }}
            >
              {rowsData?.row4 && rowsData?.row4?.rowType === "manual" && (
                <h5 className="m-0 p-0">
                  {rowsData.row4.rowValues?.customHeader}
                </h5>
              )}

              {rowsData?.row4 && rowsData?.row4?.rowType === "single" && (
                <h5 className="m-0 p-0">
                  {rowsData?.row4?.rowValues?.hideHeader
                    ? ""
                    : `${
                        rowsData?.row4?.rowValues?.customHeader
                          ? `${rowsData?.row4?.rowValues?.customHeader} - `
                          : ""
                      }`}
                  {`${rowsData?.row4?.rowValues?.hideHeader ? "" : ""}${
                    rowsData?.row4?.rowValues?.column
                      ? `${
                          rowsData?.row4?.rowValues?.column
                        } - ${getColumnValueOfFirstRow(
                          rowsData?.row4?.rowValues?.column
                        )}
                        `
                      : ""
                  }`}
                </h5>
              )}

              {rowsData?.row4 && rowsData?.row4?.rowType === "multiple" && (
                <h5 className="m-0 p-0">
                  {rowsData?.row4?.rowValues?.hideHeader
                    ? ""
                    : `${
                        rowsData?.row4?.rowValues?.customHeader
                          ? `${rowsData?.row4?.rowValues?.customHeader} - `
                          : ""
                      }`}

                  {`${rowsData?.row4?.rowValues?.hideHeader ? "" : ""}${
                    rowsData?.row4?.rowValues?.columns
                      ? getMultipleColumnValueOfFirstRow(
                          rowsData?.row4?.rowValues?.columns
                        )
                      : ""
                  }`}
                </h5>
              )}
            </div>
          </div>
        </div>

        {/* Show row 5 Data */}
        <div className="row mt-2">
          <h4 className="col-md-1 mb-0 text-center align-items-center d-flex">
            R5
          </h4>
          <div className="col-md-11">
            <div
              className="border text-center p-2 py-1"
              style={{ minHeight: 31 }}
            >
              {rowsData?.row5 && rowsData?.row5?.rowType === "manual" && (
                <h5 className="m-0 p-0">
                  {rowsData.row5.rowValues?.customHeader}
                </h5>
              )}

              {rowsData?.row5 && rowsData?.row5?.rowType === "single" && (
                <h5 className="m-0 p-0">
                  {rowsData?.row5?.rowValues?.hideHeader
                    ? ""
                    : `${
                        rowsData?.row5?.rowValues?.customHeader
                          ? `${rowsData?.row5?.rowValues?.customHeader} - `
                          : ""
                      }`}
                  {`${rowsData?.row5?.rowValues?.hideHeader ? "" : ""}${
                    rowsData?.row5?.rowValues?.column
                      ? `${
                          rowsData?.row5?.rowValues?.column
                        } - ${getColumnValueOfFirstRow(
                          rowsData?.row5?.rowValues?.column
                        )}
                        `
                      : ""
                  }`}
                </h5>
              )}

              {rowsData?.row5 && rowsData?.row5?.rowType === "multiple" && (
                <h5 className="m-0 p-0">
                  {rowsData?.row5?.rowValues?.hideHeader
                    ? ""
                    : `${
                        rowsData?.row5?.rowValues?.customHeader
                          ? `${rowsData?.row5?.rowValues?.customHeader} - `
                          : ""
                      }`}

                  {`${rowsData?.row5?.rowValues?.hideHeader ? "" : ""}${
                    rowsData?.row5?.rowValues?.columns
                      ? getMultipleColumnValueOfFirstRow(
                          rowsData?.row5?.rowValues?.columns
                        )
                      : ""
                  }`}
                </h5>
              )}
            </div>
          </div>
        </div>

        {/* Show row 6 Data */}
        <div className="row mt-2">
          <h4 className="col-md-1 mb-0 text-center align-items-center d-flex">
            R6
          </h4>
          <div className="col-md-11">
            <div
              className="border text-center p-2 py-1"
              style={{ minHeight: 31 }}
            >
              {rowsData?.row6 && rowsData?.row6?.rowType === "manual" && (
                <h5 className="m-0 p-0">
                  {rowsData.row6.rowValues?.customHeader}
                </h5>
              )}

              {rowsData?.row6 && rowsData?.row6?.rowType === "single" && (
                <h5 className="m-0 p-0">
                  {rowsData?.row6?.rowValues?.hideHeader
                    ? ""
                    : `${
                        rowsData?.row6?.rowValues?.customHeader
                          ? `${rowsData?.row6?.rowValues?.customHeader} - `
                          : ""
                      }`}
                  {`${rowsData?.row6?.rowValues?.hideHeader ? "" : ""}${
                    rowsData?.row6?.rowValues?.column
                      ? `${
                          rowsData?.row6?.rowValues?.column
                        } - ${getColumnValueOfFirstRow(
                          rowsData?.row6?.rowValues?.column
                        )}
                        `
                      : ""
                  }`}
                </h5>
              )}

              {rowsData?.row6 && rowsData?.row6?.rowType === "multiple" && (
                <h5 className="m-0 p-0">
                  {rowsData?.row6?.rowValues?.hideHeader
                    ? ""
                    : `${
                        rowsData?.row6?.rowValues?.customHeader
                          ? `${rowsData?.row6?.rowValues?.customHeader} - `
                          : ""
                      }`}

                  {`${rowsData?.row6?.rowValues?.hideHeader ? "" : ""}${
                    rowsData?.row6?.rowValues?.columns
                      ? getMultipleColumnValueOfFirstRow(
                          rowsData?.row6?.rowValues?.columns
                        )
                      : ""
                  }`}
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
