import { useState } from "react";
import ManualRow from "./ManualRow";
import MultipleRows from "./MultipleRows";
import SingleRow from "./SingleRow";

export default function SelectExcelData({ columnLabels, setRowsData }) {
  /*   All States
   ********************************************* */

  const [row1, setRow1] = useState({
    rowNo: 1,
    rowType: "manual",
    rowValues: {},
  });

  const [row2, setRow2] = useState({
    rowNo: 2,
    rowType: "manual",
    rowValues: {},
  });

  const [row3, setRow3] = useState({
    rowNo: 3,
    rowType: "manual",
    rowValues: {},
  });

  const [row4, setRow4] = useState({
    rowNo: 4,
    rowType: "manual",
    rowValues: {},
  });

  const [row5, setRow5] = useState({
    rowNo: 5,
    rowType: "manual",
    rowValues: {},
  });

  const [row6, setRow6] = useState({
    rowNo: 6,
    rowType: "manual",
    rowValues: {},
  });

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  return (
    <div className="px-2 py-1">
      <div className="d-flex justify-content-between align-items-center mb-1">
        <h4 className="h4">Select Label Data</h4>
        {/* {JSON.stringify(row1)} */}
        <div>
          <button
            onClick={() => {
              setRowsData({
                row1: row1,
                row2: row2,
                row3: row3,
                row4: row4,
                row5: row5,
                row6: row6,
              });
            }}
            className="btn btn-info btn-sm"
          >
            Load Data
          </button>
        </div>
      </div>
      <hr className="mt-0" />
      <div>
        {/* Row 1 */}
        <div className="row">
          <div className="col-md-2">
            <select
              onChange={(e) => {
                setRow1((prev) => {
                  return { ...prev, rowType: e.target.value };
                });
              }}
              className=" form-control form-control-sm"
              name="rowType"
            >
              <option value="manual">Manual</option>
              <option value="single">Single</option>
              <option value="multiple">Multiple</option>
            </select>
          </div>
          <div className="col-md-10">
            {row1.rowType === "manual" && (
              <ManualRow rowData={row1} updateFunc={setRow1} />
            )}
            {row1.rowType === "single" && (
              <SingleRow
                columnLabels={columnLabels}
                rowData={row1}
                updateFunc={setRow1}
              />
            )}
            {row1.rowType === "multiple" && (
              <MultipleRows
                rowData={row1}
                updateFunc={setRow1}
                columnLabels={columnLabels}
              />
            )}
          </div>
        </div>
        {/* Row 2 */}
        <div className="row">
          <div className="col-md-2">
            <select
              onChange={(e) => {
                setRow2((prev) => {
                  return { ...prev, rowType: e.target.value };
                });
              }}
              className=" form-control form-control-sm"
              name="rowType"
            >
              <option value="manual">Manual</option>
              <option value="single">Single</option>
              <option value="multiple">Multiple</option>
            </select>
          </div>
          <div className="col-md-10">
            {row2.rowType === "manual" && (
              <ManualRow updateFunc={setRow2} rowData={row2} />
            )}
            {row2.rowType === "single" && (
              <SingleRow
                columnLabels={columnLabels}
                updateFunc={setRow2}
                rowData={row2}
              />
            )}
            {row2.rowType === "multiple" && (
              <MultipleRows
                columnLabels={columnLabels}
                updateFunc={setRow2}
                rowData={row2}
              />
            )}
          </div>
        </div>
        {/* Row 3 */}
        <div className="row">
          <div className="col-md-2">
            <select
              onChange={(e) => {
                setRow3((prev) => {
                  return { ...prev, rowType: e.target.value };
                });
              }}
              className=" form-control form-control-sm"
              name="rowType"
            >
              <option value="manual">Manual</option>
              <option value="single">Single</option>
              <option value="multiple">Multiple</option>
            </select>
          </div>
          <div className="col-md-10">
            {row3.rowType === "manual" && (
              <ManualRow updateFunc={setRow3} rowData={row3} />
            )}
            {row3.rowType === "single" && (
              <SingleRow
                columnLabels={columnLabels}
                updateFunc={setRow3}
                rowData={row3}
              />
            )}
            {row3.rowType === "multiple" && (
              <MultipleRows
                columnLabels={columnLabels}
                updateFunc={setRow3}
                rowData={row3}
              />
            )}
          </div>
        </div>
        {/* Row 4 */}
        <div className="row">
          <div className="col-md-2">
            <select
              onChange={(e) => {
                setRow4((prev) => {
                  return { ...prev, rowType: e.target.value };
                });
              }}
              className=" form-control form-control-sm"
              name="rowType"
            >
              <option value="manual">Manual</option>
              <option value="single">Single</option>
              <option value="multiple">Multiple</option>
            </select>
          </div>
          <div className="col-md-10">
            {row4.rowType === "manual" && (
              <ManualRow updateFunc={setRow4} rowData={row4} />
            )}
            {row4.rowType === "single" && (
              <SingleRow
                columnLabels={columnLabels}
                updateFunc={setRow4}
                rowData={row4}
              />
            )}
            {row4.rowType === "multiple" && (
              <MultipleRows
                columnLabels={columnLabels}
                updateFunc={setRow4}
                rowData={row4}
              />
            )}
          </div>
        </div>
        {/* Row 5 */}
        <div className="row">
          <div className="col-md-2">
            <select
              onChange={(e) => {
                setRow5((prev) => {
                  return { ...prev, rowType: e.target.value };
                });
              }}
              className=" form-control form-control-sm"
              name="rowType"
            >
              <option value="manual">Manual</option>
              <option value="single">Single</option>
              <option value="multiple">Multiple</option>
            </select>
          </div>
          <div className="col-md-10">
            {row5.rowType === "manual" && (
              <ManualRow updateFunc={setRow5} rowData={row5} />
            )}
            {row5.rowType === "single" && (
              <SingleRow
                columnLabels={columnLabels}
                updateFunc={setRow5}
                rowData={row5}
              />
            )}
            {row5.rowType === "multiple" && (
              <MultipleRows
                columnLabels={columnLabels}
                updateFunc={setRow5}
                rowData={row5}
              />
            )}
          </div>
        </div>
        {/* Row 6 */}
        <div className="row">
          <div className="col-md-2">
            <select
              onChange={(e) => {
                setRow6((prev) => {
                  return { ...prev, rowType: e.target.value };
                });
              }}
              className=" form-control form-control-sm"
              name="rowType"
            >
              <option value="manual">Manual</option>
              <option value="single">Single</option>
              <option value="multiple">Multiple</option>
            </select>
          </div>
          <div className="col-md-10">
            {row6.rowType === "manual" && (
              <ManualRow updateFunc={setRow6} rowData={row6} />
            )}
            {row6.rowType === "single" && (
              <SingleRow
                columnLabels={columnLabels}
                updateFunc={setRow6}
                rowData={row6}
              />
            )}
            {row6.rowType === "multiple" && (
              <MultipleRows
                columnLabels={columnLabels}
                updateFunc={setRow6}
                rowData={row6}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
