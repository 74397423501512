import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import AddressSelectModal from "../addressSelectModal";

export default function ShippingAddress({
  selectedWorkOrder,
  refreshFunc,
  disableCustomerSelect,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [shippingAddress, setShippingAddress] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);

  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [checkMarks, setCheckMarks] = useState({
    signatureRelease: false,
    selfCollect: false,
  });

  /*   All Functions
   ********************************************* */

  const handleCopy = () => {
    let billingAddress =
      typeof selectedWorkOrder?.billingAddress === "string"
        ? JSON.parse(selectedWorkOrder?.billingAddress)
        : selectedWorkOrder?.billingAddress;

    let shippingAddressData = {
      description: billingAddress?.description,
      addressCompany: billingAddress?.addressCompany,
      addressLine1: billingAddress?.addressLine1,
      addressLine2: billingAddress?.addressLine2,
      city: billingAddress?.city,
      state: billingAddress?.state,
      zip: billingAddress?.zip,
      country: billingAddress?.country,
    };
    setFormData(shippingAddressData);
  };

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const handleUpdateShippingAddress = async (e) => {
    e.preventDefault();
    try {
      loadingOn();
      let shippingAddressData = {
        description: formData?.description,
        addressCompany: formData?.addressCompany,
        addressLine1: formData?.addressLine1,
        addressLine2: formData?.addressLine2,
        city: formData?.city,
        state: formData?.state,
        zip: formData?.zip,
        country: formData?.country,
        email: formData?.email,
        phone: formData?.phone,
        signatureRelease: formData?.signatureRelease,
        authorizePerson: formData?.authorizePerson,
        selfCollect: formData?.selfCollect,
        authorizeLocation: formData?.authorizeLocation,
        serviceId: formData?.serviceId,
        dropShipping: formData?.dropShipping,
      };
      let apiData = {
        id: selectedWorkOrder?.id,
        data: { shippingAddress: shippingAddressData },
      };
      const { data } = await API.patch("/work-orders", apiData, {
        headers: authHeader(),
      });
      if (data?.message?.type === "success") {
        await refreshFunc();
        loadingOff();
        setShowUpdateModal(false);
        setFormData(null);
        return;
      }
      console.log(data);
      alert("something went wrong check logs");
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let shippingAddress =
      selectedWorkOrder?.shippingAddress &&
      typeof selectedWorkOrder?.shippingAddress === "string"
        ? JSON.parse(selectedWorkOrder?.shippingAddress)
        : selectedWorkOrder?.shippingAddress;

    setShippingAddress(shippingAddress);
    if (showUpdateModal) {
      setFormData(shippingAddress);
      setCheckMarks({
        signatureRelease: shippingAddress.signatureRelease,
        selfCollect: shippingAddress.selfCollect,
      });
    }
  }, [selectedWorkOrder, showUpdateModal]);

  /*   All UseEffects
   ********************************************* */
  return (
    <div className="bg-white shadow-lg w-50 px-3 pb-2 custom-border-radius-sm">
      {loading && <Spinner />}
      {/*   WO Info
       ********************************************* */}
      <div className="d-flex inline-block justify-content-between align-items-center">
        <h4 className="mb-0">Shipping Address </h4>
        <h4 className="mb-0">
          <button
            type="button"
            className="btn btn-info btn-sm"
            onClick={() => setShowUpdateModal(true)}
          >
            Edit
          </button>
        </h4>
      </div>
      <hr className="mt-0 mb-1 w-50" />
      {/* Job Name */}
      <div className="row">
        <div className="col-4 fw-bold"> Description</div>
        <div className="col-8">{shippingAddress?.description}</div>
      </div>
      <div className="row">
        <div className="col-4 fw-bold"> Company</div>
        <div className="col-8">{shippingAddress?.addressCompany}</div>
      </div>
      <div className="row">
        <div className="col-4 fw-bold">Address Line 1</div>
        <div className="col-8">{shippingAddress?.addressLine1}</div>
      </div>
      <div className="row">
        <div className="col-4 fw-bold">Address Line 2</div>
        <div className="col-8">{shippingAddress?.addressLine2}</div>
      </div>
      <div className="row">
        <div className="col-4 fw-bold">City | State | Zip</div>
        <div className="col-8">
          {shippingAddress?.city} | {shippingAddress?.state} |{" "}
          {shippingAddress?.zip}
        </div>
      </div>
      <div className="row">
        <div className="col-4 fw-bold">Country</div>
        <div className="col-8">{shippingAddress?.country}</div>
      </div>

      {/* <div className="row">
        <div className="col-4 fw-bold">Phone</div>
        <div className="col-8">{shippingAddress?.phone}</div>
      </div>
      <div className="row">
        <div className="col-4 fw-bold">Email</div>
        <div className="col-8">{shippingAddress?.email}</div>
      </div> */}
      <div className="row">
        <div className="col-4 fw-bold">Signature Release</div>
        <div className="col-8">
          {shippingAddress?.signatureRelease ? "Yes" : "No"}
        </div>
      </div>
      {shippingAddress?.signatureRelease && (
        <>
          <div className="row">
            <div className="col-4 fw-bold">Authorize Person</div>
            <div className="col-8">{shippingAddress?.authorizePerson}</div>
          </div>
          <div className="row">
            <div className="col-4 fw-bold">Authorize Location</div>
            <div className="col-8">{shippingAddress?.authorizeLocation}</div>
          </div>
        </>
      )}
      <div className="row">
        <div className="col-4 fw-bold">Self Collect</div>
        <div className="col-8">
          {shippingAddress?.selfCollect ? "Yes" : "No"}
        </div>
      </div>

      <div className="row">
        <div className="col-4 fw-bold">Service ID</div>
        <div className="col-8">{shippingAddress?.serviceId}</div>
      </div>
      <div className="row">
        <div className="col-4 fw-bold">Drop Shipping</div>
        <div className="col-8">
          {shippingAddress?.dropShipping ? "Yes" : "No"}
        </div>
      </div>

      {/*       
      phone: address?.phone,
      email: address?.email,

      signatureRelease: address?.signatureRelease,
      authorizePerson: address?.authorizePerson,
      selfCollect: address?.selfCollect,
      authorizeLocation: address?.authorizeLocation,
      serviceId: address?.serviceId,
      dropShipping: address?.dropShipping, 
      */}

      {/*   Customer Select Modal
       ********************************************* */}
      <Modal
        size="xl"
        contentClassName="bg-transparent"
        show={showUpdateModal}
        onHide={() => {
          setFormData(null);
          setShowUpdateModal(false);
        }}
      >
        <Modal.Body>
          <div
            className="bg-white shadow-lg px-3 pb-2 pt-2 mb-2 me-2"
            style={{ borderRadius: 20 }}
          >
            <div className="fw-bold fs-4 mt-3 d-flex justify-content-between">
              <div>Shipping Address</div>
              <div className=" flex-grow-1 text-end me-2">
                <button
                  onClick={handleCopy}
                  type="button"
                  className="btn btn-sm rounded border-success  mb-1"
                >
                  <i className="mdi mdi-arrow-left"></i> Copy
                </button>
              </div>
              <button
                onClick={() => {
                  setShowAddressModal(true);
                }}
                disabled={!disableCustomerSelect}
                type="button"
                className="btn btn-sm btn-primary mb-1"
              >
                Choose
              </button>
            </div>
            <hr className="my-0 py-0 mb-2" />
            <form onSubmit={handleUpdateShippingAddress} className=" p-1">
              <div className="row">
                <div className="col-md-6">
                  <div className="row mb-2">
                    <label className="col-4 "> Description</label>
                    <div className="col-8 ">
                      <input
                        className="form-control  mx-0"
                        placeholder="Enter  description"
                        value={formData?.description}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            description: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <label className="col-4 "> Company</label>
                    <div className="col-8 ">
                      <input
                        className="form-control mx-0"
                        placeholder="Enter address company"
                        value={formData?.addressCompany}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            addressCompany: e.target.value,
                          })
                        }
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <label className="col-4 ">Address Line 1</label>
                    <div className="col-8 ">
                      <input
                        className="form-control mx-0"
                        placeholder="Address Line 1"
                        type="text"
                        value={formData?.addressLine1}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            addressLine1: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <label className="col-4 ">Address Line 2</label>
                    <div className="col-8 ">
                      <input
                        className="form-control mx-0"
                        placeholder="Address Line 2"
                        type="text"
                        value={formData?.addressLine2}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            addressLine2: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <label className="col-4 ">City | State | Zip</label>
                    <div className="col-8 d-flex gap-1">
                      <input
                        className="form-control w-50"
                        placeholder="City"
                        type="text"
                        value={formData?.city}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            city: e.target.value,
                          })
                        }
                      />
                      <input
                        className="form-control"
                        style={{ width: "20%" }}
                        placeholder="State"
                        type="text"
                        value={formData?.state}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            state: e.target.value,
                          })
                        }
                      />
                      <input
                        className="form-control"
                        style={{ width: "30%" }}
                        placeholder="Zip"
                        type="text"
                        value={formData?.zip}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            zip: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <label className="col-4 ">Country</label>
                    <div className="col-8 ">
                      <input
                        className="form-control mx-0"
                        placeholder="Country"
                        type="text"
                        value={formData?.country}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            country: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="text-end mt-3">
                    <button
                      onClick={() => {
                        setShowUpdateModal(false);
                      }}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-success ms-1">
                      Save
                    </button>
                  </div>
                </div>
                {/* Right Column */}
                <div className="col-md-6">
                  {/* Remote Shipping */}
                  <div className="row mb-1">
                    <div className="flex-center justify-content-start col-5 pe-0">
                      <label className="form-label">Remote Address</label>
                    </div>
                    <div className="col-7 d-flex align-items-center justify-content-between gap-2x">
                      <div className="d-flex me-2 ">
                        <input
                          type="text"
                          className="form-control "
                          disabled
                          value={"Set from Api"}
                        />
                      </div>
                      <div className="">
                        <button
                          type="button"
                          className="btn text-nowrap btn-info"
                        >
                          Validate Address
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Signature Release */}
                  <div className="row mb-1">
                    <div className="flex-center justify-content-start col-5 pe-0">
                      <label className="form-label">Signature Release</label>
                    </div>
                    <div className="col-7 d-flex">
                      <input
                        type="checkbox"
                        checked={checkMarks?.signatureRelease}
                        onChange={(e) => {
                          setCheckMarks({
                            selfCollect: !e.target.checked,
                            signatureRelease: e.target.checked,
                          });
                        }}
                        id={"signatureRelease"}
                        data-switch="success"
                      />
                      <label
                        htmlFor={"signatureRelease"}
                        data-on-label="Yes"
                        data-off-label="No"
                      ></label>
                    </div>
                  </div>
                  {checkMarks.signatureRelease && (
                    <div>
                      {/* Authorize Person */}
                      <div className="row mb-1">
                        <div className="flex-center justify-content-start col-5 pe-0">
                          <label className="form-label">Authorize Person</label>
                        </div>
                        <div className="col-7 d-flex">
                          <input
                            value={formData?.authorizePerson}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                authorizePerson: e.target.value,
                              })
                            }
                            placeholder="Enter Authorize Person"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      {/* Authorize Location */}
                      <div className="row mb-1">
                        <div className="flex-center justify-content-start col-5 pe-0">
                          <label className="form-label">
                            Authorize Location
                          </label>
                        </div>
                        <div className="col-7 d-flex">
                          <input
                            value={formData?.authorizeLocation}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                authorizeLocation: e.target.value,
                              })
                            }
                            placeholder="Enter Authorize location"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row mb-1">
                    <div className="flex-center justify-content-start col-5 pe-0">
                      <label className="form-label">Self Collect</label>
                    </div>
                    <div className="col-7 d-flex">
                      <input
                        type="checkbox"
                        checked={checkMarks?.selfCollect}
                        onChange={(e) => {
                          setCheckMarks({
                            signatureRelease: !e.target.checked,
                            selfCollect: e.target.checked,
                          });
                        }}
                        id={"selfCollect"}
                        data-switch="success"
                      />
                      <label
                        htmlFor={"selfCollect"}
                        data-on-label="Yes"
                        data-off-label="No"
                      ></label>
                    </div>
                  </div>
                  {/* Service ID */}
                  <div className="row mb-1">
                    <div className="flex-center justify-content-start col-5 pe-0">
                      <label className="form-label">Service ID</label>
                    </div>
                    <div className="col-7 d-flex align-items-center justify-content-between gap-2">
                      <input
                        value={formData?.serviceId}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            serviceId: e.target.value,
                          })
                        }
                        placeholder="Enter Service ID"
                        type="text"
                        className="form-control"
                      />
                      <button
                        type="button"
                        className="btn btn-info text-nowrap"
                      >
                        Service Point
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/*   Customer Address Modal
             ********************************************* */}
            <Modal
              size="xl"
              contentClassName="bg-transparent"
              show={showAddressModal}
              onHide={() => setShowAddressModal(false)}
            >
              <Modal.Body>
                <AddressSelectModal
                  selectedWorkOrder={selectedWorkOrder}
                  showModal={setShowAddressModal}
                  setFormData={setFormData}
                />
              </Modal.Body>
            </Modal>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
