import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import MockupGuestList from "./GuestList/MockupGuestList";

export default function EmailToCustomer({
  showModal,
  link,
  getLink,
  selectedDesign,
  setSelectedDesign,
  refreshFunc,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [loading, setLoading] = useState(false);
  const [emailTo, setEmailTo] = useState(selectedDesign?.email);
  const [showGuestEmailListModal, setShowGuestEmailListModal] = useState(false);
  const [selectedGuestEmails, setSelectedGuestEmails] = useState([]);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const sendEmail = async () => {
    try {
      if (!emailTo) {
        dispatch(
          setNotification({ type: "error", message: "Please select a contact" })
        );
        return;
      }

      let _selectedEmailList = selectedGuestEmails?.filter(
        (email) => email.checked
      );

      if (selectedDesign?.customer?.user?.email) {
        _selectedEmailList.push({
          name: `${selectedDesign?.customer?.user?.firstName} ${selectedDesign?.customer?.user?.lastName}`,
          email: selectedDesign?.customer?.user?.email,
        });
      }
      if (selectedDesign?.customer?.salesPerson?.email) {
        _selectedEmailList.push({
          name: selectedDesign?.customer?.salesPerson?.name,
          email: selectedDesign?.customer?.salesPerson?.email,
        });
      }

      let guestEmailList = _selectedEmailList.map(
        (email) => `"${email.name}" <${email.email}>`
      );

      console.log(guestEmailList);

      loadingOn();

      const { data } = await API.post(
        "/mockup-email",
        {
          emailTo: emailTo,
          id: selectedDesign?.id,
          mockupTitle: selectedDesign?.title,
          locationLogo: selectedDesign?.customer?.location?.logo,
          locationName: selectedDesign?.customer?.location?.locationName,
          contactName: selectedDesign?.customer?.customerName,
          locationId: selectedDesign?.customer?.locationId,
          products: JSON.stringify(selectedDesign?.products),
          emailGuestList: JSON.stringify(guestEmailList),
          link,
        },
        {
          headers: authHeader(),
        }
      );
      if (data.type === "success") {
        dispatch(setNotification({ type: "success", message: data.message }));
      } else {
        dispatch(setNotification({ type: "error", message: data.message }));
      }
      loadingOff();
      showModal(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );

      loadingOff();
    }
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (selectedDesign) {
      getLink({ showMsg: false });

      setSelectedGuestEmails(
        selectedDesign?.designRequestGuestLists
          ? selectedDesign?.designRequestGuestLists.map((email) => ({
              ...email,
              checked: true,
            }))
          : []
      );
    }
  }, [selectedDesign]);

  if (!link) return <Spinner />;

  return (
    <div>
      {loading && <Spinner />}
      <div className="d-flex justify-content-between align-items-center">
        <h3>Send Email</h3>
        <div className="d-flex gap-2">
          <div>
            <button
              onClick={() => {
                setShowGuestEmailListModal(true);
              }}
              className="btn btn-sm btn-primary"
            >
              Guest Email List
            </button>
          </div>
        </div>
      </div>
      <hr className="mt-1" />
      <div className="my-2">
        <label htmlFor="emailTo">Email To</label>
        <input
          type="email"
          required
          placeholder="Enter Email"
          name="emailTo"
          value={emailTo || ""}
          onChange={(e) => {
            setEmailTo(e.target.value);
          }}
          className="form-control"
        />
      </div>
      <hr className="mt-1" />
      {
        <div className="my-3">
          <h4>Inside Sales Person</h4>
          <div>{selectedDesign?.customer?.user?.email}</div>
          <h4>Outside Sales Person</h4>
          <div>{selectedDesign?.customer?.salesPerson?.email}</div>
        </div>
      }
      <h3>Guest List</h3>
      <hr className="mt-1" />
      {selectedGuestEmails?.map((email, index) => (
        <div className="my-2" key={index}>
          <input
            type="checkbox"
            className="form-check-input me-2"
            checked={email.checked}
            onChange={() => {
              const updatedEmails = [...selectedGuestEmails];
              updatedEmails[index].checked = !updatedEmails[index].checked;
              setSelectedGuestEmails(updatedEmails);
            }}
          />
          <span className="me-3">{email?.name}</span>
          <span>{email?.email}</span>
        </div>
      ))}
      <div className="d-flex justify-content-end gap-1 mt-3">
        <button
          onClick={() => {
            showModal(false);
          }}
          className="btn btn-sm btn-secondary"
        >
          Close
        </button>
        <button
          onClick={() => {
            sendEmail();
          }}
          disabled={!emailTo}
          className="btn btn-sm btn-success"
        >
          Send
        </button>
      </div>

      {/*   Guest Email
       ********************************************* */}
      <Modal
        size="lg"
        show={showGuestEmailListModal}
        contentClassName="bg-transparent"
        onHide={async () => {
          await refreshFunc();
          setShowGuestEmailListModal(false);
        }}
      >
        <Modal.Body>
          <div
            className="custom-shadow-sm bg-white px-3 py-2"
            style={{ borderRadius: 10 }}
          >
            <MockupGuestList
              selectedDesign={selectedDesign}
              showModal={setShowGuestEmailListModal}
              setSelectedDesign={setSelectedDesign}
              refreshFunc={refreshFunc}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
