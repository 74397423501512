import Spinner from "components/Spinner";
import React, { useEffect, useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function ImgWithSignedUrl({
  path,
  width,
  height,
  className,
  styles,
}) {
  const [url, setUrl] = useState(null);

  const getImgUrl = async () => {
    const { data } = await API.post(
      "/art-approval/get-image",
      { path },
      {
        headers: authHeader(),
      }
    );

    setUrl(data);
    return data;
  };

  useEffect(() => {
    // Reset the URL to null to show the spinner while loading the new image
    setUrl(null);
    getImgUrl();
  }, [path]);

  return (
    <>
      {!url && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Spinner />
          <span>Loading image...</span>
        </div>
      )}
      {url && (
        <img
          style={{ ...styles, minHeight: 300, minWidth: 500 }}
          width={width}
          className={className}
          height={height}
          src={url}
          alt="logo"
        />
      )}
    </>
  );
}
