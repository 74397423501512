import Spinner from "components/Spinner";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

import { setNotification } from "features/Notification/notificationSlice";
import { addUser, editUser } from "../../features/user/userActions";
import { Popover } from "@headlessui/react";

function AddUser({ id, setShowAddUser, setSelectedUser, selectedUser }) {
  const dispatch = useDispatch();
  const isAddMode = !id;

  const dataEntryPermRef = useRef();

  const [locationData, setLocationData] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [rawPermissions, setRawPermissions] = useState([]);
  const [groupPermissions, setGroupPermissions] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);

  const [entryPermDisabled, setEntryPermDisabled] = useState(false);

  const [formData, setFormData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      isAddMode
        ? addUser({
            ...formData,
            locationId: JSON.stringify(selectedLocation),
            permissions: checkedItems,
          })
        : editUser({
            formData: {
              ...formData,
              locationId: JSON.stringify(selectedLocation),
              permissions: checkedItems,
            },
            userId: formData?.id,
          })
    ).then((res) => {
      if (res.type === "user/add/rejected") {
        setError(res.payload);
      } else {
        setShowAddUser(false);
        setSelectedUser(null);
      }
    });
  };

  useEffect(() => {
    dispatch(
      setNotification({
        message: error,
        type: "error",
      })
    );
  }, [error]);

  const getLocations = async () => {
    try {
      const { data } = await API.get("/locations", { headers: authHeader() });
      setLocationData(data);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleLocationChecked = (val) => {
    if (selectedLocation?.includes(val)) {
      setSelectedLocation((prev) => prev.filter((item) => item != val));
    } else {
      setSelectedLocation([...selectedLocation, val]);
    }
  };

  const handleAllLocationChecked = () => {
    if (selectedLocation?.length == 0) {
      setSelectedLocation((prev) => locationData.map((item) => item.id));
    } else {
      setSelectedLocation([]);
    }
  };

  useEffect(() => {
    getLocations();
    API.get("permissions", { headers: authHeader() }).then(({ data }) => {
      setRawPermissions(data);
      const groupedPermNames = groupByFirstWord(data);
      setGroupPermissions(groupedPermNames);
    });

    if (!isAddMode) {
      API.get(`users/${id}`, { headers: authHeader() }).then(({ data }) => {
        setFormData({ ...data });
        setSelectedLocation(
          data?.locationId ? JSON.parse(data?.locationId) : []
        );
        setCheckedItems(
          typeof data.permissions === "string"
            ? JSON.parse(data.permissions)
            : data.permissions
        );
        setIsLoading(false);
      });
    }
  }, []);

  const handleCheckAll = (e, value) => {
    let group = value?.filter((item) => item != "work_orders_dataEntry");
    if (e.target.id.split("-")[1] == "quotations") {
      setCheckedItems((prev) =>
        prev.filter((item) => item != "work_orders_dataEntry")
      );
    }
    if (e.target.checked) {
      setCheckedItems((prevArray) => [...prevArray, ...group]);
    } else {
      setCheckedItems(checkedItems.filter((item) => !group?.includes(item)));
    }
  };

  const handleCheckbox = (e, value) => {
    if (
      value == "work_orders_add" ||
      value == "work_orders_update" ||
      value == "work_orders_delete"
    ) {
      setCheckedItems((prev) =>
        prev.filter((item) => item != "work_orders_dataEntry")
      );
    }
    if (e.target.checked && !checkedItems.includes(value)) {
      setCheckedItems((prevArray) => [...prevArray, value]);
    } else {
      setCheckedItems(checkedItems.filter((item) => !value.includes(item)));
    }
  };

  const groupByFirstWord = (arr) => {
    const groups = {};
    arr.forEach(({ permName }) => {
      const [groupName] = permName.split("_");
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(permName);
    });
    return groups;
  };

  useEffect(() => {
    let disabled = false;
    checkedItems?.map((item) => {
      if (
        item.includes("work_orders_add") ||
        item.includes("work_orders_update") ||
        item.includes("work_orders_delete")
      ) {
        disabled = true;
      } else {
        disabled = false;
      }
      setEntryPermDisabled(disabled);
    });
  }, [checkedItems]);

  if (isLoading && !isAddMode) return <Spinner />;

  return (
    <>
      <div className="row">
        <div className="col-12 mb-2">
          <h3>{isAddMode ? "Add" : "Edit"} User</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="">
            <form onSubmit={handleSubmit}>
              <div className="row">
                {/*   Form Inputs
                 ********************************************* */}
                <div className="col-6">
                  <div className="row mb-2">
                    <div className="col-6">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        required
                        className={`form-control ${
                          formData?.firstName ? "" : "border-danger"
                        }`}
                        placeholder="Enter First Name"
                        value={formData?.firstName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            firstName: e.target.value,
                          })
                        }
                      />
                      <small className="text-danger">
                        {formData?.firstName == null ||
                        formData?.firstName === undefined ||
                        formData?.firstName === ""
                          ? "First Name is required"
                          : ""}
                      </small>
                    </div>
                    <div className="col-6">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        required
                        className={`form-control ${
                          formData?.lastName ? "" : "border-danger"
                        }`}
                        placeholder="Enter Last Name"
                        value={formData?.lastName}
                        onChange={(e) =>
                          setFormData({ ...formData, lastName: e.target.value })
                        }
                      />
                      <small className="text-danger">
                        {formData?.lastName == null ||
                        formData?.lastName === undefined ||
                        formData?.lastName === ""
                          ? "Last Name is required"
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6">
                      <label className="form-label">Phone</label>
                      <input
                        placeholder="Enter Phone"
                        type="tel"
                        name="phone"
                        className={`form-control ${
                          formData?.phone == null ||
                          formData?.phone === undefined ||
                          formData?.phone === ""
                            ? "border-danger"
                            : ""
                        }`}
                        required
                        value={formData?.phone}
                        onChange={(e) =>
                          setFormData({ ...formData, phone: e.target.value })
                        }
                      />
                      <small className="text-danger">
                        {formData?.phone == null ||
                        formData?.phone === undefined ||
                        formData?.phone === ""
                          ? "Phone is required"
                          : ""}
                      </small>
                    </div>
                    <div className="col-6">
                      <label className="form-label">Extention</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Extention"
                        value={formData?.phoneExt}
                        onChange={(e) =>
                          setFormData({ ...formData, phoneExt: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      required
                      className={`form-control ${
                        formData?.email ? "" : "border-danger"
                      }`}
                      placeholder="Enter Email"
                      value={formData?.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                    <small className="text-danger">
                      {formData?.email == null ||
                      formData?.email === undefined ||
                      formData?.email === ""
                        ? "Email is required"
                        : ""}
                    </small>
                  </div>
                  <div className="mb-2 ">
                    <label className="form-label">Password</label>
                    <div className="input-group">
                      <input
                        type={showPass ? "text" : "password"}
                        required={isAddMode}
                        className={`form-control ${
                          formData?.email ? "" : "border-danger"
                        }`}
                        placeholder="Enter password"
                        onChange={(e) =>
                          setFormData({ ...formData, password: e.target.value })
                        }
                      />
                      <span
                        onClick={() => setShowPass(!showPass)}
                        className="input-group-text input-group-text"
                      >
                        <i
                          className={`mdi mdi-eye${
                            showPass ? "" : "-off"
                          } fs-4`}
                        ></i>
                      </span>
                    </div>
                    <small className="text-danger">
                      {(formData?.password == null ||
                        formData?.password === undefined ||
                        formData?.password === "") &&
                      isAddMode
                        ? "password is required"
                        : ""}
                    </small>
                  </div>
                  <div className="mb-2 ">
                    <label className="form-label">Default Location</label>
                    <div className="input-group">
                      <select
                        value={formData?.defaultLocation || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            defaultLocation: e.target.value,
                          })
                        }
                        className="form-control"
                        required
                      >
                        <option value={""} disabled>
                          Select default location
                        </option>
                        {locationData?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.locationName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <hr />
                  <div className="mb-3 mt-3">
                    <h3 className="">Add email account ( Gmail ) </h3>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      referrerPolicy="no-referrer"
                      href="https://myaccount.google.com/apppasswords?continue=https://myaccount.google.com/data-and-privacy?hl%3Den%26rapt%3DAEjHL4N-_-wqowUOflRj8zsWBHL93KyUfugluv0j26WMDbVjZEyajo8p5u5lV5u92pylN3k8u3XYEaG_8sR-5AvtsqDp5s1ezPxGXSrsfZ0TRSsAWLzqC7o%26utm_source%3DOGB%26utm_medium%3Dact%26gar%3DWzI0Nl0&pli=1&rapt=AEjHL4OYovdomiZLn4-l-Jfd38P2Dm1untWoxDqkS96MKXuQQHJMrU3kcGmmLc4mH9-qGBpfHarlQUPd1ozfKg9MJQvez1jIhWXqvO-VQaloqWJLsQPzQqw"
                    >
                      Click here to get your gmail smtp password
                    </a>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="smtpName" className="form-label">
                      Smtp Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="smtpName"
                      placeholder="Enter smtp name"
                      value={formData?.smtpName || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, smtpName: e.target.value })
                      }
                      name="smtpName"
                    />
                  </div>{" "}
                  <div className="mb-3">
                    <label htmlFor="smtpEmail" className="form-label">
                      Smtp email
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="smtpEmail"
                      placeholder="Enter smtp email"
                      name="smtpEmail"
                      value={formData?.smtpEmail || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, smtpEmail: e.target.value })
                      }
                    />
                  </div>{" "}
                  <div className="mb-3">
                    <label htmlFor="smtpPassword" className="form-label">
                      Smtp password
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      id="smtpPassword"
                      placeholder="Enter smtp password"
                      name="smtpPassword"
                      value={formData?.smtpPassword || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          smtpPassword: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                {/*   Permissions
                 ********************************************* */}
                {selectedUser && selectedUser.role !== "admin" && (
                  <div className="col-6">
                    <label htmlFor="projectname" className="mb-0">
                      Assign Permissions
                    </label>
                    <p className="text-muted font-14">
                      Assign Module Permission to user
                    </p>

                    {/* location*/}
                    <div className="mb-3">
                      <div className="">
                        <div className="form-check">
                          <input
                            checked={
                              selectedLocation?.length === locationData?.length
                            }
                            onChange={() => handleAllLocationChecked()}
                            type="checkbox"
                            className="form-check-input"
                            id="customCheck1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheck1"
                          >
                            Location
                          </label>
                        </div>
                      </div>
                      <div className=" d-flex mt-2">
                        {locationData?.map((location) => (
                          <div key={location.id} className="form-check mx-3">
                            <input
                              checked={selectedLocation?.includes(location.id)}
                              onChange={() =>
                                handleLocationChecked(location.id)
                              }
                              type="checkbox"
                              className="form-check-input"
                              id="customCheck1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck1"
                            >
                              {location?.locationName}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                    {Object.entries(groupPermissions).map(
                      ([groupName, groupPerms]) => (
                        <div className="" key={groupName}>
                          <div className="">
                            <input
                              className={`mt-2${
                                checkedItems?.some((r) =>
                                  groupPerms.includes(r)
                                )
                                  ? " intermediate"
                                  : ""
                              }`}
                              checked={groupPerms?.every((i) => {
                                if (i == "work_orders_dataEntry") {
                                  return true;
                                } else {
                                  return checkedItems?.includes(i);
                                }
                              })}
                              onChange={(e) => handleCheckAll(e, groupPerms)}
                              type="checkbox"
                              id={`parent-${groupName}`}
                            />
                            <label className="ms-1">
                              {groupName.charAt(0).toUpperCase() +
                                groupName.slice(1)}
                            </label>
                          </div>
                          {groupPerms.map((perm, i) => {
                            const currentPerm = rawPermissions.filter(
                              (item) => {
                                return item.permName === perm;
                              }
                            );

                            return (
                              <div
                                key={i}
                                className="form-check form-check-inline mt-2 mb-2"
                              >
                                {perm === "work_orders_dataEntry" ? (
                                  <>
                                    <input
                                      checked={
                                        checkedItems.includes(perm) &&
                                        !entryPermDisabled
                                      }
                                      onChange={(e) => handleCheckbox(e, perm)}
                                      disabled={entryPermDisabled}
                                      ref={dataEntryPermRef}
                                      type="checkbox"
                                      id={`child-${perm}`}
                                    />
                                    <label className="ms-1">
                                      {currentPerm[0].permDescription}
                                    </label>
                                  </>
                                ) : (
                                  <>
                                    <input
                                      checked={checkedItems.includes(perm)}
                                      onChange={(e) => handleCheckbox(e, perm)}
                                      type="checkbox"
                                      id={`child-${perm}`}
                                    />
                                    <label className="ms-1">
                                      {currentPerm[0].permDescription}
                                    </label>
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )
                    )}
                  </div>
                )}
                {/*   Buttons
                 ********************************************* */}
                <div className="d-flex mt-3">
                  <div
                    type="button"
                    className="btn me-2 btn-secondary ms-auto"
                    onClick={() => {
                      setShowAddUser(false);
                      setSelectedUser(null);
                    }}
                  >
                    Cancel
                  </div>

                  <button type="submit" className="btn btn-primary">
                    <i className="mdi mdi-account-check-outline me-1"></i>
                    {isAddMode ? "Add" : "Update"} user
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddUser;
