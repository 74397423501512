import WOPdfButton from "components/Pdfs/WOPdf/WOPdfButton";
import ImgWithSignedUrl from "components/SignedUrl/ImgWithSignedUrl";
import Spinner from "components/Spinner";
import WOOrderEmail from "components/WorkOrderOrderEmail/WOOrderEmail";
import useWorkOrder from "features/Contexts/WorkOrderContext";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import QuickViewProducts from "./QuickViewProducts";
import QuickViewProductSizes from "./QuickViewProductSize";

export default function QuickViewWorkOrder({
  btnClasses,
  hideCustomerViewBtn,
  filterBy,
  selectedId,
  workOrdersList,
  refreshFunc,
  active,
  show,
  setShow,
}) {
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  /*   All States
   ********************************************* */

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentId, setCurrentId] = useState(null);
  const [selected, setSelected] = useState(null);
  const [allWOData, setAllWOData] = useState([]);

  const [productsList, setProductsList] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [isReady, setIsReady] = useState(false);

  const [productTypesList, setProductTypesList] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState(null);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };

  const loadingOff = () => {
    setLoading(false);
  };

  // Go to previous WorkOrder
  const previousWorkOrder = () => {
    const currentIndex = allWOData.findIndex((wo) => wo.woId === currentId);
    if (currentIndex > 0) {
      setCurrentId(allWOData[currentIndex - 1]?.woId);
      // setSelected(allWOData[currentIndex - 1]);
    }
  };

  // Go to next WorkOrder
  const nextWorkOrder = () => {
    const currentIndex = allWOData.findIndex((wo) => wo.woId === currentId);
    if (currentIndex < allWOData.length + 1) {
      setCurrentId(allWOData[currentIndex + 1]?.woId);
      // setSelected(allWOData[currentIndex + 1]);
    }
  };

  /**
   * Updates Status of Work Order.
   * Does not updates the work order list so going back and forth will not update the list.
   */
  const handleWOStatusChange = async (e) => {
    let WOStatus = e.target.value;

    let newAllWOData = allWOData.map((item) => {
      if (item.woId === selected.woId) {
        return { ...item, WOStatus };
      } else {
        return item;
      }
    });
    setAllWOData(newAllWOData);
    loadingOn(true);
    try {
      const { data } = await API.patch(
        "/work-orders",
        {
          data: { WOStatus: WOStatus },
          id: selected.id,
        },
        { headers: authHeader() }
      );

      if (data.message.type === "success") {
        loadingOff();
        return;
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
    }
  };

  const { clearWorkOrderData, setCustomerData } = useWorkOrder();

  const handleViewCustomer = () => {
    setCustomerData({ id: selected?.customerId });
    clearWorkOrderData();

    navigate(`/customer/details/${selected?.customer?.customerId}`);
  };

  const getAllProductTypes = async () => {
    try {
      const { data } = await API.get("/productTypes", {
        headers: authHeader(),
      });
      setProductTypesList(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleProductTypeFilter = (item) => {
    setSelectedProductType(item);
    if (item === null) {
      setProductsList(selected?.WOProducts);
      setSelectedProduct(selected?.WOProducts[0]);
      return;
    }
    let filteredList = selected?.WOProducts.filter((product) => {
      return product.productType === item.productTypes;
    });

    setProductsList(filteredList);
    setSelectedProduct(filteredList[0]);
  };

  const UpdateProductReady = async ({ ready }) => {
    try {
      loadingOn(true);

      const { data } = await API.patch(
        "/wo-products/ready-status",
        {
          WOId: selected?.id,
          productType: selectedProductType?.productTypes,
          isReady: ready,
        },
        { headers: authHeader() }
      );

      if (data?.success) {
        let updatedProduct = {
          ...selectedProduct,
          isReady: ready,
        };
        // Get current index of workOrder in allWOData
        let currentWOIndex = allWOData?.findIndex(
          (item) => item.id === selected?.id
        );
        // Get current index of product in allWOData

        let currentProductIndex = allWOData[
          currentWOIndex
        ]?.WOProducts?.findIndex((item) => item.id === selectedProduct.id);
        // Update the product in allWOData
        let updatedWOData = JSON.parse(JSON.stringify(allWOData));
        updatedWOData[currentWOIndex].WOProducts[currentProductIndex] =
          updatedProduct;
        setAllWOData((prev) => updatedWOData);

        dispatch(
          setNotification({
            message: data.message,
            type: "success",
          })
        );
      }

      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /**
   * @param(selectedType) - Selected Product Type
   * Counts the number products of selected type and total quantity of that type.
   * @returns(Number,total quantity) - Number of products of selected type.
   */
  const countProducts = (selectedType) => {
    if (!selectedType) {
      const summary = selected?.WOProducts?.reduce(
        (acc, item) => {
          acc.totalQuantity += item.totalQuantity;
          acc.count += 1;
          return acc;
        },
        { totalQuantity: 0, count: 0 }
      );

      return summary;
    }

    const summary = selected?.WOProducts?.reduce(
      (acc, item) => {
        if (item.productType === selectedType.productTypes) {
          acc.totalQuantity += item.totalQuantity;
          acc.count += 1;
        }
        return acc;
      },
      { totalQuantity: 0, count: 0 }
    );

    return summary;
  };

  /*   All UseEffects
   ********************************************* */

  // Set Selected Work Order
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (currentId && allWOData) {
        let _selected = allWOData.find((wo) => wo.woId === parseInt(currentId));

        // sort WOProducts by sortId
        if (_selected?.WOProducts) {
          _selected.WOProducts.sort((a, b) => {
            return a.sortId - b.sortId;
          });
        }

        setSelected((prev) => _selected);
      }
    }, 50);
    return () => clearTimeout(timeout);
  }, [allWOData, currentId]);

  useEffect(() => {
    if (selectedId) {
      setCurrentId(selectedId);
    }
  }, [selectedId]);

  // Set All Work Orders Data
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (workOrdersList) {
        setAllWOData(workOrdersList);
      }
    }, 50);
    return () => clearTimeout(timeout);
  }, [workOrdersList]);

  // Set Products List and Selected Product
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (selected) {
        setSelectedProductType((prev) => null);

        const _productsList = selected?.WOProducts;
        setProductsList(_productsList);

        if (_productsList && _productsList?.length > 0) {
          let _selectedProduct = _productsList[0];
          setSelectedProduct(_selectedProduct);
          setIsReady(_selectedProduct?.isReady);
        }

        if (
          productTypesList &&
          productTypesList?.length > 0 &&
          filterBy &&
          // filterBy !== "READY TO BILL" &&
          filterBy !== "Pending"
        ) {
          const _selectedProductType = productTypesList.find(
            (item) => item.productTypes === filterBy
          );

          if (_selectedProductType) {
            handleProductTypeFilter(_selectedProductType);
          } else {
            // If no matching product type is found, reset the filter
            handleProductTypeFilter(null);
          }
        }
      }
    }, 50);
    return () => clearTimeout(timeout);
  }, [selected, productTypesList]);

  useEffect(() => {
    if (selectedProduct) {
      setIsReady(selectedProduct?.isReady);
    }
  }, [selectedProduct]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      getAllProductTypes();
    }, 50);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  if (!selectedId || !allWOData) return null;
  return (
    <div>
      {show === undefined && (
        <button
          className={`${btnClasses}`}
          onClick={() => {
            setShowModal(true);
          }}
        >
          Quick View
        </button>
      )}

      <Modal
        show={showModal}
        size="xl"
        onHide={() => {
          setShowModal(false);
          setShow && setShow(false);
        }}
        contentClassName="bg-transparent"
      >
        <Modal.Body className="bg-light rounded-3 px-3 py-2">
          <>
            {loading && <Spinner />}

            {/* Header Section */}
            <div className="mx- 1 me-2 d-flex justify-content-between">
              <h4 className="d-flex mb-0 flex-column">
                <div className="d-flex">
                  {(user?.permissions?.includes("work_orders_add") ||
                    user?.permissions?.includes("work_orders_update") ||
                    user?.permissions?.includes("work_orders_delete") ||
                    user?.permissions?.includes("work_orders_dataEntry") ||
                    user?.roles === "admin") && (
                    <button
                      className="btn btn-sm btn-success me-2 py-0 px-1 mb-1"
                      onClick={() => {
                        // let status =
                        //   selected?.WOStatus === "pending" ||
                        //   selected?.WOStatus === "processing"
                        //     ? "pending-processing"
                        //     : selected.WOStatus;
                        navigate(
                          `/work-order/details/${selected.WOStatus}/${selected?.woId}?page=1`
                        );
                      }}
                    >
                      View Work Order
                    </button>
                  )}
                  <div>
                    WO ID {selected?.woId} -
                    {selected?.jobName?.length <= 20
                      ? selected?.jobName
                      : `${selected?.jobName?.slice(0, 20)}..`}
                  </div>
                </div>
                <div className="d-flex">
                  {(user?.permissions?.includes("work_orders_add") ||
                    user?.permissions?.includes("work_orders_update") ||
                    user?.permissions?.includes("work_orders_delete") ||
                    user?.permissions?.includes("work_orders_dataEntry") ||
                    user?.roles === "admin") &&
                    !hideCustomerViewBtn && (
                      <button
                        onClick={handleViewCustomer}
                        className="btn btn-sm btn-info me-2 py-0 px-1 mb-1 "
                      >
                        View Customer
                      </button>
                    )}
                  <div className="">
                    Customer : {selected?.customer?.customerId} -{" "}
                    {selected?.customerName.length <= 20
                      ? selected?.customerName
                      : `${selected?.customerName.slice(0, 20)}..`}
                  </div>
                </div>
              </h4>
              <div className="d-flex justify-content-end align-items-center flex-grow-1 ">
                {selectedProduct && selectedProductType && (
                  <>
                    <div className="d-flex align-items-center gap-2 me-2">
                      <label className="col-5"> Ready?</label>
                      <div className="col-7 ">
                        <input
                          onChange={(e) => {
                            setIsReady(e.target.checked);
                            UpdateProductReady({
                              id: selectedProduct?.id,
                              ready: e.target.checked,
                            });
                          }}
                          checked={isReady}
                          type="checkbox"
                          id="switch1"
                          data-switch="success"
                        />
                        <label
                          htmlFor="switch1"
                          data-on-label="Yes"
                          data-off-label="No"
                        ></label>
                      </div>
                    </div>
                  </>
                )}
                <h5 className="me-1">WO Status</h5>
                <div className="me-1 ">
                  {user?.roles === "customer" ? (
                    <input
                      className="form-control"
                      readOnly
                      value={selected?.WOStatus}
                    />
                  ) : (
                    <select
                      value={selected?.WOStatus}
                      onChange={handleWOStatusChange}
                      className="form-select"
                    >
                      <option value="pending">Pending</option>
                      <option value="processing">Processing</option>
                      <option value="completed">Completed</option>
                    </select>
                  )}
                </div>
                <div className="d-flex gap-1">
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-25">
                      <Dropdown.Item
                        as="div"
                        className="p-0 m-0"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {allWOData && selected && (
                          <WOPdfButton
                            filterBy={filterBy}
                            btnTitle={"Print Pdf"}
                            btnClasses={"btn w-100"}
                            btnColor={"text-start btn"}
                            selectedWO={selected}
                          />
                        )}
                      </Dropdown.Item>
                      {selected && user?.roles !== "customer" && !filterBy && (
                        <Dropdown.Item
                          as="div"
                          className="p-0 m-0"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <WOOrderEmail
                            btnClass={"btn w-100"}
                            btnColor={"text-start btn"}
                            btnTitle={"Email"}
                            selectedWorkOrder={selected}
                            wOProductsData={selected?.WOProducts}
                          />
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>

                  <button
                    onClick={() => {
                      previousWorkOrder();
                    }}
                    disabled={allWOData?.length === 0 && loading}
                    className={"btn btn-success btn-sm p-0 px-1 "}
                  >
                    <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
                  </button>
                  <button
                    onClick={() => {
                      nextWorkOrder();
                    }}
                    disabled={allWOData?.length === 0 && loading}
                    className={"btn btn-success btn-sm p-0  px-1"}
                  >
                    <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={async () => {
                      setShowModal(false);
                      setShow && setShow(false);
                      refreshFunc && (await refreshFunc(active));
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2" />

            {/* List of Filters buttons for product by productType */}
            <div className="my-1">
              <div className="d-flex gap-1">
                <button
                  onClick={() => {
                    handleProductTypeFilter(null);
                  }}
                  className={`btn btn-sm ${
                    !selectedProductType ? "btn-primary" : "btn-outline-primary"
                  }`}
                >
                  All ({selected?.WOProducts?.length})
                </button>
                {productTypesList &&
                  productTypesList?.map((item) => {
                    if (
                      countProducts(item) &&
                      countProducts(item)["count"] === 0
                    )
                      return null;
                    return (
                      <button
                        key={item.id}
                        onClick={() => {
                          handleProductTypeFilter(item);
                        }}
                        className={`btn btn-sm ${
                          selectedProductType?.id === item.id
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                      >
                        {item?.productTypes} (
                        {countProducts(item) &&
                          `${countProducts(item)["count"]} - ${
                            countProducts(item)["totalQuantity"]
                          }`}
                        )
                      </button>
                    );
                  })}
              </div>
            </div>

            {/*   Top Row ( Products & Sizes )
             ********************************************* */}
            <div className="row ">
              {/* Products Info */}
              <div style={{ padding: "5px 5px" }} className="col-md-6 mx-0 ">
                <div
                  style={{ paddingBottom: "1.1rem" }}
                  className="bg-white rounded-3 h-100 shadow px-3 pt-2"
                >
                  {selected && selectedProduct && productsList ? (
                    <QuickViewProducts
                      user={user}
                      selected={selected}
                      selectedProduct={selectedProduct}
                      setSelectedProduct={setSelectedProduct}
                      productsList={productsList}
                      setProductsList={setProductsList}
                      productTypesList={productTypesList}
                      allWOData={allWOData}
                      setAllWOData={setAllWOData}
                    />
                  ) : (
                    <div
                      style={{
                        minHeight: 300,
                        minWidth: 300,
                      }}
                      className="h3 flex-center text-muted  text-center my-auto"
                    >
                      No Products
                    </div>
                  )}
                </div>
              </div>
              {/* Sizes Info */}
              <div style={{ padding: "5px 5px" }} className="col-md-6 mx-0">
                <div className="">
                  <QuickViewProductSizes selectedProduct={selectedProduct} />
                </div>
              </div>
            </div>
            {/*   Top Row ( Img Preview & Notes )
             ********************************************* */}
            <div className="row ">
              {/* Product Image */}
              <div style={{ padding: "5px 5px" }} className="col-md-6 mx-0">
                <div
                  style={{ paddingBottom: "1.1rem" }}
                  className="bg-white rounded-3 shadow px-3 pt-2"
                >
                  {!loading ? (
                    <div>
                      {productsList?.length && selectedProduct?.productImage ? (
                        <ImgWithSignedUrl
                          height={300}
                          width={500}
                          path={selectedProduct?.productImage}
                        />
                      ) : (
                        <div
                          style={{
                            minHeight: 300,
                            minWidth: 300,
                          }}
                          className="h3 flex-center text-muted  text-center my-auto"
                        >
                          NO Image{" "}
                        </div>
                      )}
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
              {/* Notes */}
              <div style={{ padding: "5px 5px" }} className="col-md-6 mx-0">
                <div className="bg-white rounded-3 shadow-sm p-1">
                  <div className="">
                    <ReactQuill
                      modules={modules}
                      theme="snow"
                      value={selectedProduct?.notes}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
}
