import axios from "axios";
import TableOnly from "components/DataTable/TableOnly";
import Pagination from "components/Pagination/Pagination";
import SearchComp from "components/SearchComp/SearchComp";
import Spinner from "components/Spinner";
import useWorkOrder from "features/Contexts/WorkOrderContext";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import AdvanceSearchForm from "./AdvanceSearch/AdvanceSearchForm";
import CustomerAdd from "./CustomerAdd";
import ImportCustomersExcel from "./ImportCustomersExcel";
import useCustomer from "features/Contexts/CustomerContext";
import Table from "components/DataTable/Table";

function Customers() {
  const user = useSelector((state) => state.user?.userInfo);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { customerContextData } = useWorkOrder();
  const { advanceSearchParams, setAdvanceSearchParams } = useCustomer();

  /*   All States
   ********************************************* */

  const [allCustomersData, setAllCustomersData] = useState(null);

  const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState(false);

  const [filterBy, setFilterBy] = useState(null);

  const [searchResult, setSearchResult] = useState(null);

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({
    locationName: "Filter By Location",
  });

  const [showAddModal, setShowAddModal] = useState(false);
  const [advanceSearchModal, setAdvanceSearchModal] = useState(false);
  const [showImportForm, setShowImportForm] = useState(false);

  /*   All Function
   ********************************************* */

  const getAllCustomersData = async (_page, _filterBy, query, location) => {
    try {
      setLoading(true);

      let body = {
        page: _page,
        filterBy: _filterBy,
        query,
        location,
      };
      const { data } = await API.post("/customers", body, {
        headers: authHeader(),
      });

      setAllCustomersData(data?.result);
      setTotalPages(data?.pages);
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  const downloadSampleFile = () => {
    axios
      .post(`${process.env.REACT_APP_API_URI}/aws/images`, {
        key: "kms/customer/Customers Data - Sample.xlsx",
      })
      .then((response) => {
        const url = response.data[0];
        const link = document.createElement("a");
        link.href = url;
        link.download = "Customers Data - Sample.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log("Error downloading file:", error);
      });
  };

  const getAllLocations = async () => {
    try {
      setLoading(true);
      const { data } = await API.get("/locations", { headers: authHeader() });
      setLocations(data);
      setLoading(false);
    } catch (err) {
      alert(err.message);
      setLoading(false);
    }
  };

  /*   Table Functions
   ********************************************* */
  const btn1 = () => (
    <div>
      {(user?.permissions?.includes("customer_add") ||
        user?.roles === "admin") && (
        <div className="d-flex justify-content-center align-items-center gap-1">
          <Dropdown className="">
            <Dropdown.Toggle
              variant="outline-info"
              className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end rounded-start"
              id="dropdown-basic2"
            >
              {selectedLocation?.locationName}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={(e) => {
                  setSelectedLocation({ locationName: "Filter By Location" });
                  updateSearchParams("location", "no-location");
                }}
              >
                All
              </Dropdown.Item>
              {locations?.map((item) => (
                <Dropdown.Item
                  key={item.id}
                  onClick={(e) => {
                    setSelectedLocation(item);
                    updateSearchParams("location", item?.id);
                  }}
                >
                  {item?.locationName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {/* FilterBy */}
          <Dropdown className="">
            <Dropdown.Toggle
              variant="outline-info"
              className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end rounded-start"
              id="dropdown-basic2"
            >
              {filterBy
                ? filterBy.toUpperCase().replaceAll("_", " ")
                : "Filter by All"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setFilterBy(null);
                  updateSearchParams("filterBy");
                }}
              >
                Filter by All
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setFilterBy("customerName");
                  updateSearchParams("filterBy", "customerName");
                }}
              >
                Customer Name
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setFilterBy("accountPayableContact");
                  updateSearchParams("filterBy", "accountPayableContact");
                }}
              >
                Payable Name
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setFilterBy("accountPayablePhone");
                  updateSearchParams("filterBy", "accountPayablePhone");
                }}
              >
                Payable Phone
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setFilterBy("accountPayableEmail");
                  updateSearchParams("filterBy", "accountPayableEmail");
                }}
              >
                Payable Email
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setFilterBy("customerType");
                  updateSearchParams("filterBy", "customerType");
                }}
              >
                Customer Type
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <button
            onClick={() => setAdvanceSearchModal(true)}
            type="button"
            className="btn px-2 btn-success"
          >
            Advance Search
          </button>
          <button
            onClick={() => setShowImportForm(true)}
            type="button"
            className="btn px-2 btn-success"
          >
            IMPORT FILE
          </button>
          <button
            onClick={() => downloadSampleFile()}
            type="button"
            className="btn px-2 btn-info"
          >
            <i className="mdi mdi-download"></i> SAMPLE FILE
          </button>
          <button
            onClick={() => setShowAddModal(true)}
            type="button"
            className="btn px-2 btn-primary"
          >
            ADD CUSTOMER
          </button>
        </div>
      )}
    </div>
  );

  // Clear Advance Search
  const btnAS = () => (
    <>
      <button
        className="btn btn-danger  ms-1"
        onClick={(e) => {
          setSearchResult(null);
          setAdvanceSearchParams(null);
        }}
      >
        Clear Advance Search
      </button>
    </>
  );

  const table_head = [
    { name: "ID", value: "customerId" },
    { name: "Customer Name", value: "customerName" },
    { name: "Payable Account Name", value: "accountPayableContact" },
    { name: "Payable Account Phone", value: "accountPayablePhone" },
    { name: "Payable Account Email", value: "accountPayableEmail" },
    { name: "location", value: "location?.name" },
    { name: "Actions", value: "Actions" },
  ];

  const table_S_head = [
    "ID",
    "Customer Name",
    "Payable Account Name",
    "Payable Account Phone",
    "Payable Account Email",
    "location",
    "Actions",
  ];

  const table_body = (item, index) => (
    <tr className="" key={item.id}>
      <td className="py-1 align-middle">{item?.customerId}</td>
      <td
        className="py-1 align-middle"
        onDoubleClick={() => {
          navigate(
            `/customer/details/${item?.customerId}?${searchParams.toString()}`
          );
        }}
      >
        {item?.customerName}
      </td>
      <td className="py-1 align-middle">{item?.accountPayableContact}</td>
      <td className="py-1 align-middle">{item?.accountPayablePhone} </td>
      <td className="py-1 align-middle">{item?.accountPayableEmail}</td>
      <td className="py-1 align-middle">{item?.location?.name}</td>
      <td className="table-action py-1 align-middle">
        <button
          onClick={() => {
            navigate(
              `/customer/details/${item?.customerId}?${searchParams.toString()}`
            );
          }}
          className={`btn btn-sm bg-success text-white mx-2 `}
        >
          Details
        </button>
      </td>
    </tr>
  );

  const updateSearchParams = (key, value) => {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
    setSearchParams(searchParams);
  };

  /*   All UseEffect
   ********************************************* */

  useEffect(() => {
    if (!customerContextData) {
      getAllLocations();
    }
  }, []);

  useEffect(() => {
    let page = searchParams.get("page");
    let _page = page ? page : 1;
    let _filterBy = searchParams.get("filterBy");
    let query = searchParams.get("query");
    let location = searchParams.get("location");

    if (location && locations) {
      if (location === "no-location" || location === null) {
        setSelectedLocation({ locationName: "Filter By Location" });
      } else {
        let _selectedLocation = locations.find(
          (item) => item.id === parseInt(location)
        );
        setSelectedLocation(_selectedLocation);
      }

      let tid = setTimeout(() => {
        getAllCustomersData(_page, _filterBy, query, location);
      }, 100);
      return () => {
        clearTimeout(tid);
      };
    } else {
      if (user && user?.defaultLocation && locations) {
        let _selectedLocation = locations?.find(
          (item) => item.id === user.defaultLocation
        );
        setSelectedLocation(_selectedLocation);
        updateSearchParams("location", _selectedLocation?.id);
      } else {
        updateSearchParams("location", "no-default-location");
        setSelectedLocation("no-default-location");
      }
    }
  }, [searchParams, user, locations]);

  // useEffect(() => {
  //   if (user && user?.defaultLocation && locations) {
  //     let _selectedLocation = locations?.find(
  //       (item) => item.id === user.defaultLocation
  //     );
  //     setSelectedLocation(_selectedLocation);
  //     updateSearchParams("location", _selectedLocation?.id);
  //   } else {
  //     updateSearchParams("location", "no-default-location");
  //     setSelectedLocation("no-default-location");
  //   }
  // }, [user, locations]);

  if (!allCustomersData) return <Spinner />;
  return (
    <div className="row">
      {loading && <Spinner />}

      <div className="col-12">
        <div className="d-flex ">
          {/*    Table Card
           ********************************************* */}
          <div
            className=" pb-2 flex-grow-1 rounded d-flex"
            style={{ marginLeft: 260 }}
          >
            {allCustomersData && !advanceSearchParams && !searchResult && (
              <>
                <div className=" px-3 py-2 pb-2 horizontal-scrollable py-4 d-flex flex-column flex-grow-1">
                  <div className="d-flex justify-content-end align-items-center mb-3">
                    <div className="me-1">{btn1()}</div>
                    <SearchComp
                      setLoading={setLoading}
                      apiPath={`/customer/?${
                        filterBy ? "filterBy=" + filterBy + "&" : ""
                      }${
                        selectedLocation?.id
                          ? "location=" + selectedLocation.id + "&"
                          : "location=no-location&"
                      }`}
                    />
                  </div>

                  <TableOnly
                    table_head={table_head}
                    table_body={table_body}
                    table_data={allCustomersData}
                  />

                  <Pagination
                    totalPages={totalPages}
                    apiPath={`/customer/?${
                      filterBy ? "filterBy=" + filterBy + "&" : ""
                    }${
                      selectedLocation?.id
                        ? "location=" + selectedLocation.id + "&"
                        : "location=no-location&"
                    }page=`}
                    currPage={parseInt(searchParams.get("page")) || 1}
                  />
                </div>
              </>
            )}

            {advanceSearchParams && searchResult && (
              <>
                <div className=" px-3 py-2 pb-2 horizontal-scrollable py-4 d-flex flex-column flex-grow-1">
                  <Table
                    btn1={btnAS}
                    table_head={table_S_head}
                    table_body={table_body}
                    table_data={searchResult}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/*   Add Modal
       ********************************************* */}
      <CustomerAdd
        customersData={allCustomersData}
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
      />

      {/*   Import Excel Modal
       ********************************************* */}
      {allCustomersData && (
        <Modal
          size="lg"
          contentClassName="bg-transparent"
          show={showImportForm}
          onHide={() => setShowImportForm(false)}
        >
          <Modal.Body
            className="bg-white shadow p-3"
            style={{ borderRadius: 15 }}
          >
            <ImportCustomersExcel
              allCustomersData={allCustomersData}
              refreshFunc={getAllCustomersData}
              setShowImportForm={setShowImportForm}
            />
          </Modal.Body>
        </Modal>
      )}
      {/*   Advance Customer Search
       ********************************************* */}
      <Modal
        size="xl"
        contentClassName="bg-transparent"
        show={advanceSearchModal}
        onHide={() => setAdvanceSearchModal(false)}
      >
        <Modal.Body
          className="bg-light shadow p-3"
          style={{ borderRadius: 15 }}
        >
          <AdvanceSearchForm
            showModal={setAdvanceSearchModal}
            setSearchResult={setSearchResult}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Customers;
