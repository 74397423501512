import CustomerContactSelect from "components/Customer/CustomerContactSelect";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { set } from "react-hook-form";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function MockupGuestList({
  selectedDesign,
  refreshFunc,
  showModal,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [guestEmailList, setGuestEmailList] = useState([]);
  const [showContactSelectModal, setShowContactSelectModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const [selectedEmail, setSelectedEmail] = useState(null);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const getGuestLists = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/mockup-guests/" + selectedDesign.id, {
        headers: authHeader(),
      });
      if (data?.success) {
        setGuestEmailList(data?.data);
      }
      if (!data?.success) {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }

      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const addGuestEmail = async (e) => {
    e.preventDefault();

    try {
      loadingOn();
      const { data } = await API.post(
        "/mockup-guest",
        {
          ...formData,
          companyId: selectedDesign?.companyId,
          customerId: selectedDesign?.customerId,
          mockupId: selectedDesign.id,
        },
        {
          headers: authHeader(),
        }
      );
      if (data?.success) {
        setGuestEmailList(data?.data);
        setFormData({});
      }
      if (!data?.success) {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const deleteGuest = async (id, mockupId) => {
    try {
      loadingOn();
      const { data } = await API.delete(`/mockup-guest/${id}/${mockupId}`, {
        headers: authHeader(),
      });
      if (data?.success) {
        setGuestEmailList(data?.data);
      }
      if (!data?.success) {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const updateGuestEmail = async (e) => {
    e.preventDefault();

    try {
      loadingOn();
      const { data } = await API.patch(
        `/mockup-guest`,
        { ...formData, mockupId: selectedDesign.id },
        {
          headers: authHeader(),
        }
      );
      if (data?.success) {
        setGuestEmailList(data?.data);
        setFormData({});
        setSelectedEmail(null);
      }
      if (!data?.success) {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleContactSelect = async (contactsList) => {
    let bulkCreate = contactsList?.map((item) => ({
      name: `${item?.firstName} ${item?.lastName}`,
      email: item?.email,
      companyId: selectedDesign?.companyId,
      customerId: selectedDesign?.customerId,
      mockupId: selectedDesign.id,
    }));

    try {
      loadingOn();
      const { data } = await API.post(
        "/mockup-guest",
        { bulkCreate, mockupId: selectedDesign.id },
        {
          headers: authHeader(),
        }
      );
      if (data?.success) {
        setGuestEmailList(data?.data);
        setFormData({});
      }
      if (!data?.success) {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (selectedDesign) {
      getGuestLists();
    }
  }, [selectedDesign]);

  useEffect(() => {
    if (selectedEmail) {
      setFormData(selectedEmail);
    }
  }, [selectedEmail]);
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h3>Guest List</h3>
        <div>
          <button
            onClick={() => {
              setShowContactSelectModal(true);
            }}
            className="btn btn-sm btn-info"
          >
            Choose Email
          </button>
        </div>
      </div>
      <hr className="mt-0" />
      <div className="my-3">
        <form
          onSubmit={selectedEmail ? updateGuestEmail : addGuestEmail}
          className="d-flex align-items-end gap-2"
        >
          <div className="d-flex flex-grow-1 gap-2">
            <div className="w-50">
              <label htmlFor="guestName" className="form-label">
                Name
              </label>
              <input
                type="text"
                placeholder="Enter Name"
                className="form-control"
                id="guestName"
                value={formData.name || ""}
                onChange={(e) => {
                  setFormData({ ...formData, name: e.target.value });
                }}
                required
              />
            </div>
            <div className="w-50">
              <label htmlFor="guestEmail" className="form-label">
                Email
              </label>
              <input
                type="email"
                placeholder="Enter Email"
                className="form-control"
                id="guestEmail"
                value={formData.email || ""}
                onChange={(e) => {
                  setFormData({ ...formData, email: e.target.value });
                }}
                required
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            {selectedEmail ? "Update" : "Add"}
          </button>
          {selectedEmail && (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setFormData({});
                setSelectedEmail(null);
              }}
            >
              Cancel
            </button>
          )}
        </form>
      </div>
      <div className="table-responsive">
        {loading && <Spinner />}
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* Map through your guest list data here */}
            {guestEmailList?.map((guest, index) => (
              <tr key={guest.id}>
                <th scope="row">{index + 1}</th>
                <td>{guest.name}</td>
                <td>{guest.email}</td>
                <td>
                  <button
                    onClick={(e) => {
                      setSelectedEmail(guest);
                    }}
                    className="btn btn-sm btn-info me-2"
                  >
                    <i className="mdi mdi-pencil-outline"></i>
                  </button>
                  <button
                    onClick={(e) => {
                      deleteGuest(guest.id, guest.mockupId);
                    }}
                    className="btn btn-sm btn-danger"
                  >
                    <i className="mdi mdi-trash-can-outline"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="text-end">
        <button
          onClick={async (e) => {
            e.preventDefault();
            await refreshFunc();
            showModal(false);
          }}
          className="btn btn-sm btn-secondary"
        >
          Close
        </button>
      </div>
      {/*   Choose Email
       ********************************************* */}
      <Modal
        show={showContactSelectModal}
        contentClassName="bg-transparent"
        size="lg"
        onHide={() => setShowContactSelectModal(false)}
      >
        <Modal.Body>
          <CustomerContactSelect
            customerId={selectedDesign?.customerId}
            handleContactSelect={handleContactSelect}
            showModal={setShowContactSelectModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
